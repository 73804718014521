import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faCheck,
  faRemove,
} from "@fortawesome/free-solid-svg-icons";
import { RecentPost } from "../recentPost";
import "./rightToApproach.css";
import { Topbar } from "../../Topbar";
import AfterTopBar from "../../AfterTopbar/AfterTopbar";
import Footer from "../../Sections/footer";

export const RightApproachToDms = () => {
  return (
    <>
      <div className="main_header">
        <Topbar />
        <AfterTopBar />
      </div>
      <div class="page-area4">
        <div class="breadcumb-overlay"></div>
        <div class="container">
          <div class="row text-center">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="breadcrumb">
                <div class="section-headline white-headline text-center">
                  <h4
                    style={{
                      fontSize: "48px",
                      letterSpacing: "2px",
                      lineHeight: "48px",
                      color: "#fff",
                      display: "inline-block",
                      maxWidth: "100%",
                    }}
                  >
                    Enhance the Power of Distributor Management Software with
                    Right Approach to DMS
                  </h4>
                </div>
                <ul>
                  <li className="home-bread">
                    Home
                    <span style={{ marginLeft: "7px" }}></span>
                    <FontAwesomeIcon icon={faAngleRight} />
                    <span style={{ marginLeft: "7px" }}></span> Blog
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="blog-page-area area-padding">
        <div class="container" style={{ color: "#6c757d" }}>
          <div class="blog-details">
            <div class="row">
              <div class="col-md-8 col-sm-12 col-xs-12">
                <article class="blog-post-wrapper">
                  <div class="blog-banner">
                    <a href="#" class="blog-images">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/blog/BLOG_DMS-approach.jpg"
                        alt="Right Approach to DMS"
                      />
                    </a>
                    <div class="blog-content">
                      <br />
                      <p>
                        With the ever changing market dynamics, businesses are
                        evolving every minute to stay relevant. In an
                        increasingly competitive environment, having a solution
                        for tracking channel sales is very important in order to
                        achieve increased revenues and faster ROIs. Owing to
                        this, more & more companies are consciously going for
                        distributor management software for a complete
                        visibility of their channel sales.
                      </p>

                      <p>
                        There can be various approaches of managing the
                        distributor network. While some approaches are fairly
                        new, some have been prevalent for quite some time in the
                        industry. It’s also hard to pin-point one particular
                        approach that would work one hundred percent for the
                        entire distributor network.{" "}
                      </p>

                      <h2
                        style={{
                          fontSize: "15pt",
                          fontWeight: "600",
                          color: "black",
                          paddingTop: "20px",
                        }}
                      >
                        Various approaches to managing your Distributor network
                        are:
                      </h2>

                      <p>
                        <b style={{ color: "black" }}>
                          {" 1) Custom Billing Software:"}{" "}
                        </b>
                        This approach was one of the first approaches to be
                        introduced in the industry by large brands to manage
                        their distributor network. According to this approach,
                        the principal company asked its super stockists and big
                        distributors to install its own custom billing software
                        and do all the transactions through it. This approach
                        worked well then but in today’s scenario it’s completely
                        irrelevant. This is because the time when this approach
                        was introduced, the concept of multi brand distributors
                        was unheard of. Mostly distributors used to manage one
                        or two brands so using a custom billing software wasn’t
                        a big headache for them. With the rise of multi brand
                        distributors, demand for an alternative approach to DMS
                        was felt.
                      </p>

                      <p>
                        <b style={{ color: "black" }}>{"2) Web Portals:"}</b>
                        Another way of managing the distributor network would be
                        web portals. In this, the company provides a web portal
                        to its distributors through which the distributors have
                        to enter all the sales related transactions specific to
                        the company. Companies face increasing resistance from
                        their distributors for implementation of Web Portal
                        based DMS. Primary reason being that the distributors
                        managing multiple brands find it extremely cumbersome
                        and time consuming to firstly learn and then use
                        different web portals for the different brands.
                      </p>
                      <p>
                        Since, the web portal is just for reporting purposes and
                        the billing is actually done on a different system, it
                        is observed that distributors provide fake sales data to
                        justify their targets whereas in reality the figures are
                        quite different. This as a whole beats the purpose of
                        implementing a DMS as there is a possibility of
                        misappropriation of Data by the distributors.{" "}
                      </p>
                      <p>
                        Another limitation of this approach is its dependency on
                        internet connectivity. We all know that good internet
                        connectivity is still a luxury even in many metro cities
                        of the country let alone Tier 3 & Tier 4 cities. So a
                        web portal based DMS might not be able to map the entire
                        distributor network.
                      </p>
                      <p>
                        <b style={{ color: "black" }}>
                          {"3) Data Extractors:"}
                        </b>
                        The data extraction method, as opposed to custom
                        solutions or web portals, implies exporting data from
                        the distributor’s billing software and then importing it
                        on the company’s software. This method didn’t work well
                        and was short lived.
                      </p>
                      <p>
                        <b style={{ color: "black" }}>
                          {"4) Integration with Distributor’s Tally / Busy:"}{" "}
                        </b>
                        This is a new approach of integrating with the
                        distributor’s accounting software (usually Tally & Busy)
                        thereby enabling companies to be seamlessly connected
                        with the distributors billing software. Making their
                        life easy by reducing the pain of duplication, multiple
                        entries in different systems, data mismatch, Item
                        alteration, Price sanctity and lot more just by working
                        on their existing TALLY system.
                      </p>
                      <p>
                        Eazy DMS works on this same principal and is integrated
                        with Tally & Busy. It bridges the information gap that
                        manufacturer’s having a distribution network normally
                        face, without having to deploy any new system at the
                        distributor’s end. It gives them real time access to the
                        secondary sales data & inventory and enables better
                        decision making. Therefore, there is no need to
                        regularly follow up with your distributors for reports
                        and information.{" "}
                      </p>
                      <p>
                        Limitation to this method is mapping distributors who
                        are not using Tally or Busy for accounting purpose or
                        those small time distributors who don’t use a billing
                        software at all. For this the next approach might be
                        useful.
                      </p>
                      <p>
                        <b style={{ color: "black" }}>{"5) Mobile Apps: "}</b>{" "}
                        Latest trend amongst all is a mobile based - lite DMS
                        wherein the distributor can do the data entry on the
                        mobile app. This approach is more useful to map the
                        rural distributor network where infrastructure is not
                        that developed or in case of very small distributors who
                        don’t have the resources or knowledge of using a
                        desktop.
                      </p>

                      <h3
                        style={{
                          fontWeight: "600",
                          fontFamily: "serif !important",
                          color: "black",
                        }}
                      >
                        Comparison between the three most common types of DMS:
                      </h3>

                      <p>
                        A quick summary to showcase how the three types of DMS
                        are different in terms of functionality.
                      </p>
                      <table style={{ marginTop: "30px" }}>
                        <tr>
                          <th style={{ width: "34%" }}>Features</th>
                          <th style={{ width: "24%" }}>
                            Custom Billing Software
                          </th>
                          <th style={{ width: "24%" }}>Web Portal</th>
                          <th style={{ width: "24%" }}>
                            Tally Integrated DMS (Eazy DMS)
                          </th>
                        </tr>

                        <tr>
                          <td style={{ width: "34%" }}>
                            Integration with Principle Company ERP
                          </td>
                          <th style={{ width: "24%", color: "red" }}>
                            <FontAwesomeIcon
                              icon={faRemove}
                              style={{ color: "red" }}
                            />
                          </th>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon
                              icon={faRemove}
                              style={{ color: "red" }}
                            />
                          </th>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon icon={faCheck} />
                          </th>
                        </tr>
                        <tr>
                          <td style={{ width: "34%" }}>Integration with SFA</td>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon
                              icon={faRemove}
                              style={{ color: "red" }}
                            />
                          </th>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon icon={faCheck} />
                          </th>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon icon={faCheck} />
                          </th>
                        </tr>
                        <tr>
                          <td style={{ width: "34%" }}>
                            Works in Offline Mode
                          </td>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon icon={faCheck} />
                          </th>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon
                              icon={faRemove}
                              style={{ color: "red" }}
                            />
                          </th>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon icon={faCheck} />
                          </th>
                        </tr>
                        <tr>
                          <td style={{ width: "34%" }}>
                            Multi Brand Billing @ Distributor Level
                          </td>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon
                              icon={faRemove}
                              style={{ color: "red" }}
                            />
                          </th>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon
                              icon={faRemove}
                              style={{ color: "red" }}
                            />
                          </th>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon icon={faCheck} />
                          </th>
                        </tr>
                        <tr>
                          <td style={{ width: "34%" }}>Scheme Management</td>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon icon={faCheck} />
                          </th>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon icon={faCheck} />
                          </th>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon icon={faCheck} />
                          </th>
                        </tr>
                        <tr>
                          <td style={{ width: "34%" }}>SKU Mapping</td>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon
                              icon={faRemove}
                              style={{ color: "red" }}
                            />
                          </th>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon
                              icon={faRemove}
                              style={{ color: "red" }}
                            />
                          </th>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon icon={faCheck} />
                          </th>
                        </tr>
                        <tr>
                          <td style={{ width: "34%" }}>Analytical Tools</td>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon
                              icon={faRemove}
                              style={{ color: "red" }}
                            />
                          </th>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon
                              icon={faRemove}
                              style={{ color: "red" }}
                            />
                          </th>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon icon={faCheck} />
                          </th>
                        </tr>
                        <tr>
                          <td style={{ width: "34%" }}>
                            Low Support & License Cost
                          </td>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon
                              icon={faRemove}
                              style={{ color: "red" }}
                            />
                          </th>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon
                              icon={faRemove}
                              style={{ color: "red" }}
                            />
                          </th>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon icon={faCheck} />
                          </th>
                        </tr>
                        <tr>
                          <td style={{ width: "34%" }}>
                            Low Manpower & Training Cost
                          </td>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon
                              icon={faRemove}
                              style={{ color: "red" }}
                            />
                          </th>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon
                              icon={faRemove}
                              style={{ color: "red" }}
                            />
                          </th>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon icon={faCheck} />
                          </th>
                        </tr>
                        <tr>
                          <td style={{ width: "34%" }}>Change Management</td>
                          <th style={{ width: "24%" }}>
                            <i class="fa">High</i>
                          </th>
                          <th style={{ width: "24%" }}>
                            <i class="fa">High</i>
                          </th>
                          <th style={{ width: "24%" }}>
                            <i class="fa">Low</i>
                          </th>
                        </tr>
                        <tr>
                          <td style={{ width: "34%" }}>
                            Real Time Data Analysis
                          </td>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon
                              icon={faRemove}
                              style={{ color: "red" }}
                            />
                          </th>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon
                              icon={faRemove}
                              style={{ color: "red" }}
                            />
                          </th>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon icon={faCheck} />
                          </th>
                        </tr>
                        <tr>
                          <td style={{ width: "34%" }}>
                            Data Discrepancy Tracking
                          </td>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon
                              icon={faRemove}
                              style={{ color: "red" }}
                            />
                          </th>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon
                              icon={faRemove}
                              style={{ color: "red" }}
                            />
                          </th>
                          <th style={{ width: "24%" }}>
                            <FontAwesomeIcon icon={faCheck} />
                          </th>
                        </tr>
                      </table>
                      <p style={{ marginTop: "29px" }}>
                        With the proper software fueling your sales and
                        distribution activities, you can achieve high
                        performance in even the most fast-moving and fragmented
                        marketplace. Designed for the complex Indian market
                        landscape, EAZY DMS is a distributor management system
                        that provides complete visibility of the secondary Sales
                        or stock lying at the distributor’s end. It can be
                        integrated with the distributor’s Tally/ Busy, so that
                        companies don’t have to deploy a separate portal at the
                        distributor’s end and still can keep track of all
                        relevant information. The distributor can keep using his
                        existing Tally/ Busy software for his day to day billing
                        and other activities.
                      </p>
                      <p>
                        A Tally/ Busy integrated DMS like EAZY DMS is not just
                        about pulling the data from the distributor; it is also
                        capable of pushing information to the distributors like
                        the masters, prices and schemes that eventually brings a
                        lot of grip and control to the principal. The Master &
                        Price control enables the principal company to exercise
                        a complete control over distributor’s MRP, Item
                        Alteration, Back date entries, Ledger Alteration,
                        Negative Stock billings and Reorder levels. The PO
                        generated by Distributor’s Tally syncs automatically in
                        the principal's ERP software thus automating the primary
                        sales as well.
                      </p>
                      <p>
                        Eazy DMS offers an entire spectrum of DMS solution which
                        includes Tally integrated DMS, Web portal as well as
                        mobile DMS in order to capitalize on the maximum number
                        of distributors for a company. So a Tally integrated DMS
                        might work for most of your distributors but for mapping
                        the small distributors that are not using any billing
                        software of their own or are not using Tally / Busy, for
                        them a mobile DMS makes more sense as it’s easy to adopt
                        without much of infrastructure investment and training.
                      </p>
                    </div>
                  </div>
                </article>
                <div class="clear"></div>
                <div class="single-post-comments"></div>
              </div>

              <div class="col-md-4 col-sm-12">
                <RecentPost />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
