import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faCircleCheck,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { Topbar } from "../Topbar";
import AfterTopBar from "../AfterTopbar/AfterTopbar";
import Footer from "./footer";
import a from '../../assets/images/mobile/a.png';
import b from '../../assets/images/mobile/b.png';
import c from '../../assets/images/mobile/c.png';
import d from '../../assets/images/mobile/d.png';
export const EazySfaPage = () => {
  const [activeTab, setActiveTab] = useState(0);

  const changeTab = (arg) => {
    if (arg === activeTab) {
      setActiveTab(0);
    } else {
      setActiveTab(arg);
    }
  };

  return (
    <>
      <div className="main_header">
        <Topbar />
        <AfterTopBar />
      </div>
      <div class="page-area7">
        <div class="container">
          <div class="row text-center">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="breadcrumb">
                <div class="section-headline white-headline text-center">
                  <h3 className="text-dark">Eazy SFA</h3>
                </div>
                {/* <ul>
                  <li className="home-bread">
                    Home
                    <span style={{ marginLeft: "7px" }}></span>
                    <FontAwesomeIcon icon={faAngleRight} />
                    <span style={{ marginLeft: "7px" }}></span> Eazy SFA
                  </li>
                </ul> */}
                <p className="text-dark mt-5">
                  Eazy SFA lets you effectively monitor your sales staff performance at all hierarchy levels through mobile SFA app. The application is exclusively developed
                  to connect the field force with the back office management and ensure a real-time, powerful and comprehensive exchange of information between the two.
                  EAZY SFA helps organizations track sales staff activities on the move such as field visits, attendance, punctuality, performance and productivity and also
                  integrates with Tally/Busy to automate the ordering process.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
     <section className="mobile_baneer">
     <div className="container">
        <div className="row">
          <div className="col-md-1 col-1">
            
          </div>
          <div className="col-md-3 col-3">
            <div className="mobile_img">
              <img src={b} alt="" className="" />
            </div>
          </div>
          <div className="col-md-5 col-5 text-center">
            <div className="mobile_img_center">
              <img src={a} alt="" className="" />
            </div>
            <div className="mobile_img_custom">
              <img src={c} alt="" className="" />
            </div>
          </div>
          <div className="col-md-3 col-3">
            <div className="mobile_img">
              <img src={d} alt="" className="" width="280" />
            </div>
          </div>
        </div>
      </div>
     </section>

      <div class="area-padding mt_t0">
        <div class="container" style={{ color: "#6c757d" }}>
          <div class="row">
            <div class="col-md-6 col-sm-12 col-xs-12">
              <div class="section-headline">
                <p></p>
                <p
                  class="text-justify"
                  style={{ marginTop: "55px", marginBottom: "15px" }}
                >
                  Eazy SFA lets you effectively monitor your sales staff
                  performance at all hierarchy levels through mobile SFA app.
                  The application is exclusively developed to connect the field
                  force with the back office management and ensure a real-time,
                  powerful and comprehensive exchange of information between the
                  two.
                </p>
                <p>
                  EAZY SFA helps organizations track sales staff activities on
                  the move such as field visits, attendance, punctuality,
                  performance and productivity and also integrates with
                  Tally/Busy to automate the ordering process.{" "}
                </p>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12">
              <div class="section-headline text-center">
                <img
                  src="https://eazysites.s3.amazonaws.com/eazydms/img/sfa_Image.jpg"
                  alt="Sales Force Automation image"
                  style={{ marginRight: "10px" }}
                />
              </div>
            </div>
          </div>
        </div>

        <div class="area-padding">
          <div class="container" style={{ color: "#6c757d" }}>
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="section-headline text-center">
                  <h3 style={{ color: "black" }}>Eazy SFA helps you to:</h3>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="accordion">
                  <div
                    class={`accordion__header ${activeTab === 1 ? "is-active" : ""
                      }`}
                    onClick={() => changeTab(1)}
                  >
                    <h4 class="check-title">
                      <a
                        data-toggle="collapse"
                        class="active"
                        data-parent="#accordion"
                        href="#feature5"
                        style={{ textDecoration: "none" }}
                      >
                        <span class="acc-icons"></span>
                        <div className="accordian_img_icon">
                        <img
                          src="https://eazysites.s3.amazonaws.com/eazydms/img/feature/sync.png"
                          alt="Icon"
                          width="35px"
                          style={{ marginRight: "10px" }}
                        />
                        </div>
                        Multiple Alerts
                      </a>
                    </h4>
                    <span class="accordion__toggle"></span>
                  </div>
                  <div
                    class={`accordion__body ${activeTab === 1 ? "is-active" : ""
                      }`}
                  >
                    <ul class="gun">
                      <p>
                        Send real time SMS/Email alerts to sales staff regarding
                        their performance, attendance, missed follow ups etc.
                      </p>
                    </ul>
                  </div>
                  <div
                    class={`accordion__header ${activeTab === 2 ? "is-active" : ""
                      }`}
                    onClick={() => changeTab(2)}
                  >
                    <h4 class="check-title">
                      <a
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#feature6"
                        style={{ textDecoration: "none" }}
                      >
                        <span class="acc-icons"></span>
                        <div className="accordian_img_icon">
                        <img
                          src="https://eazysites.s3.amazonaws.com/eazydms/img/feature/claim.png"
                          alt="Icon"
                          width="38px"
                          style={{ marginRight: "10px" }}
                        />
                        </div>
                        Centralize Control
                      </a>
                    </h4>
                    <span class="accordion__toggle"></span>
                  </div>
                  <div
                    class={`accordion__body ${activeTab === 2 ? "is-active" : ""
                      }`}
                  >
                    <ul class="gun">
                      <p>
                        Management can easily schedule visits and tasks remotely
                        through the web console that will reflect in the Mobile
                        App of the Sales executive.
                      </p>
                    </ul>
                  </div>
                  <div
                    class={`accordion__header ${activeTab === 3 ? "is-active" : ""
                      }`}
                    onClick={() => changeTab(3)}
                  >
                    <h4 class="check-title">
                      <a
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#feature4"
                        style={{ textDecoration: "none" }}
                      >
                        <span class="acc-icons"></span>
                        <div className="accordian_img_icon">
                        <img
                          src="https://eazysites.s3.amazonaws.com/eazydms/img/feature/channel.png"
                          alt="Icon"
                          width="38px"
                          style={{ marginRight: "10px" }}
                        />
                        </div>
                        Customized Reports
                      </a>
                    </h4>
                    <span class="accordion__toggle"></span>
                  </div>
                  <div
                    class={`accordion__body ${activeTab === 3 ? "is-active" : ""
                      }`}
                  >
                    <ul class="gun">
                      <p>
                        Ad-hoc analytical reports for deeper insights, new sales
                        opportunities and effective business decisions.
                      </p>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 accordion">
                <div
                  class={`accordion__header ${activeTab === 4 ? "is-active" : ""
                    }`}
                  onClick={() => changeTab(4)}
                >
                  <h4 class="check-title">
                    <a
                      data-toggle="collapse"
                      class="active"
                      data-parent="#accordion"
                      // href="#feature"
                      style={{ textDecoration: "none" }}
                    >
                      <span class="acc-icons"></span>
                      <div className="accordian_img_icon">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/feature/Implementation.png"
                        alt="Icon"
                        width="40px"
                        style={{ marginRight: "10px" }}
                      />
                      </div>
                      Automate Sales Process
                    </a>
                  </h4>
                  <span class="accordion__toggle"></span>
                </div>
                <div
                  class={`accordion__body ${activeTab === 4 ? "is-active" : ""
                    }`}
                >
                  <p style={{ marginBottom: "0px", marginTop: "5px" }}>
                    Save time by automating order processing at field staff's
                    end through the easy to use application.
                  </p>
                </div>

                <div
                  class={`accordion__header ${activeTab === 5 ? "is-active" : ""
                    }`}
                  onClick={() => changeTab(5)}
                >
                  <h4 class="check-title">
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion"
                      // href="#feature2"
                      style={{ textDecoration: "none" }}
                    >
                      <span class="acc-icons"></span>
                      <div className="accordian_img_icon">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/feature/Distributors.png"
                        alt="icon"
                        width="38px"
                        style={{ marginRight: "10px" }}
                      />
                      </div>
                      Improve Performance & Productivity
                      <p style={{ marginBottom: "0px", marginTop: "5px" }}></p>
                    </a>
                  </h4>
                  <span class="accordion__toggle"></span>
                </div>
                <div
                  class={`accordion__body ${activeTab === 5 ? "is-active" : ""
                    }`}
                >
                  <ul class="gun">
                    <p>
                      Plan, execute, track and manage all sales activities such
                      as sales pipeline, orders, targets achieved as well as
                      attendance and punctuality of sales staff.
                    </p>
                  </ul>
                </div>

                <div
                  class={`accordion__header ${activeTab === 6 ? "is-active" : ""
                    }`}
                  onClick={() => changeTab(6)}
                >
                  <h4 class="check-title">
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion"
                      // href="#feature3"
                      style={{ textDecoration: "none" }}
                    >
                      <span class="acc-icons"></span>
                      <div className="accordian_img_icon">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/feature/purchase-order.png"
                        alt="icon"
                        width="33px"
                        style={{ marginRight: "10px" }}
                      />
                      </div>
                      Real Time Sales Data
                    </a>
                  </h4>
                  <span class="accordion__toggle"></span>
                </div>
                <div
                  class={`accordion__body ${activeTab === 6 ? "is-active" : ""
                    }`}
                >
                  <ul class="gun">
                    <p>
                      Get real time secondary & tertiary sales data from
                      distributors end in real time with automated reports.
                    </p>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
