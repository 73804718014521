import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpen, faPhone } from "@fortawesome/free-solid-svg-icons";

export const Topbar = () => {
  return (
    <>
      <div class="row text-light" style={{padding:"6px", backgroundColor:"#ECECEC"}}>
        <div class="col-lg-8 col-md-6"></div>
        <div
          class="col-lg-4 col-md-6"
          style={{
            padding: "50px !important",
          }}
        >
          <div class="header-contact ">
            <div
              style={{
                display: "flex",
                marginBottom:"0px",
              }}
            >
              <span
                className="col-lg-6"
                style={{
                  
                  display: "flex",
                  textAlign: "center",
                  alignItems:"center",
                }}
              >
                <span>
                  <FontAwesomeIcon className="text-danger" icon={faPhone} aria-hidden={true} />
                  {/* <img src={support} alt="icon" /> */}
                </span>

                <span style={{ marginLeft: "10px", color: "#015181" }}>
                  +91 12 4479 4479
                </span>
              </span>
              <span style={{
                  
                  display: "flex",
                  textAlign: "center",
                  alignItems:"center",
                }}>
                <span>
                  <FontAwesomeIcon className="text-danger" icon={faEnvelopeOpen} aria-hidden={true} />
                </span>
                <span style={{ marginLeft: "10px", color: "#015181" }}>
                  contact@eazrerp.com
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
