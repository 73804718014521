import React, { useState, useEffect } from "react";
// import SocialMedia from "../../UI/SocialMedia/SocialMedia.js";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import "./Navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from 'react-router-dom';

import {
  faChevronDown,
  faPhone,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";
import logo1 from "../../img/about/image.png";
import ServerAPI from "../../ServerApi";
import { ResponsePopUp } from "../Alert";
import Loader from "../Loader";
function Nav({ navClass }) {
  // let history = useHistory();
  
  const [click, setClick] = useState(false);
  const [isLoading,setLoading]=useState(false)
  const [dropdown, setDropdown] = useState(false);
  const [formDetails, setFormDetails] = useState({
    name: "",
    email: "",
    mobile: "",
    companyName: "",
    Country: "102",
    state: "",
  });
  const [responseDetails, setResponseDetails] = useState({
    isResponseOpen: false,
    responseContent: "",
    responseType: ""
  })
  const handleResponseClose = () => {
    setResponseDetails((prev) => ({ ...prev, isResponseOpen: false }))
  }

  const [errorDetails, setErrorsDetails] = useState({
    name: "",
    email: "",
    mobile: "",
    companyName: "",
    state: "",
  });

  const closeMobileMenu = () => setClick(false);

  const customCloseButton = (
    <span
      className="sociallandingbIcon"
      style={{ fontSize: "25px", color: "#fff", cursor: "pointer" }}
      onClick={closeMobileMenu}
    >
      <FontAwesomeIcon icon={faXmark} />
    </span>
  );

  const handleChange = (e) => {
    if (e.target) {
      let name = e.target.name;
      let value = e.target.value;
      setFormDetails((prev) => ({ ...prev, [name]: value }));
      setErrorsDetails((prev) => ({
        ...prev,
        [name]: value ? "" : "This Field Can't be Blank",
      }));
    } else if (e.element) {
      let name = e.element.id;
      let value = e.value;
      setFormDetails(() => ({ ...formDetails, [name]: value }));
    }
  };

  const handleKeyPress = (e) => {
    const value = e.key;
    const isNumber = /^[0-9]$/.test(value);
    if (!isNumber) {
      e.preventDefault();
    }
  };

  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    if (formDetails["name"] == "") {
      formIsValid = false;
      setErrorsDetails((prev) => ({
        ...prev,
        ["name"]: "This Field Can't be Blank",
      }));
    }
    if (formDetails["email"] == "") {
      formIsValid = false;
      setErrorsDetails((prev) => ({
        ...prev,
        ["email"]: "This Field Can't be Blank",
      }));
    } else if (formDetails["email"] != "") {
      const isEmail =
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/.test(
          formDetails.email
        );
      const isValidCompanyEmail = /^[a-zA-Z0-9_.+-]+@gmail\.com$/.test(
        formDetails.email
      );
      if (!isEmail) {
        formIsValid = false;
        setErrorsDetails((prev) => ({
          ...prev,
          ["email"]: "Enter a valid email Address",
        }));
      }
      if (isValidCompanyEmail) {
        formIsValid = false;
        setErrorsDetails((prev) => ({
          ...prev,
          ["email"]: "Enter a valid Company Email Address",
        }));
      }
    }

    if (formDetails["companyName"] == "") {
      formIsValid = false;
      setErrorsDetails((prev) => ({
        ...prev,
        ["companyName"]: "This Field Can't be Blank",
      }));
    }
    if (formDetails["mobile"] == "") {
      formIsValid = false;
      setErrorsDetails((prev) => ({
        ...prev,
        ["mobile"]: "This Field Can't be Blank",
      }));
    }
    if (formDetails["state"] == "") {
      formIsValid = false;
      setErrorsDetails((prev) => ({
        ...prev,
        ["state"]: "This Field Can't be Blank",
      }));
    }

    return formIsValid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      setLoading(true)
      const payload = {
        contactDetail: {
          source: "EazyDMS",
          pageName: "Request a Demo",
          company_name: formDetails.companyName,
          name: formDetails.name,
          email: formDetails.email,
          mobile: formDetails.mobile,
          state: formDetails.state,
          country: "India",
          message: "This is testing from developer",
          sendEmail: "true",
        },
      };

      fetch(ServerAPI, {
        method: "post",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status == true || res.status == "true") {
            setResponseDetails({
              isResponseOpen: true,
              responseContent: res.result,
              responseType: "success"
            })
            setFormDetails({
              name: "",
              email: "",
              mobile: "",
              companyName: "",
              Country: "102",
              state: "",
            });
            setClick(false)
          } else {
            setResponseDetails({
              isResponseOpen: true,
              responseContent: res.error,
              responseType: "error"
            })
            setFormDetails({
              name: "",
              email: "",
              mobile: "",
              companyName: "",
              Country: "102",
              state: "",
            });
            setClick(false)
          }
          setLoading(false)
        })
        .catch((err) =>
        {
          setResponseDetails({
            isResponseOpen: true,
            responseContent: "Something Went Wrong,Try Again",
            responseType: "error"
          })
          setLoading(false)
          console.log(err, "Error While trying to send request demo data")
        }
        );
    } 
  };

  return (
    <>
     {isLoading && <Loader/>}
      <nav className={`navbar navbar-expand-lg`}>
        <Link to="/" className="navbar-brand d-flex align-items-center">
          <h1 className="m-0">
            {navClass == "" ? (
              // <img className="img-fluid me-3 white_logo" src={logo1} alt="" />
              <img className="img-fluid me-3 white_logo" src="https://eazysites.s3.amazonaws.com/eazydms/img/logo/eazydms.png" alt="EazyDMS" />
              
            ) : (
              // <img className="img-fluid me-3 white_logo " src={logo1} alt="logo" />
              <img className="img-fluid me-3 white_logo" src="https://eazysites.s3.amazonaws.com/eazydms/img/logo/eazydms.png" alt="EazyDMS" />
            )}

            {/* <img className="img-fluid me-3 color_logo" src="https://eazysites.s3.amazonaws.com/eazydms/img/logo/dms-new.png" alt=""/> */}
          </h1>
        </Link>
        <button
          type="button"
          className="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav mx-auto pe-4 py-3 py-lg-0">
            <NavLink to="/" activeClassName="active" className="nav-item nav-link" style={{ color: navClass == "" ? "#025282" : "#025282" }}>
              Home
            </NavLink>
           
            <NavLink
            activeClassName="active"
              to="/About"
              className="nav-item nav-link"
              style={{ color: navClass == "" ? "#025282" : "#025282" }}
            >
              About EAZY
            </NavLink>
            {/* <span
              className="nav-item nav-link"
              onClick={() => SwitchTabs("/About")}
              style={{
                color: navClass == "" ? "#025282" : "#025282",
                cursor: "pointer",
              }}
            >
              About EAZY
            </span> */}
            <div className="nav-item dropdown">
              <Link
              
                to="/"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                style={{ color: navClass == "" ? "#025282" : "#025282" }}
              >
                Products
                <span
                  style={{
                    marginLeft: "3px",
                    fontSize: "14px",
                    color: navClass == "" ? "#fff !important" : "#025282",
                  }}
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </span>
              </Link>
              <div className="dropdown-menu bg-light border-0 m-0">
                <Link  to="/" className="dropdown-item">
                  EAZY DMS
                </Link>
                <NavLink activeClassName="active" to="/EazySfa" className="dropdown-item">
                  EAZY SFA
                </NavLink>
                <NavLink
                activeClassName="active"
                  to="https://www.retaileazy.in/"
                  target="_blank"
                  className="dropdown-item"
                >
                  RETAIL EAZY
                </NavLink>
              </div>
            </div>
            <NavLink
            activeClassName="active"
              to="/Customer"
              className="nav-item nav-link"
              style={{ color: navClass == "" ? "#025282" : "#025282" }}
            >
              Customers
            </NavLink>
            <NavLink
            activeClassName="active"
              to="/Media"
              className="nav-item nav-link"
              style={{ color: navClass == "" ? "#025282" : "#025282" }}
            >
              Media
            </NavLink>

            <NavLink
            activeClassName="active"
              to="/Contact"
              className="nav-item nav-link"
              style={{ color: navClass == "" ? "#025282" : "#025282" }}
            >
              Contact Us
            </NavLink>

            <div className="nav-item dropdown">
              <Link
              
                to="/"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                style={{ color: navClass == "" ? "#025282" : "#025282" }}
              >
                Resources
                <span style={{ marginLeft: "3px", fontSize: "14px" }}>
                  {" "}
                  <FontAwesomeIcon icon={faChevronDown} />
                </span>
              </Link>
              <div className="dropdown-menu bg-light border-0 m-0">
                <NavLink to="/Blogs" activeClassName="active" className="dropdown-item">
                  Blogs
                </NavLink>
                <NavLink to="/CaseStudy" activeClassName="active" className="dropdown-item">
                  Case Studies
                </NavLink>
              </div>
            </div>
          </div>
          <div className="h-100 d-lg-inline-flex align-items-center d-none">
            <button
              type="button"
              className="btn btn-primary reqst_demo"
              onClick={() => setClick(true)}
            >
              Request a Demo
            </button>
          </div>
        </div>
      </nav>
      <Modal isOpen={click}>
        <ModalHeader toggle={closeMobileMenu} close={customCloseButton}>
          <div style={{ color: "#fff" }}>Request a Demo</div>
        </ModalHeader>
        <ModalBody>
          <div class="contact-form">
            <div class="row">
              <div
                id="requestDemoForm"
                name="requestDemoForm"
                class="contact-form"
                autocomplete="off"
              >
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    class="form-control"
                    placeholder="Name*"
                    value={formDetails.name}
                    onChange={handleChange}
                    style={{
                      marginBottom: errorDetails.name == "" ? "" : "0px",
                    }}
                  />
                  {errorDetails.name != "" ? (
                    <span style={{ color: "red" }}>
                      This Field Can't be Blank
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <input
                    type="text"
                    id="mobile"
                    name="mobile"
                    onKeyPress={handleKeyPress}
                    class="form-control"
                    placeholder="Mobile*"
                    maxlength="15"
                    value={formDetails.mobile}
                    onChange={handleChange}
                    style={{
                      marginBottom: errorDetails.mobile == "" ? "" : "0px",
                    }}
                  />
                  {errorDetails.mobile != "" ? (
                    <span style={{ color: "red" }}>
                      This Field Can't be Blank
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <input
                    type="email"
                    class="email form-control"
                    id="email"
                    name="email"
                    placeholder="Email*"
                    value={formDetails.email}
                    onChange={handleChange}
                    // onKeyPress={handleEmailCheck}
                    style={{
                      marginBottom: errorDetails.email == "" ? "" : "0px",
                    }}
                  />
                  {errorDetails.email != "" ? (
                    <span style={{ color: "red" }}>{errorDetails.email}</span>
                  ) : (
                    ""
                  )}
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <input
                    type="text"
                    class="form-control"
                    id="companyName"
                    name="companyName"
                    placeholder="Company Name*"
                    value={formDetails.companyName}
                    onChange={handleChange}
                    style={{
                      marginBottom: errorDetails.companyName == "" ? "" : "0px",
                    }}
                  />
                  {errorDetails.companyName != "" ? (
                    <span style={{ color: "red" }}>
                      This Field Can't be Blank
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <select
                    class="form-control"
                    name="Country"
                    id="Country"
                    onChange={handleChange}
                    placeholder="Country"
                    // onSelect={handleChange}
                  >
                    <option value="102">India</option>
                  </select>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <select
                    class="form-control"
                    name="state"
                    id="state"
                    onChange={handleChange}
                    value={formDetails.state}
                    style={{
                      marginBottom: errorDetails.state == "" ? "" : "0px",
                    }}
                  >
                    <option value="">State*</option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Andaman and Nicobar Island">
                      Andaman and Nicobar Island
                    </option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Chandigarh">Chandigarh</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="Dadra and Nagar Haveli and Daman and Diu">
                      Dadra and Nagar Haveli and Daman and Diu
                    </option>
                    <option value="Delhi">Delhi</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Ladakh">Ladakh</option>
                    <option value="Lakshadweep">Lakshadweep</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Odisha">Odisha</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Puducherry">Puducherry</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="Uttarakhand">Uttarakhand</option>
                    <option value="West Bengal">West Bengal</option>
                  </select>
                  {errorDetails.state != "" ? (
                    <span style={{ color: "red" }}>
                      This Field Can't be Blank
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div class="col-md-6 col-sm-6">
                  <div
                    class="g-recaptcha"
                    data-sitekey="6Le_aW8UAAAAAMWrCedYvbz-yDuEpSOnzMQJu6q9"
                    id="demoRequestCaptcha"
                  ></div>
                </div>

                <div class="col-md-6 col-sm-12 col-xs-12 text-left">
                  <button
                    type="submit"
                    id="submit"
                    onClick={handleSubmit}
                    class="contact-btn1"
                  >
                    Submit
                  </button>
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <ResponsePopUp
        open={responseDetails.isResponseOpen}
        onClose={handleResponseClose}
        maxWidth={"xs"}
        content={responseDetails.responseContent}
        type={responseDetails.responseType}

      />


    </>
  );
}

export default Nav;
