import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { RecentPost } from "../recentPost";
import { Topbar } from "../../Topbar";
import AfterTopBar from "../../AfterTopbar/AfterTopbar";
import Footer from "../../Sections/footer";

export const BlogTallyVsDms = () => {
  return (
    <>
      <div className="main_header">
        <Topbar />
        <AfterTopBar />
      </div>
      <div class="page-area4">
        <div class="breadcumb-overlay"></div>
        <div class="container">
          <div class="row text-center">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="breadcrumb">
                <div class="section-headline white-headline text-center">
                  <h4
                    style={{
                      fontSize: "48px",
                      letterSpacing: "2px",
                      lineHeight: "48px",
                      color: "#fff",
                      display: "inline-block",
                      maxWidth: "100%",
                    }}
                  >
                    Eazy DMS Vs Portal Based DMS
                  </h4>
                </div>
                <ul>
                  <li className="home-bread">
                    Home
                    <span style={{ marginLeft: "7px" }}></span>
                    <FontAwesomeIcon icon={faAngleRight} />
                    <span style={{ marginLeft: "7px" }}></span> Blog
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="blog-page-area area-padding">
        <div class="container" style={{ color: "#6c757d" }}>
          <div class="blog-details">
            <div class="row">
              <div class="col-md-8 col-sm-8 col-xs-12">
                <article class="blog-post-wrapper">
                  <div class="blog-banner">
                    <a href="#" class="blog-images">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/blog/DMSblogDMSVSPortal.jpg"
                        alt="Eazy DMS vs Portal Based DMS Image"
                      />
                    </a>
                    <div class="blog-content">
                      <br />
                      <p>
                        Emerging markets for FMCG/FMCD companies are massive and
                        aggregated with manifold distributors, dealers, retails
                        and potential customers. There are various ways through
                        which manufacturers track goods as they move along the
                        supply chain till it reaches the final consumer.
                        Normally, primary data is already available with the
                        manufacturer and manual ways are adopted to collect the
                        secondary sales data from distributors i.e. sales data
                        of goods sold by distributor to various retailers. But
                        with the advancement of technology, companies have
                        adopted different softwares available in the market to
                        automatically capture their secondary sales data. These
                        softwares are categorized under Distributor Management
                        Systems (DMS).
                      </p>
                      <p>
                        These distributor management systems commonly comprise
                        of a web portal where distributors enter data separately
                        which can then be accessed by the manufacturer. Software
                        providers install a web based portal on both
                        manufacturer and distributors side for communicating
                        sales data and other information. However, these systems
                        fail to address some of the common issues at
                        distributor’s end which defeats the prime motive of
                        implementing a Distributor Management System. These
                        issues are:
                      </p>

                      <p style={{ paddingLeft: "50px" }}>
                        <b style={{ color: "black" }}>
                          1. Massive Duplication of Work:{" "}
                        </b>
                        Leading Distributor Management System providers offer a
                        web portal where information needs to be entered
                        manually by the distributor which creates a resistance
                        among them due to increased workload of updating data in
                        theirs as well as manufacturers system. In other words,
                        data needs to be maintained in distributors own system
                        as well as individual brand portals which creates
                        massive duplication of work. Eazy DMS eliminates the
                        need to enter data separately as it extracts information
                        directly from distributors Tally/Busy. Information is
                        pulled out of their accounting software and transferred
                        to manufacturers ERP directly as and when invoices are
                        raised, reducing massive workload.
                      </p>

                      <p style={{ paddingLeft: "50px" }}>
                        <b style={{ color: "black" }}>
                          2. Separate Portals for Multiple Brands:{" "}
                        </b>
                        Modern distributors work with multiple brands in the
                        market. As more and more brands start adopting a
                        Distributor Management System, more portals need to be
                        installed at distributors end and information needs to
                        be entered in seperate systems. Imagine the additional
                        work and man hours required to fulfill the same. With
                        the help of Eazy DMS, there is no need to install
                        additional software as information is directly taken
                        from distributors existing Tally/Busy.
                      </p>

                      <p style={{ paddingLeft: "50px" }}>
                        <b style={{ color: "black" }}>
                          3. Mandatory Additional Training:{" "}
                        </b>
                        Each company may not opt for the same distributor
                        management system. Moreover, these portal based
                        distributor management systems provided by service
                        providers will not be the same for two companies or
                        brands. So everytime a manufacturer decides to implement
                        a Distributor Management System (DMS), they need to
                        provide training to distributor for operating and
                        managing the system which is a very tedious task for the
                        distributors. On the other hand, there is no additional
                        training required in Eazy DMS. Moreover, as more and
                        more manufacturing companies adopt Eazy DMS, distributor
                        management process can be standardized in FMCG/FMCD
                        sector.
                      </p>

                      <p style={{ paddingLeft: "50px" }}>
                        <b style={{ color: "black" }}>
                          4. Increase in Manpower:{" "}
                        </b>
                        In order to operate and manage portal based distributor
                        management systems, distributors are going to have to
                        employ skilled staff who have a knack for technology
                        which will increase distributors expenses and manpower.
                        It is due to elimination of duplication of work in Eazy
                        DMS, no additional manpower is required by the
                        distributor.
                      </p>

                      <p style={{ paddingLeft: "50px" }}>
                        <b style={{ color: "black" }}>
                          5. Distributors go offline for days:{" "}
                        </b>
                        One of the major issues faced by manufacturers is that
                        distributors go offline for days and even months which
                        prevents data from reaching them at the right time. No
                        distributor management system (DMS) can help under such
                        scenario except Eazy DMS. It lets you put limitations on
                        distributors tally which won’t allow them to raise
                        invoices for your product if they don’t sync data within
                        2 days or more as per your convenience. Additionally,
                        distributors need to be online in order to operate the
                        portal based distributor management system which becomes
                        an issue in areas with disruptive connectivity. Eazy DMS
                        is the only software available in the market that
                        provides the functionality of both online and offline
                        mode. It stores transaction details on the system and
                        syncs the data once the connectivity is restored.
                      </p>

                      <p style={{ paddingLeft: "50px" }}>
                        <b style={{ color: "black" }}>
                          6. Lack of Real Time Transaction Details:{" "}
                        </b>
                        As mentioned earlier, web portal based distributor
                        management systems require distributors to manually
                        enter data which is a time consuming process and is
                        usually done at the end of the day. Eazy DMS on the
                        other hand extracts data directly from their tally in
                        real-time, making sure information reaches you on time.
                      </p>

                      <p style={{ paddingLeft: "50px" }}>
                        <b style={{ color: "black" }}>
                          7. SKU Differentiation:{" "}
                        </b>
                        It is often seen that SKU codes differ at distributors
                        end. In such case, it is very difficult to track &
                        analyze which product has been sold by the distributor.
                        Eazy DMS makes this task easier by synchronizing SKUs at
                        both distributor and manufacturer end.
                      </p>

                      <p>
                        Eazy DMS came into existence with its unique set of
                        features that overcomes all the limitations of web
                        portal based Distributor Management Systems (DMS). It
                        integrates manufacturer’s ERP with distributor’s
                        Tally/Busy to provide real time transaction details to
                        the manufacturer. There is no scope of frauds in terms
                        of scheme and claims management as details are validated
                        through genuine transactions. Eazy DMS also gives
                        complete control and power over distributors stock and
                        sales to manufacturers. There are numerous benefits of
                        Eazy DMS that manufacturers can secure for quick and
                        efficient decision making in terms of distributor
                        management. Our expertise lies not just in the product,
                        but in the way the rollouts are done PAN India basis.
                        FMCG/FMCD companies can also gain actionable insights
                        from scheme, pricing and sales transactions to make
                        smart business decisions with Eazy DMS.
                      </p>
                      <p></p>
                    </div>
                  </div>
                </article>
                <div class="clear"></div>
                <div class="single-post-comments"></div>
              </div>
              <div class="col-md-4">
                <RecentPost />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
