import React, { useState, useEffect } from "react";
import VisibilitySensor from "react-visibility-sensor";
import CountUp, { useCountUp } from "react-countup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import ServerAPI from "../../ServerApi";
import { ResponsePopUp } from "../Alert";
import Loader from "../Loader";

const RemarketingDmsLandingPage = () => {
  const [isLoading,setLoading]=useState(false)
  const [formDetails, setFormDetails] = useState({
    fullname: "",
    company: "",
    mobile: "",
    email: "",
    state: "",
  });
  const [responseDetails, setResponseDetails] = useState({
    isResponseOpen: false,
    responseContent: "",
    responseType: ""
  })
  const handleResponseClose = () => {
    setResponseDetails((prev) => ({ ...prev, isResponseOpen: false }))
  }



  const [errorDetails, setErrorsDetails] = useState({
    fullname: "",
    company: "",
    mobile: "",
    email: "",
    state: "",
  });

  const handleReset = () => {
    if (
      !formDetails.fullname ||
      !formDetails.company ||
      !formDetails.email ||
      !formDetails.mobile ||
      !formDetails.state
    ) {
      setTimeout(() => {
        setErrorsDetails({
          fullname: "",
          company: "",
          mobile: "",
          email: "",
          state: "",
        });
      }, 2000);
    }
  };

  const handleChange = (e) => {
    if (e.target) {
      let name = e.target.name;
      let value = e.target.value;
      setFormDetails((prev) => ({ ...prev, [name]: value }));
      setErrorsDetails((prev) => ({
        ...prev,
        [name]: value ? "" : "This Field Can't be Blank",
      }));
    } else if (e.element) {
      let name = e.element.id;
      let value = e.value;
      setFormDetails(() => ({ ...formDetails, [name]: value }));
    }
  };
  const handleKeyPress = (e) => {
    const value = e.key;
    const isNumber = /^[0-9]$/.test(value);
    if (!isNumber) {
      e.preventDefault();
    }
  };
  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    if (formDetails["fullname"] == "") {
      formIsValid = false;
      setErrorsDetails((prev) => ({
        ...prev,
        ["fullname"]: "This Field Can't be Blank",
      }));
    }
    if (formDetails["email"] == "") {
      formIsValid = false;
      setErrorsDetails((prev) => ({
        ...prev,
        ["email"]: "This Field Can't be Blank",
      }));
    } else if (formDetails["email"] != "") {
      const isEmail =
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/.test(
          formDetails.email
        );
      const isValidCompanyEmail = /^[a-zA-Z0-9_.+-]+@gmail\.com$/.test(
        formDetails.email
      );
      if (!isEmail) {
        formIsValid = false;
        setErrorsDetails((prev) => ({
          ...prev,
          ["email"]: "Enter a valid email Address",
        }));
      }
      if (isValidCompanyEmail) {
        formIsValid = false;
        setErrorsDetails((prev) => ({
          ...prev,
          ["email"]: "Enter a valid Company Email Address",
        }));
      }
    }

    if (formDetails["company"] == "") {
      formIsValid = false;
      setErrorsDetails((prev) => ({
        ...prev,
        ["company"]: "This Field Can't be Blank",
      }));
    }
    if (formDetails["mobile"] == "") {
      formIsValid = false;
      setErrorsDetails((prev) => ({
        ...prev,
        ["mobile"]: "This Field Can't be Blank",
      }));
    }
    if (formDetails["state"] == "") {
      formIsValid = false;
      setErrorsDetails((prev) => ({
        ...prev,
        ["state"]: "This Field Can't be Blank",
      }));
    }

    return formIsValid;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true)
      const payload = {
        contactDetail: {
          source: "EazyDMS",
          pageName: "remarketingdms",
          company_name: formDetails.company,
          name: formDetails.fullname,
          email: formDetails.email,
          mobile: formDetails.mobile,
          city: formDetails.state,
          sendEmail: "true",
        },
      };
      fetch(ServerAPI, {
        method: "post",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status == true || res.status == "true") {
            setResponseDetails({
              isResponseOpen: true,
              responseContent: res.result,
              responseType: "success"
            })
            setFormDetails({
              fullname: "",
              company: "",
              mobile: "",
              email: "",
              state: "",
            });
            setErrorsDetails({
              fullname: "",
              company: "",
              mobile: "",
              email: "",
              state: "",
            });
          } else {
            setResponseDetails({
              isResponseOpen: true,
              responseContent: res.error,
              responseType: "error"
            })
            setFormDetails({
              fullname: "",
              company: "",
              mobile: "",
              email: "",
              state: "",
            });
          }
          setLoading(false)
        })
        .catch((err) =>{
        setResponseDetails({
          isResponseOpen: true,
          responseContent: "Something Went Wrong",
          responseType: "error"
        })
        setLoading(false)
        console.log(err, "Error While trying to send request demo data")
      }
        );
    } 
  };

  return (
    <>
    {isLoading && <Loader/>}
      <section class="banner_campaign">
        <div class="fluid container" style={{ padding: "2rem" }}>
          <div className="row justify-content-center  mb-4">
          <img
                src="https://eazysites.s3.amazonaws.com/eazydms/campaign1/images/logo.png"
                class="logo"
              />

          </div>
          <div class="row">
            <div class="col-lg-8 col-xl-8 col-md-6 col-sm-12 text-center">
             
              <div class="iframe_main">
                <iframe
                  style={{
                    margin: "0 auto",
                    marginTop: "15px",
                    width: "560",
                    height: "315",
                  }}
                  src="https://www.youtube.com/embed/fz3Q8pi5azo"
                  frameborder="0"
                  allow="autoplay"
                  allowFullScreen
                ></iframe>
              </div>
            </div>

            <div class="col-lg-4 col-xl-4 col-md-6 col-sm-12">
              <form
                method="post"
                id="contactform"
                name="contactform"
                onSubmit={handleSubmit}
              >
                <div class="card border-primary rounded-0">
                  <div class="card-header p-0">
                    <div class="bg-info text-white text-center py-2">
                      <h5>
                        <i class="fa fa-envelope" style={{ color: "#fff" }}></i>{" "}
                        Request A Demo
                      </h5>
                      <p class="m-0"></p>
                    </div>
                  </div>
                  <div class="card-body p-3">
                    <div class="form-group">
                      <div class="input-group mb-2">
                        <div class="input-group-prepend">
                          <div
                            class="input-group-text"
                            style={{
                              borderBottomRightRadius: "0px",
                              borderTopRightRadius: "0px",
                            }}
                          >
                            <i
                              class="fa fa-user text-info"
                              style={{ fontSize: "24px" }}
                            ></i>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: "1",
                          }}
                        >
                          <input
                            type="text"
                            class="form-control"
                            id="fullname"
                            name="fullname"
                            onChange={handleChange}
                            placeholder="Full Name"
                            value={formDetails.fullname}
                            style={{
                              borderBottomLeftRadius: "0px",
                              borderTopLeftRadius: "0px",
                            }}
                          />
                          {errorDetails.fullname != "" ? (
                            <span style={{ color: "red" }}>
                              This Field Can't be Blank
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="row err" id="error_name"></div>

                    <div class="form-group">
                      <div class="input-group mb-2">
                        <div class="input-group-prepend">
                          <div
                            class="input-group-text"
                            style={{
                              borderBottomRightRadius: "0px",
                              borderTopRightRadius: "0px",
                            }}
                          >
                            <i
                              class="fa fa-building text-info"
                              style={{ fontSize: "24px" }}
                            ></i>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: "1",
                          }}
                        >
                          <input
                            type="text"
                            class="form-control"
                            id="company"
                            name="company"
                            value={formDetails.company}
                            placeholder="Company"
                            onChange={handleChange}
                            style={{
                              borderBottomLeftRadius: "0px",
                              borderTopLeftRadius: "0px",
                            }}
                          />
                          {errorDetails.company != "" ? (
                            <span style={{ color: "red" }}>
                              This Field Can't be Blank
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="row err" id="error_company_name"></div>

                    <div class="form-group">
                      <div class="input-group mb-2">
                        <div class="input-group-prepend">
                          <div
                            class="input-group-text"
                            style={{
                              borderBottomRightRadius: "0px",
                              borderTopRightRadius: "0px",
                            }}
                          >
                            <i
                              class="fa fa-phone text-info"
                              style={{ fontSize: "24px" }}
                            ></i>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: "1",
                          }}
                        >
                          <input
                            type="text"
                            class="form-control"
                            id="mobile"
                            name="mobile"
                            onKeyPress={handleKeyPress}
                            value={formDetails.mobile}
                            onChange={handleChange}
                            maxlength="15"
                            placeholder="Phone Number"
                            style={{
                              borderBottomLeftRadius: "0px",
                              borderTopLeftRadius: "0px",
                            }}
                          />
                          {errorDetails.mobile != "" ? (
                            <span style={{ color: "red" }}>
                              This Field Can't be Blank
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="row err" id="error_phone"></div>
                    <div class="form-group">
                      <div class="input-group mb-2">
                        <div class="input-group-prepend">
                          <div
                            class="input-group-text"
                            style={{
                              borderBottomRightRadius: "0px",
                              borderTopRightRadius: "0px",
                            }}
                          >
                            <i
                              class="fa fa-envelope text-info"
                              style={{ fontSize: "24px" }}
                            ></i>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: "1",
                          }}
                        >
                          <input
                            type="email"
                            class="form-control"
                            id="email"
                            name="email"
                            onChange={handleChange}
                            value={formDetails.email}
                            placeholder="Email"
                            style={{
                              borderBottomLeftRadius: "0px",
                              borderTopLeftRadius: "0px",
                            }}
                          />
                          {errorDetails.email != "" ? (
                            <span style={{ color: "red" }}>
                              {errorDetails.email}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="row err" id="error_email"></div>
                    <div class="form-group">
                      <div class="input-group mb-2">
                        <div class="input-group-prepend">
                          <div
                            class="input-group-text"
                            style={{
                              borderBottomRightRadius: "0px",
                              borderTopRightRadius: "0px",
                            }}
                          >
                            <i
                              class="fa fa-map-marker text-info"
                              style={{ fontSize: "24px" }}
                            ></i>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: "1",
                          }}
                        >
                          <select
                            class="form-control"
                            id="state"
                            name="state"
                            onChange={handleChange}
                            value={formDetails.state}
                            style={{
                              borderBottomLeftRadius: "0px",
                              borderTopLeftRadius: "0px",
                            }}
                          >
                            <option
                              value=""
                              label="Select a State"
                              selected="selected"
                            >
                              Select a State
                            </option>
                            <option value="Andhra Pradesh">
                              Andhra Pradesh
                            </option>
                            <option value="Arunachal Pradesh">
                              Arunachal Pradesh
                            </option>
                            <option value="Andaman and Nicobar Island">
                              Andaman and Nicobar Island
                            </option>
                            <option value="Assam">Assam</option>
                            <option value="Bihar">Bihar</option>
                            <option value="Chandigarh">Chandigarh</option>
                            <option value="Chhattisgarh">Chhattisgarh</option>
                            <option value="Dadra and Nagar Haveli and Daman and Diu">
                              Dadra and Nagar Haveli and Daman and Diu
                            </option>
                            <option value="Delhi">Delhi</option>
                            <option value="Goa">Goa</option>
                            <option value="Gujarat">Gujarat</option>
                            <option value="Haryana">Haryana</option>
                            <option value="Himachal Pradesh">
                              Himachal Pradesh
                            </option>
                            <option value="Jharkhand">Jharkhand</option>
                            <option value="Jammu and Kashmir">
                              Jammu and Kashmir
                            </option>
                            <option value="Karnataka">Karnataka</option>
                            <option value="Kerala">Kerala</option>
                            <option value="Ladakh">Ladakh</option>
                            <option value="Lakshadweep">Lakshadweep</option>
                            <option value="Madhya Pradesh">
                              Madhya Pradesh
                            </option>
                            <option value="Maharashtra">Maharashtra</option>
                            <option value="Manipur">Manipur</option>
                            <option value="Meghalaya">Meghalaya</option>
                            <option value="Mizoram">Mizoram</option>
                            <option value="Nagaland">Nagaland</option>
                            <option value="Odisha">Odisha</option>
                            <option value="Punjab">Punjab</option>
                            <option value="Puducherry">Puducherry</option>
                            <option value="Rajasthan">Rajasthan</option>
                            <option value="Sikkim">Sikkim</option>
                            <option value="Tamil Nadu">Tamil Nadu</option>
                            <option value="Telangana">Telangana</option>
                            <option value="Tripura">Tripura</option>
                            <option value="Uttar Pradesh">Uttar Pradesh</option>
                            <option value="Uttarakhand">Uttarakhand</option>
                            <option value="West Bengal">West Bengal</option>
                          </select>
                          {errorDetails.state != "" ? (
                            <span style={{ color: "red" }}>
                              This Field Can't be Blank
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="row err" id="error_place"></div>

                    <div class="form-group">
                      <div
                        class="g-recaptcha"
                        data-sitekey="6Le_aW8UAAAAAMWrCedYvbz-yDuEpSOnzMQJu6q9"
                        required
                      ></div>
                      <div id="errorCaptcha" style={{ color: "red" }}></div>
                    </div>

                    <input
                      style={{ fontWeight: "bold", fontSize: "18px" }}
                      //   style="font-weight: bold; font-size: 18px;"
                      type="submit"
                      value="GET A FREE DEMO"
                      class="btn btn-danger btn-block rounded-0 py-2"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section class="clients">
        <h2 style={{ width: "100%" }} class="section-title h1">
          Trusted by the Best
        </h2>
        <div class="container">
          <div class="row">
            <div class="col-md-2 col-6">
              <img
                src="https://eazysites.s3.amazonaws.com/eazydms/campaign1/images/logo1.jpg"
                class="client-logo"
              />
            </div>
            <div class="col-md-2 col-6">
              <img
                src="https://eazysites.s3.amazonaws.com/eazydms/campaign1/images/logo2.jpg"
                class="client-logo"
              />
            </div>
            <div class="col-md-2 col-6">
              <img
                src="https://eazysites.s3.amazonaws.com/eazydms/campaign1/images/logo3.jpg"
                class="client-logo"
              />
            </div>
            <div class="col-md-2 col-6">
              <img
                src="https://eazysites.s3.amazonaws.com/eazydms/campaign1/images/logo4.jpg"
                class="client-logo"
              />
            </div>
            <div class="col-md-2 col-6">
              <img
                src="https://eazysites.s3.amazonaws.com/eazydms/campaign1/images/logo5.jpg"
                class="client-logo"
              />
            </div>
            <div class="col-md-2 col-6">
              <img
                src="https://eazysites.s3.amazonaws.com/eazydms/campaign1/images/logo6.jpg"
                class="client-logo"
              />
            </div>
          </div>
        </div>
      </section>
      <section id="what-we-do">
        <div class="container">
          <h2 class="section-title mb-2 h1">Maximize your profitability</h2>
          <div class="row mt-3 adj_card_font v2">
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div class="card">
                <div class="card-block block-4">
                  <h5 class="card-title">
                    Tally <br />
                    Integration
                  </h5>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div class="card">
                <div class="card-block block-2">
                  <h5 class="card-title">
                    Scheme & Claims <br />
                    Management
                  </h5>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div class="card">
                <div class="card-block block-6">
                  <h5 class="card-title">
                    Purchase Order
                    <br />
                    Management
                  </h5>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div class="card">
                <div class="card-block block-3">
                  <h5 class="card-title">
                    Track Channel
                    <br />
                    Sales Performance
                    <br /> <span style={{ color: "#fff" }}>.</span>
                  </h5>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div class="card">
                <div class="card-block block-5">
                  <h5 class="card-title">
                    App Support for Order
                    <br />
                    Management (SFA Integration)
                  </h5>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div class="card">
                <div class="card-block block-1">
                  <h5 class="card-title">
                    Complete Master <br />& Price Control <br />
                    Across Supply Chain
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="modules set_ul">
        <div class="fluid-container">
          <div class="row row-eq-height">
            <div style={{ minHeight: "300px" }} class="col-md-6">
              <div class="info ">
                <img
                  src="https://eazysites.s3.amazonaws.com/eazydms/campaign1/images/banner2.jpg"
                  class="align-middle"
                />
              </div>
            </div>
            <div class="col-md-6 column-2">
              <h2 class="section-title mb-2 h1 text-left">Benefits</h2>
              <h3 style={{ fontSize: "1.5em" }}>for Distributors</h3>
              <ul>
                <li>Quicker processing of claims</li>
                <li>Automated alerts to avoid stock outs</li>
                <li>Ideal stocking based on sale patterns</li>
                <li>Know availability of stock at warehouse</li>
                <li>Eliminate needs of shipping paper bills</li>
                <li>Information on in-transit stock and delivery date</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div style={{ marginTop: "50px" }} class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <h2 class="section-title mb-2 h1">Awards & Recognition</h2>
            <p></p>
          </div>
          <div class="col-md-3">
            <div class="image-wrap text-left">
              <img
                src="https://eazysites.s3.amazonaws.com/eazydms/campaign1/images/award1.jpg"
                class="award"
              />
            </div>
            <div class="image-wrap text-center">
              <img
                src="https://eazysites.s3.amazonaws.com/eazydms/campaign1/images/award2.jpg"
                class="award"
              />
            </div>
            <div class="image-wrap text-right">
              <img
                src="https://eazysites.s3.amazonaws.com/eazydms/campaign1/images/award3.jpg"
                class="award "
              />
            </div>
          </div>
          <div style={{ marginTop: "60px" }} class="col-md-6 text-center">
            <img
              src="https://eazysites.s3.amazonaws.com/eazydms/campaign1/images/trophy.png"
              class="trophy mx-auto"
            />
          </div>
          <div class="col-md-3">
            <div class="image-wrap text-right">
              <img
                src="https://eazysites.s3.amazonaws.com/eazydms/campaign1/images/award4.jpg"
                class="award"
              />
            </div>
            <div class="image-wrap text-center">
              <img
                src="https://eazysites.s3.amazonaws.com/eazydms/campaign1/images/award5.jpg"
                class="award"
              />
            </div>
            <div class="image-wrap text-left">
              <img
                src="https://eazysites.s3.amazonaws.com/eazydms/campaign1/images/award6.jpg"
                class="award "
              />
            </div>
          </div>
        </div>
        <div
          style={{ marginBottom: "40px" }}
          class="row text-center main_counter"
        >
          <div class="col-md-3 col-1"></div>
          <div class="col-md-3 col-5 col-sm-6">
            <div class="counter">
              <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                {({ isVisible }) => (
                  <div class="counter">
                    <i class="fa fa-user fa-2x"></i>
                    <br />
                    {isVisible ? (
                      <CountUp
                        style={{ fontSize: "1.5em", fontWeight: "bold" }}
                        end={70}
                        duration="4"
                        suffix="+"
                      />
                    ) : null}

                    <p class="count-text ">Clients</p>
                  </div>
                )}
              </VisibilitySensor>
            </div>
          </div>

          <div class="col-md-3 col-5 col-sm-6">
            <div class="counter">
              <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                {({ isVisible }) => (
                  <div class="counter">
                    <i class="fa fa-coffee fa-2x"></i>
                    <br />
                    {isVisible ? (
                      <CountUp
                        style={{ fontSize: "1.5em", fontWeight: "bold" }}
                        end={10000}
                        duration="5"
                        suffix="+"
                      />
                    ) : null}

                    <p class="count-text ">Distributors</p>
                  </div>
                )}
              </VisibilitySensor>
            </div>
          </div>
          <div class="col-md-3 col-1"></div>
        </div>
      </div>
      <footer>
        <div class="container-fluid py-3">
          <div class="container">
            <div class="row">
              <div class="col-md-8">
                <div class="row py-0">
                  <div class="col-sm-1 hidden-md-down">
                    <a class="bg-circle bg-info" href="#">
                      <i
                        class="fa fa-2x fa-fw fa-address-card"
                        aria-hidden="true "
                      ></i>
                    </a>
                  </div>
                  <div class="col-sm-11 text-white">
                    <div style={{ color: "#fff" }}>
                      <h4>Contact</h4>
                      <p style={{ color: "#fff" }}>
                        +91 8130285554 | contact@eazydms.com
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 text-right copy">
                <div class="d-inline-block social">
                  <div
                    class="bg-circle-outline icon d-inline-block"
                    style={{ background: "#3b5998", marginRight: "10px" }}
                  >
                    <a
                      target="_blank"
                      href="https://www.facebook.com/eazyerp/?ref=settings"
                    >
                      <span className={"sociallandingbIcon"}>
                        <FontAwesomeIcon
                          icon={faFacebook}
                          style={{ fontSize: "22px" }}
                        />
                      </span>

                      {/* <i class="fab fa-fw fa-facebook text-white"></i> */}
                    </a>
                  </div>
                  <div
                    class="bg-circle-outline icon d-inline-block"
                    style={{ background: "#3b5998", marginRight: "10px" }}
                  >
                    <a target="_blank" href="https://twitter.com/EazyERP">
                      <span className={"sociallandingbIcon"}>
                        <FontAwesomeIcon
                          icon={faTwitter}
                          style={{ fontSize: "22px" }}
                        />
                      </span>
                      {/* <i class="fab fa-fw fa-twitter text-white"></i> */}
                    </a>
                  </div>

                  <div
                    class="bg-circle-outline icon d-inline-block"
                    style={{ background: "#3b5998", marginRight: "10px" }}
                  >
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/singhal-system-solutions-pvt-ltd---offical-page/"
                    >
                      <span className={"sociallandingbIcon"}>
                        <FontAwesomeIcon
                          icon={faLinkedin}
                          style={{ fontSize: "22px" }}
                        />
                      </span>
                      {/* <i class="fab fa-fw fa-linkedin text-white"></i> */}
                    </a>
                  </div>
                  <div
                    class="bg-circle-outline icon d-inline-block"
                    style={{ background: "#d34836", marginRight: "10px" }}
                  >
                    <a href="https://www.eazydms.com">
                      <i class="fa fa-fw fa-globe text-white"></i>
                    </a>
                  </div>
                  <p>
                    <span
                      class="header-font"
                      style={{ letterSpacing: "1px", color: "#fff" }}
                    >
                      www.eazydms.com
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <p class="text-center m-1 copy" style={{ color: "black" }}>
        ©2019 EAZY Business Solutions. All right reserved.{" "}
      </p>
      <ResponsePopUp
        open={responseDetails.isResponseOpen}
        onClose={handleResponseClose}
        maxWidth={"xs"}
        content={responseDetails.responseContent}
        type={responseDetails.responseType}

      />
    </>
  );
};

export default RemarketingDmsLandingPage;
