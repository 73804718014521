import { faPlay, faSquareFull } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import YouTube from "react-youtube";

const CustomYouTubePlayer = ({
  videoId,
  refs,
  handlePlayButton,
  onReady,
  showIcon,
}) => {
  useEffect(() => {
    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    return () => {
      // Cleanup code if component unmounts
      // (e.g., remove event listeners, clear intervals, etc.)
    };
  }, []);

  return (
    <>
      <YouTube
        videoId={videoId} // Replace with the actual YouTube video ID
        opts={{
          playerVars: {
            autoplay: 1,
            modestbranding: 1,
            showinfo: 0,
            controls: 0,
          },
        }}
        onPlay={onReady}
        ref={refs}
        style={{ height: "300px" }}
      />
      {showIcon && (
        <span className="play-icon" onClick={handlePlayButton}>
          <FontAwesomeIcon icon={faPlay} />
        </span>
      )}
    </>
  );
};

export default CustomYouTubePlayer;

// import React from "react";

// const YouTubePlayer = ({ videoId }) => {
//   const embedUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1&modestbranding=1&showinfo=0&controls=0`;

//   return (
//     <iframe
//       width="560"
//       height="315"
//       src={embedUrl}
//       frameBorder="0"
//       allowFullScreen
//       title="YouTube Video"
//     />
//   );
// };

// export default YouTubePlayer;
