import React, { useEffect, useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CustomYouTubePlayer from "./SampleYoutube";
import TruncateText from '../Truncate/TruncateText';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import "./custom1.css";
import YouTube from "react-youtube";
export const CustomerFooter = () => {
  let SliderYoutube = useRef();
  let sliderTestimony = useRef();
  let video1 = useRef();
  let video2 = useRef();
  let video3 = useRef();
  let video4 = useRef();
  let video5 = useRef();

  const [currentSlide, setCurrentSlide] = useState(0);

  const PrevTestimony = () => {
    sliderTestimony.current.onClickNext();
  };
  const NextTestimony = () => {
    sliderTestimony.current.onClickPrev();
  };

  const previous = () => {
    SliderYoutube.current.slickNext();
  };
  const next = () => {
    SliderYoutube.current.slickPrev();
  };

  const videoDetails = [
    { id: "0", videoId: "r7Gfc1R0U2w", refValue: video1 },
    { id: "1", videoId: "uHSTO7cQsbU", refValue: video2 },
    { id: "2", videoId: "klx3b3_E16U", refValue: video3 },
    { id: "3", videoId: "Z4FHOVaBVOQ", refValue: video4 },
    { id: "4", videoId: "2pHo6CcIBqg", refValue: video5 },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0",
  };

  const handlePlayButton = (args) => {
    // video1.current.internalPlayer.playVideo();
    console.log(args);
    // args.refValue.current.internalPlayer.playVideo();
  };

  return (
    <>
      <div
        className="about-area area-padding bg-color"
        data-stellar-background-ratio="0.6"
        style={{ height: "380px !important" }}
      >
        <div className="container">
          <div className="row my-4">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="section-headline white-headline text-center">
                <h4
                  style={{
                    fontSize: "30px",
                    letterSpacing: "2px",
                    lineHeight: "32px",
                    display: "inline-block",
                    maxWidth: "100%",
                  }}
                >
                  Why our Customers love us!
                </h4>
              </div>
            </div>
          </div>
        </div>

        <Slider
          ref={SliderYoutube}
          {...settings}
          afterChange={(current) => setCurrentSlide(current)}
        >
          {videoDetails &&
            videoDetails.map((arg, index) => (
              <div className="video-container" key={index}>
                <div
                  className={`itemSlider ${currentSlide == arg.id ? "slick-current" : ""
                    }`}
                >
                  {" "}
                  {/* <YouTube
                    videoId={arg.videoId}
                    ref={arg.refValue}
                    style={{ height: "300px" }}
                    opts={{
                      playerVars: {
                        autoplay: 0,
                        modestbranding: 1,
                        showinfo: 0,
                        controls: 0,
                        rel: 0,
                      },
                    }}
                  /> */}
                  <iframe class="video"

                    src={`https://www.youtube.com/embed/${arg.videoId}?enablejsapi=1&showinfo=0&controls=1&loop=0&rel=0&modestbranding=1`}
                    ref={arg.refValue} frameborder="0" allowfullscreen></iframe>
                  {/* <span
                    className="play-icon"
                    onClick={() => handlePlayButton(arg)}
                  >
                    <FontAwesomeIcon icon={faPlay} />
                  </span> */}
                </div>
              </div>
            ))}
        </Slider>

        <div className="CustomerSliderButton">
          <button className="btn customerSlidercssforbutton" onClick={next}>
            <FontAwesomeIcon icon={faLongArrowAltLeft} />
          </button>
          <button className="btn customerSlidercssforbutton" onClick={previous}>
            <FontAwesomeIcon icon={faLongArrowAltRight} />
          </button>
        </div>
      </div>

      <div className="container py-5">
        <div
          className="text-center mx-auto wow fadeInUp position_rel"
          data-wow-delay="0.1s"
          style={{ maxWidth: "500px" }}
        >
          <h1 className="mb-5">TESTIMONIALS</h1>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-11 wow fadeIn" data-wow-delay="0.5s">
            <div className="owl-carousel customer_testi">
              <Carousel
              className=""
                autoPlay
                infiniteLoop={true}
                interval={3000}
                showArrows={false}
                ref={sliderTestimony}
                showIndicators={false}
                showStatus={false}
              >
                <div className="testimonial-item text-center">
                  <div className="testimonial-img">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/doms.png"
                      alt=""
                    />
                   
                  </div>
                  <div className="testimonial-text">
                    <TruncateText text="..it has helped us on getting daily updated data on
                      superstockists to distributor billing, ..done away with
                      manual data compiling work at our end and also helped in
                      Auto-replenishment of stocks resulting in smooth flow at
                      our Superstockist." maxLength={200} />

                  </div>
                  <div className="client-name">
                      <h4>Mr. Krishna Anand</h4>
                      <span>
                        Vice-president Sales, DOMS Industries Pvt. Ltd
                      </span>
                    </div>
                </div>

                <div className="testimonial-item text-center">
                  <div className="testimonial-img ">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/WEIK.png"
                      alt=""
                    />
                    
                  </div>
                  <div className="testimonial-text">

                    <TruncateText text="..our challenges were to capture channel sales and
                      inventory without investing much on the infrastructure
                      .with the strong support of EazyDMS team, together we can
                      plug the gap."
                      maxLength={200} />
                  </div>
                  <div className="client-name">
                      <h4>Mr. Amit Singh</h4>
                      <span>Digital Transformation Head, Weikfield</span>
                    </div>
                </div>

                <div className="testimonial-item text-center">
                  <div className="testimonial-img ">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/MERIONO.png"
                      alt="merino"
                    />
                   
                  </div>
                  <div className="testimonial-text">
                    <TruncateText text="EazyDMS allows hosting Oracle Cloud that provides the
                      security as well as reliability to our customers, we are
                      able to do the inventory monitoring which helps the
                      company to serve the customers more efficiently."
                      maxLength={200} />
                  </div>
                  <div className="client-name">
                      <h4>Mr. Anand Chaudhary</h4>
                      <span>Head IT, Merino Industries</span>
                    </div>
                </div>

                <div className="testimonial-item text-center">
                  <div className="testimonial-img ">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/rrcables.jpg"
                      alt=" RR electric"
                    />
                   
                  </div>
                  <div className="testimonial-text">
                    <TruncateText text="..we can map all the parameters of a secondary sales
                      transaction which has helped us to strategize in a better
                      way in the distribution network. It was also easy for the
                      distributors as they don't have to spend any extra effort
                      to maintain Tally, by syncing it every day."
                      maxLength={200} />
                  </div>
                  <div className="client-name">
                      <h4>Mr. Dipankar Dutta</h4>
                      <span>National Sales Head of RR SNM</span>
                    </div>
                </div>

                <div className="testimonial-item text-center">
                  <div className="testimonial-img ">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/Scheneider_L&T_logo.png"
                      alt=""
                    />
                   
                  </div>
                  <div className="testimonial-text">
                    <TruncateText text="today we have a significantly good accurate information
                      about our secondary sales. This has also helped us to
                      reduce the cost through the savings which we could do by
                      shifting the schemes from Primary to Secondary."
                      maxLength={200} />
                  </div>
                  <div className="client-name">
                      <h4>Mr. Sameer Chaudhary</h4>
                      <span>Scheneider</span>
                    </div>
                </div>

                <div className="testimonial-item text-center">
                  <div className="testimonial-img ">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/KENSTAR.png"
                      alt=""
                    />
                    
                  </div>
                  <div className="testimonial-text">
                    <TruncateText text="it's really a full Integrated platform which works very
                      easily with Tally Busy Accounting systems...they have a
                      lot of new features which we'll be a part of in their
                      journey."
                      maxLength={200} />
                  </div>
                  <div className="client-name">
                      <h4>Mr. Pratyush</h4>
                      <span>Strategic Team, Kenstar</span>
                    </div>
                </div>

                <div className="testimonial-item text-center">
                  <div className="testimonial-img ">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/Astal_pipes.png"
                      alt=""
                    />
                    
                  </div>
                  <div className="testimonial-text">
                    <TruncateText text="The good thing that I find about this team is how
                      solution oriented they are. Every connection and
                      discussion that we have usually ends up with an Action
                      Plan, which eventually resolves our issues."
                      maxLength={200} />
                  </div>
                  <div className="client-name">
                      <h4>Mr. Hemant</h4>
                      <span>Astral Adhesives</span>
                    </div>
                </div>

                <div className="testimonial-item text-center">
                  <div className="testimonial-img ">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/jamna-dms.jpg"
                      alt=""
                    />
                   
                  </div>
                  <div className="testimonial-text">
                    <TruncateText text="After implementing the loyalty, we analysed that it is
                      different from the scemes, so it has helped us a lot in
                      retaining customer engagement and gain profits."
                      maxLength={200} />
                  </div>
                  <div className="client-name">
                      <h4>Mr.Lavesh</h4>
                      <span>Jamna Auto Industries Ltd.</span>
                    </div>
                </div>

                <div className="testimonial-item text-center">
                  <div className="testimonial-img ">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/3.jpg"
                      alt=""
                    />
                   
                  </div>
                  <div className="testimonial-text">
                    <TruncateText text="One of our major pain areas was collecting secondary sales
                      data on-time. After evaluating all the available
                      Distributor Management Software's in the market, we
                      narrowed down Eazy DMS due to its ability to fetch
                      information from distributor's Tally. This allowed us to
                      convince our distributors easily since we were not
                      burdening them with any additional work."
                      maxLength={200} />
                  </div>
                  <div className="client-name">
                      <h4>Mr. Manish Verma</h4>
                      <span>Head - Sales Operations, Goodyear</span>
                    </div>
                </div>

                <div className="testimonial-item text-center">
                  <div className="testimonial-img ">

                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/jamna-dms.jpg"
                      alt=""
                    />

                   

                  </div>
                  <div className="testimonial-text">


                    <TruncateText text="Eazy DMS helps us keep our eyes on the most crucial part
                      of our business - our secondary sales. We were already
                      using ERP software to map our primary sales and were
                      searching for a viable option for managing channel sales.
                      Most of the products available in the market required us
                      to set up a separate portal at the distributor's end -
                      which we didn't want. We were approached by Eazy DMS,
                      which brings in a new concept of integrating with the
                      distributor's Tally and providing information on his stock
                      & sales in real time. So, there is no need to burden the
                      distributor with additional software. They have given us
                      active support throughout the implementation period and
                      even today, as we are rolling out more and more new
                      distributors. We are confident knowing that the system
                      that we have in place is scalable to where we want it to
                      go." maxLength={200} />

                  </div>
                  <div className="client-name">
                      <h4>Lavesh Verma</h4>
                      <span>Head of IT, Jamna Auto Industries</span>
                    </div>
                </div>

                <div className="testimonial-item text-center">
                <div className="testimonial-img ">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/anmol.jpg"
                      alt=""
                    />
                    
                  </div>
                  <div className="testimonial-text">
                    <TruncateText text="With the help of Eazy DMS our Secondary Sales process has
                      streamlined and is improving day by day, leading to rise
                      in sales by 5-10%. Initially we on-boarded our super
                      stockiest on Eazy DMS and after seeing the results we are
                      on-boarding all distributors."
                      maxLength={200} />
                  </div>
                  <div className="client-name">
                      <h4>Gaurav Gupta</h4>
                      <span>IT Manager, Anmol Industries Ltd</span>
                    </div>
                </div>
              </Carousel>
              <div className="CustomerSliderButton">
                <button
                  className="btn customerSlidercssforbutton"
                  onClick={NextTestimony}
                >
                  <FontAwesomeIcon icon={faLongArrowAltLeft} />
                </button>
                <button
                  className="btn customerSlidercssforbutton"
                  onClick={PrevTestimony}
                >
                  <FontAwesomeIcon icon={faLongArrowAltRight} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
