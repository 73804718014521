import React, { useState, useEffect, useRef } from "react";
import VisibilitySensor from "react-visibility-sensor";
import CountUp, { useCountUp } from "react-countup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
} from "@fortawesome/free-solid-svg-icons";
import { Carousel } from "react-responsive-carousel";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import ServerAPI from "../../ServerApi";
import {
  faBuilding,
  faEnvelope,
  faMapMarkerAlt,
  faPhoneAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import StyleEmail from "./emaildms.module.css";
import { ResponsePopUp } from "../Alert";
import Loader from "../Loader";

const EmailDmsPage = () => {
 
  let sliderTestimony = useRef();
  const [isLoading,setLoading]=useState(false)
  const [responseDetails,setResponseDetails]= useState({
    isResponseOpen:false,
    responseContent:"",
    responseType:""
  })
  const handleResponseClose =()=>{
    setResponseDetails((prev)=>({...prev,isResponseOpen:false}))
  }
  const PrevTestimony = () => {
    sliderTestimony.current.onClickNext();
  };
  const NextTestimony = () => {
    sliderTestimony.current.onClickPrev();
  };
  const [formDetails, setFormDetails] = useState({
    fullname: "",
    company: "",
    mobile: "",
    email: "",
    state: "",
  });

  const [errorDetails, setErrorsDetails] = useState({
    fullname: "",
    company: "",
    mobile: "",
    email: "",
    state: "",
  });
  const handleReset = () => {
    if (
      !formDetails.fullname ||
      !formDetails.company ||
      !formDetails.email ||
      !formDetails.mobile ||
      !formDetails.state
    ) {
      setTimeout(() => {
        setErrorsDetails({
          fullname: "",
          company: "",
          mobile: "",
          email: "",
          state: "",
        });
      }, 2000);
    }
  };
  const handleChange = (e) => {
    if (e.target) {
      let name = e.target.name;
      let value = e.target.value;
      setFormDetails((prev) => ({ ...prev, [name]: value }));
      setErrorsDetails((prev) => ({
        ...prev,
        [name]: value ? "" : "This Field Can't be Blank",
      }));
    } else if (e.element) {
      let name = e.element.id;
      let value = e.value;
      setFormDetails(() => ({ ...formDetails, [name]: value }));
    }
  };
  const handleKeyPress = (e) => {
    const value = e.key;
    const isNumber = /^[0-9]$/.test(value);
    if (!isNumber) {
      e.preventDefault();
    }
  };
  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    if (formDetails["fullname"] == "") {
      formIsValid = false;
      setErrorsDetails((prev) => ({
        ...prev,
        ["fullname"]: "This Field Can't be Blank",
      }));
    }
    if (formDetails["email"] == "") {
      formIsValid = false;
      setErrorsDetails((prev) => ({
        ...prev,
        ["email"]: "This Field Can't be Blank",
      }));
    } else if (formDetails["email"] != "") {
      const isEmail =
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/.test(
          formDetails.email
        );
      const isValidCompanyEmail = /^[a-zA-Z0-9_.+-]+@gmail\.com$/.test(
        formDetails.email
      );
      if (!isEmail) {
        formIsValid = false;
        setErrorsDetails((prev) => ({
          ...prev,
          ["email"]: "Enter a valid email Address",
        }));
      }
      if (isValidCompanyEmail) {
        formIsValid = false;
        setErrorsDetails((prev) => ({
          ...prev,
          ["email"]: "Enter a valid Company Email Address",
        }));
      }
    }

    if (formDetails["company"] == "") {
      formIsValid = false;
      setErrorsDetails((prev) => ({
        ...prev,
        ["company"]: "This Field Can't be Blank",
      }));
    }
    if (formDetails["mobile"] == "") {
      formIsValid = false;
      setErrorsDetails((prev) => ({
        ...prev,
        ["mobile"]: "This Field Can't be Blank",
      }));
    }
    if (formDetails["state"] == "") {
      formIsValid = false;
      setErrorsDetails((prev) => ({
        ...prev,
        ["state"]: "This Field Can't be Blank",
      }));
    }

    return formIsValid;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true)
      const payload = {
        contactDetail: {
          source: "EazyDMS",
          pageName: "emaildms",
          company_name: formDetails.company,
          name: formDetails.fullname,
          email: formDetails.email,
          mobile: formDetails.mobile,
          city: formDetails.state,
          sendEmail: "true",
        },
      };
      fetch(ServerAPI, {
        method: "post",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status == true || res.status == "true") {
            setResponseDetails({
              isResponseOpen:true,
              responseContent:res.result,
              responseType:"success"
            })
            setFormDetails({
              fullname: "",
              company: "",
              mobile: "",
              email: "",
              state: "",
            });
            setErrorsDetails({
              fullname: "",
              company: "",
              mobile: "",
              email: "",
              state: "",
            });
          } else {
            setResponseDetails({
              isResponseOpen:true,
              responseContent:res.error,
              responseType:"error"
            })
            setFormDetails({
              fullname: "",
              company: "",
              mobile: "",
              email: "",
              state: "",
            });
          }
          setLoading(false)
        })
        .catch((err) =>
        {setResponseDetails({
          isResponseOpen:true,
          responseContent:"Something Went Wrong",
          responseType:"error"
        })
        setLoading(false)
          console.log(err, "Error While trying to send request demo data")}
        );
    }
  };
  return (
    <>
    {isLoading && <Loader/>}
      <section className={StyleEmail.banner}>
        <div class="container" style={{ padding: "2rem" }}>
          <div class="row">
            <div
              style={{ margin: "0 auto" }}
              class="col-md-8 col-sm-10 col-xs-12 text-center"
            >
              <img
                style={{ marginBottom: "20px" }}
                src="https://eazysites.s3.amazonaws.com/eazydms/emaildms/images/e-logo.png"
                class="logo"
              />

              <h2 class="m-2" style={{ color: "#fff" }}>
                Distributor Management Software for Tracking FMCG/FMCD Secondary
                Sales
              </h2>
            </div>
          </div>
          <div style={{ marginTop: "20px" }} class="row">
            <div class="col-md-8 text-center">
              <iframe
                style={{ margin: "0 auto", marginTop: "15px" }}
                width="100%"
                height="400px"
                src="https://www.youtube.com/embed/fz3Q8pi5azo"
                frameborder="0"
                allow="autoplay; encrypted-media"
                allowfullscreen
              ></iframe>
            </div>

            <div class="col-md-4">
              <form
                style={{ marginTop: "15px" }}
                method="post"
                id={StyleEmail.contactform}
                name="contactform"
                onSubmit={handleSubmit}
              >
                <div className={`${StyleEmail.card}  ${StyleEmail.rounded_0}`}>
                  <div className={`${StyleEmail.card_header} p-0`}>
                    <div class=" text-white text-center py-2">
                      <h5>
                        <i class="fa fa-envelope" style={{ color: "#fff" }}></i>{" "}
                        Request A Demo
                      </h5>
                      <p class="m-0"></p>
                    </div>
                  </div>
                  <div
                    class="card-body p-3"
                    // className={`$`}
                  >
                    <div
                      // class="form-group"
                      className={StyleEmail.form_group}
                    >
                      <div class="input-group mb-2">
                        <div
                          // class="input-group-prepend"
                          className={StyleEmail.input_group_prepand}
                        >
                          <div
                            class="input-group-text"
                            style={{
                              borderBottomRightRadius: "0px",
                              borderTopRightRadius: "0px",
                            }}
                          >
                            <span
                              className="landingFormIcon"
                              //
                            >
                              <FontAwesomeIcon icon={faUser} />
                            </span>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: "1",
                          }}
                        >
                          <input
                            type="text"
                            class="form-control"
                            id="fullname"
                            name="fullname"
                            onChange={handleChange}
                            value={formDetails.fullname}
                            placeholder="Full Name"
                            style={{
                              borderBottomLeftRadius: "0px",
                              borderTopLeftRadius: "0px",
                            }}
                          />
                          {errorDetails.fullname != "" ? (
                            <span style={{ color: "red" }}>
                              This Field Can't be Blank
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="row err" id="error_name"></div>

                    <div class="form-group">
                      <div class="input-group mb-2">
                        <div class="input-group-prepend">
                          <div
                            class="input-group-text"
                            style={{
                              borderBottomRightRadius: "0px",
                              borderTopRightRadius: "0px",
                            }}
                          >
                            <span className="landingFormIcon">
                              <FontAwesomeIcon icon={faBuilding} />
                            </span>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: "1",
                          }}
                        >
                          <input
                            type="text"
                            class="form-control"
                            id="company"
                            name="company"
                            placeholder="Company"
                            value={formDetails.company}
                            onChange={handleChange}
                            style={{
                              borderBottomLeftRadius: "0px",
                              borderTopLeftRadius: "0px",
                            }}
                          />
                          {errorDetails.company != "" ? (
                            <span style={{ color: "red" }}>
                              This Field Can't be Blank
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="row err" id="error_company_name"></div>

                    <div class="form-group">
                      <div class="input-group mb-2">
                        <div class="input-group-prepend">
                          <div
                            class="input-group-text"
                            style={{
                              borderBottomRightRadius: "0px",
                              borderTopRightRadius: "0px",
                            }}
                          >
                            <span className="landingFormIcon">
                              <FontAwesomeIcon icon={faPhoneAlt} />
                            </span>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: "1",
                          }}
                        >
                          <input
                            type="text"
                            class="form-control"
                            id="mobile"
                            name="mobile"
                            onChange={handleChange}
                            value={formDetails.mobile}
                            maxlength="15"
                            placeholder="Phone Number"
                            style={{
                              borderBottomLeftRadius: "0px",
                              borderTopLeftRadius: "0px",
                            }}
                          />
                          {errorDetails.mobile != "" ? (
                            <span style={{ color: "red" }}>
                              This Field Can't be Blank
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="row err" id="error_phone"></div>
                    <div class="form-group">
                      <div class="input-group mb-2">
                        <div class="input-group-prepend">
                          <div
                            class="input-group-text"
                            style={{
                              borderBottomRightRadius: "0px",
                              borderTopRightRadius: "0px",
                            }}
                          >
                            <span className="landingFormIcon">
                              <FontAwesomeIcon icon={faEnvelope} />
                            </span>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: "1",
                          }}
                        >
                          <input
                            type="email"
                            class="form-control"
                            id="email"
                            name="email"
                            onChange={handleChange}
                            value={formDetails.email}
                            placeholder="Email"
                            style={{
                              borderBottomLeftRadius: "0px",
                              borderTopLeftRadius: "0px",
                            }}
                          />
                          {errorDetails.email != "" ? (
                            <span style={{ color: "red" }}>
                              {errorDetails.email}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="row err" id="error_email"></div>
                    <div class="form-group">
                      <div class="input-group mb-2">
                        <div class="input-group-prepend">
                          <div
                            class="input-group-text"
                            style={{
                              borderBottomRightRadius: "0px",
                              borderTopRightRadius: "0px",
                            }}
                          >
                            <span className="landingFormIcon">
                              <FontAwesomeIcon icon={faMapMarkerAlt} />
                            </span>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: "1",
                          }}
                        >
                          <select
                            class="form-control"
                            id="state"
                            name="state"
                            value={formDetails.state}
                            onChange={handleChange}
                            style={{
                              borderBottomLeftRadius: "0px",
                              borderTopLeftRadius: "0px",
                            }}
                          >
                            <option
                              value=""
                              label="Select a State"
                              selected="selected"
                            >
                              Select a State
                            </option>
                            <option value="Andhra Pradesh">
                              Andhra Pradesh
                            </option>
                            <option value="Arunachal Pradesh">
                              Arunachal Pradesh
                            </option>
                            <option value="Andaman and Nicobar Island">
                              Andaman and Nicobar Island
                            </option>
                            <option value="Assam">Assam</option>
                            <option value="Bihar">Bihar</option>
                            <option value="Chandigarh">Chandigarh</option>
                            <option value="Chhattisgarh">Chhattisgarh</option>
                            <option value="Dadra and Nagar Haveli and Daman and Diu">
                              Dadra and Nagar Haveli and Daman and Diu
                            </option>
                            <option value="Delhi">Delhi</option>
                            <option value="Goa">Goa</option>
                            <option value="Gujarat">Gujarat</option>
                            <option value="Haryana">Haryana</option>
                            <option value="Himachal Pradesh">
                              Himachal Pradesh
                            </option>
                            <option value="Jharkhand">Jharkhand</option>
                            <option value="Jammu and Kashmir">
                              Jammu and Kashmir
                            </option>
                            <option value="Karnataka">Karnataka</option>
                            <option value="Kerala">Kerala</option>
                            <option value="Ladakh">Ladakh</option>
                            <option value="Lakshadweep">Lakshadweep</option>
                            <option value="Madhya Pradesh">
                              Madhya Pradesh
                            </option>
                            <option value="Maharashtra">Maharashtra</option>
                            <option value="Manipur">Manipur</option>
                            <option value="Meghalaya">Meghalaya</option>
                            <option value="Mizoram">Mizoram</option>
                            <option value="Nagaland">Nagaland</option>
                            <option value="Odisha">Odisha</option>
                            <option value="Punjab">Punjab</option>
                            <option value="Puducherry">Puducherry</option>
                            <option value="Rajasthan">Rajasthan</option>
                            <option value="Sikkim">Sikkim</option>
                            <option value="Tamil Nadu">Tamil Nadu</option>
                            <option value="Telangana">Telangana</option>
                            <option value="Tripura">Tripura</option>
                            <option value="Uttar Pradesh">Uttar Pradesh</option>
                            <option value="Uttarakhand">Uttarakhand</option>
                            <option value="West Bengal">West Bengal</option>
                          </select>
                          {errorDetails.state != "" ? (
                            <span style={{ color: "red" }}>
                              This Field Can't be Blank
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="row err" id="error_place"></div>

                    <div class="form-group">
                      <div
                        class="g-recaptcha"
                        data-sitekey="6Le_aW8UAAAAAMWrCedYvbz-yDuEpSOnzMQJu6q9"
                        required
                      ></div>
                      <div id="errorCaptcha" style={{ color: "red" }}></div>
                    </div>

                    <div class="text-center">
                      <input
                        type="submit"
                        value="Submit"
                        class="btn btn-danger btn-block rounded-0 py-2"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section style={{ background: "#fafafa" }} id={StyleEmail.what_we_do}>
        <div class="container">
          <div class="row mt-3">
            <div
              style={{ margin: "0 auto" }}
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            >
              <h2 className={`${StyleEmail.section_title} mb-2 h1`}>
                Current Challenges in Channel Management
              </h2>
              <div className={StyleEmail.dash}></div>
            </div>
            <div class="row mt-3">
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                <div className={StyleEmail.card1}>
                  <div
                    className={`${StyleEmail.card_block} ${StyleEmail.block_i}`}
                  >
                    <h5
                      className={StyleEmail.card_title}
                      style={{ color: "#212529" }}
                    >
                      Absence of Real time Channel Sales Data
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                <div className={StyleEmail.card1}>
                  <div
                    className={`${StyleEmail.card_block} ${StyleEmail.block_i}`}
                  >
                    <h5
                      className={StyleEmail.card_title}
                      style={{ color: "#212529" }}
                    >
                      Time Consuming Manual Data Collection
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                <div className={StyleEmail.card1}>
                  <div
                    className={`${StyleEmail.card_block} ${StyleEmail.block_i}`}
                  >
                    <h5
                      className={StyleEmail.card_title}
                      style={{ color: "#212529" }}
                    >
                      Resistance from Distributors
                    </h5>
                  </div>
                </div>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                <div className={StyleEmail.card1}>
                  <div
                    className={`${StyleEmail.card_block} ${StyleEmail.block_i}`}
                  >
                    <h5
                      className={StyleEmail.card_title}
                      style={{ color: "#212529" }}
                    >
                      {" "}
                      Multiple Brand Management for Distributors
                    </h5>
                  </div>
                </div>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                <div className={StyleEmail.card1}>
                  <div
                    className={`${StyleEmail.card_block} ${StyleEmail.block_i}`}
                  >
                    <h5
                      className={StyleEmail.card_title}
                      style={{ color: "#212529" }}
                    >
                      {" "}
                      Loss of Sales due to Lack of Coordination
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                <div className={StyleEmail.card1}>
                  <div
                    className={`${StyleEmail.card_block} ${StyleEmail.block_i}`}
                  >
                    <h5
                      className={StyleEmail.card_title}
                      style={{ color: "#212529" }}
                    >
                      Misappropriation of Sales, Discount & Scheme Data by
                      Distributors{" "}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="what-we-do" style={{ paddingTop: "0px !important" }}>
        <div class="container">
          <div class="row mt-3">
            <div
              style={{ margin: "0 auto" }}
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            >
              <h2 class="section-title mb-2 h1">
                Smoothly Manage your Entire Distributor Network!
              </h2>
              <div className={StyleEmail.dash}></div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div class="card">
                <div class="card-block block-4">
                  <h5
                    className={StyleEmail.card_title}
                    style={{ color: "#212529" }}
                  >
                    Tally/Busy
                    <br />
                    Integration
                  </h5>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div class="card">
                <div class="card-block block-10">
                  <h5
                    className={StyleEmail.card_title}
                    style={{ color: "#212529" }}
                  >
                    Scheme & Claims <br />
                    Management
                  </h5>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div class="card">
                <div class="card-block block-6">
                  <h5
                    className={StyleEmail.card_title}
                    style={{ color: "#212529" }}
                  >
                    Replenishment & Order
                    <br />
                    Management
                  </h5>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div class="card">
                <div class="card-block block-3">
                  <h5
                    className={StyleEmail.card_title}
                    style={{ color: "#212529" }}
                  >
                    {" "}
                    Channel Sales
                    <br />
                    Performance Tracking
                  </h5>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div class="card">
                <div class="card-block block-11">
                  <h5
                    className={StyleEmail.card_title}
                    style={{ color: "#212529" }}
                  >
                    {" "}
                    Integrated <br />
                    SFA
                  </h5>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div class="card">
                <div class="card-block block-12">
                  <h5
                    className={StyleEmail.card_title}
                    style={{ color: "#212529" }}
                  >
                    Complete Master <br />& Price Control{" "}
                  </h5>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div class="card">
                <div class="card-block block-13">
                  <h5
                    className={StyleEmail.card_title}
                    style={{ color: "#212529" }}
                  >
                    Rich Data <br />
                    Analytics
                  </h5>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div class="card">
                <div class="card-block block-5">
                  <h5
                    className={StyleEmail.card_title}
                    style={{ color: "#212529" }}
                  >
                    Stock & Sales
                    <br /> Visibility
                  </h5>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div class="card">
                <div class="card-block block-14">
                  <h5
                    className={StyleEmail.card_title}
                    style={{ color: "#212529" }}
                  >
                    Offline Mode & Multi <br />
                    Brand Invoicing
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        style={{ background: "#002e49", padding: "50px 10px" }}
        class="container"
      >
        <div class="row text-center">
          <div class="col-md-3 col-1"></div>
          <div class="col-md-3 col-5">
            <div class="counter">
              <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                {({ isVisible }) => (
                  <div class="counter">
                    <i class="fa fa-user fa-2x"></i>
                    <br />
                    {isVisible ? (
                      <CountUp
                        style={{
                          fontSize: "1.5em",
                          fontWeight: "bold",
                        }}
                        end={70}
                        duration="4"
                        suffix="+"
                      />
                    ) : null}

                    <p class="count-text ">Clients</p>
                  </div>
                )}
              </VisibilitySensor>
            </div>
          </div>

          <div class="col-md-3 col-5">
            <div class="counter">
              <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                {({ isVisible }) => (
                  <div class="counter">
                    <i class="fa fa-user fa-2x"></i>
                    <br />
                    {isVisible ? (
                      <CountUp
                        style={{
                          fontSize: "1.5em",
                          fontWeight: "bold",
                        }}
                        end={10000}
                        duration="4"
                        suffix="+"
                      />
                    ) : null}

                    <p class="count-text ">Distributors</p>
                  </div>
                )}
              </VisibilitySensor>
            </div>
          </div>
          <div class="col-md-3 col-1"></div>
        </div>
      </div>

      <section style={{ marginTop: "20px", paddingTop: "0" }}>
        <h2
          style={{ marginBottom: "20px" }}
          className={`${StyleEmail.section_title} mb-2 h1`}
        >
          What Our Clients Say About Us
        </h2>
        <div className={StyleEmail.dash}></div>
        <div
          id={StyleEmail.myCarousel}
          className={`${StyleEmail.carousel} ${StyleEmail.slide} ${StyleEmail.testi}`}
          data-ride="carousel"
        >
          <Carousel
            autoPlay
            infiniteLoop={true}
            interval={3000}
            showArrows={false}
            ref={sliderTestimony}
            showIndicators={false}
            showStatus={false}
          >
            <div
              // className={`${StyleEmail.item} ${StyleEmail.caroud}`}
              class="item  active"
            >
              <div
                // className={StyleEmail.img_box}
                className={StyleEmail.img_box}
              >
                <img
                  src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images/doms.png"
                  alt=""
                />
              </div>
              <p className={StyleEmail.testimonial}>
                "..it has helped us on getting daily updated data on
                superstockists to distributor billing, ..done away with manual
                data compiling work at our end and also helped in
                Auto-replenishment of stocks resulting in smooth flow at our
                Superstockist."
              </p>
              <p className={StyleEmail.overview}>
                <b>Krishna Anand</b> Vice-president Sales, DOMS Industries Pvt.
                Ltd.
              </p>
              <div className={StyleEmail.star_rating}>
                <ul class="list-inline">
                  <i class="fa fa-star"></i>

                  <i class="fa fa-star"></i>

                  <i class="fa fa-star"></i>

                  <i class="fa fa-star"></i>

                  <i class="fa fa-star-half-o"></i>
                </ul>
              </div>
            </div>
            <div class="item  active">
              <div className={StyleEmail.img_box}>
                <img
                  src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images/WEIK.png"
                  alt=""
                />
              </div>
              <p className={StyleEmail.testimonial}>
                "..our challenges were to capture channel sales and inventory
                without investing much on the infrastructure ..with the strong
                support of EazyDMS team, together we can plug the gap."
              </p>
              <p className={StyleEmail.overview}>
                <b> Amit Singh</b> Digital Transformation Head, Weikfield
              </p>
              <div className={StyleEmail.star_rating}>
                <ul class="list-inline">
                  <i class="fa fa-star"></i>

                  <i class="fa fa-star"></i>

                  <i class="fa fa-star"></i>

                  <i class="fa fa-star"></i>

                  <i class="fa fa-star-half-o"></i>
                </ul>
              </div>
            </div>
            <div class="item  active">
              <div className={StyleEmail.img_box}>
                <img
                  src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images-webp/MERIONO.webp"
                  alt=""
                />
              </div>
              <p className={StyleEmail.testimonial}>
                "EazyDMS allows hosting Oracle Cloud that provides the security
                as well as reliability to our customers, we are able to do the
                inventory monitoring which helps the company to serve the
                customers more efficiently."
              </p>
              <p className={StyleEmail.overview}>
                <b>Anand Chaudhary</b>Head IT, Merino Industries
              </p>
              <div className={StyleEmail.star_rating}>
                <ul class="list-inline">
                  <i class="fa fa-star"></i>

                  <i class="fa fa-star"></i>

                  <i class="fa fa-star"></i>

                  <i class="fa fa-star"></i>

                  <i class="fa fa-star-half-o"></i>
                </ul>
              </div>
            </div>
            <div class="item  active">
              <div className={StyleEmail.img_box}>
                <img
                  src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images-webp/MERIONO.webp"
                  alt=""
                />
              </div>
              <p className={StyleEmail.testimonial}>
                "EazyDMS allows hosting Oracle Cloud that provides the security
                as well as reliability to our customers, we are able to do the
                inventory monitoring which helps the company to serve the
                customers more efficiently."
              </p>
              <p className={StyleEmail.overview}>
                <b>Anand Chaudhary</b>Head IT, Merino Industries
              </p>
              <div className={StyleEmail.star_rating}>
                <ul class="list-inline">
                  <i class="fa fa-star"></i>

                  <i class="fa fa-star"></i>

                  <i class="fa fa-star"></i>

                  <i class="fa fa-star"></i>

                  <i class="fa fa-star-half-o"></i>
                </ul>
              </div>
            </div>
            <div class="item  active">
              <div className={StyleEmail.img_box}>
                <img
                  src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images/rrcables.jpg"
                  alt=""
                  style={{ height: "30px" }}
                />
              </div>
              <p className={StyleEmail.testimonial}>
                "..we can map all the parameters of a secondary sales
                transaction which has helped us to strategize in a better way in
                the distribution network. It was also easy for the distributors
                as they don't have to spend any extra effort to maintain Tally,
                by syncing it every day."
              </p>
              <p className={StyleEmail.overview}>
                <b>Dipankar Dutta</b>National Sales Head of RR SNM
              </p>
              <div className={StyleEmail.star_rating}>
                <ul class="list-inline">
                  <i class="fa fa-star"></i>

                  <i class="fa fa-star"></i>

                  <i class="fa fa-star"></i>

                  <i class="fa fa-star"></i>

                  <i class="fa fa-star-half-o"></i>
                </ul>
              </div>
            </div>
            <div class="item  active">
              <div className={StyleEmail.img_box}>
                <img
                  src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images-webp/Scheneider_L&T_logo.webp"
                  alt=""
                />
              </div>
              <p className={StyleEmail.testimonial}>
                "today we have a significantly good accurate information about
                our secondary sales. This has also helped us to reduce the cost
                through the savings which we could do by shifting the schemes
                from Primary to Secondary.
              </p>
              <p className={StyleEmail.overview}>
                <b>Sameer Chaudhary</b>Scheneider
              </p>
              <div className={StyleEmail.star_rating}>
                <ul class="list-inline">
                  <i class="fa fa-star"></i>

                  <i class="fa fa-star"></i>

                  <i class="fa fa-star"></i>

                  <i class="fa fa-star"></i>

                  <i class="fa fa-star-half-o"></i>
                </ul>
              </div>
            </div>
            <div class="item  active">
              <div className={StyleEmail.img_box}>
                <img
                  src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images-webp/KENSTAR.webp"
                  alt=""
                />
              </div>
              <p className={StyleEmail.testimonial}>
                "it's really a full Integrated platform which works very easily
                with Tally Busy Accounting systems...they have a lot of new
                features which we'll be a part of in their journey."
              </p>
              <p className={StyleEmail.overview}>
                <b>Pratyush</b>Strategic Team, Kenstar
              </p>
              <div className={StyleEmail.star_rating}>
                <ul class="list-inline">
                  <i class="fa fa-star"></i>

                  <i class="fa fa-star"></i>

                  <i class="fa fa-star"></i>

                  <i class="fa fa-star"></i>

                  <i class="fa fa-star-half-o"></i>
                </ul>
              </div>
            </div>
            <div class="item  active">
              <div className={StyleEmail.img_box}>
                <img
                  src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images/cr.jpg"
                  alt=""
                />
              </div>
              <p className={StyleEmail.testimonial}>
                “Eazy DMS is a robust & effective Distributor Management
                Software that has helped our team in garnering real time sales
                data for timely decision making from our distributors across
                India which was not possible with our inhouse developed DMS.
                Data accuracy has improved tremendously enabling us in better
                decision making."
              </p>
              <p className={StyleEmail.overview}>
                <b>Onkar Burnwal</b>Senior Marketing Manager
              </p>
              <div className={StyleEmail.star_rating}>
                <ul class="list-inline">
                  <i class="fa fa-star"></i>

                  <i class="fa fa-star" aria-hidden="true"></i>

                  <i class="fa fa-star"></i>

                  <i class="fa fa-star"></i>

                  <i class="fa fa-star"></i>
                </ul>
              </div>
            </div>
            <div class="item  active">
              <div className={StyleEmail.img_box}>
                <img
                  src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images/gy.jpg"
                  alt=""
                />
              </div>
              <p className={StyleEmail.testimonial}>
                “One of our major pain areas was collecting secondary sales data
                on-time. After evaluating all the available Distributor
                Management Software's in the market, we narrowed down Eazy DMS
                due to its ability to fetch information from distributor's
                Tally. This allowed us to convince our distributors easily since
                we were not burdening them with any additional work.“
              </p>
              <p className={StyleEmail.overview}>
                <b>Manish Verma</b> Head – Sales Operations
              </p>
              <div className={StyleEmail.star_rating}>
                <ul class="list-inline">
                  <i class="fa fa-star"></i>

                  <i class="fa fa-star"></i>

                  <i class="fa fa-star"></i>

                  <i class="fa fa-star"></i>

                  <i class="fa fa-star-half-o"></i>
                </ul>
              </div>
            </div>
            <div class="item  active">
              <div className={StyleEmail.img_box}>
                <img
                  src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images/shell.jpg"
                  alt=""
                />
              </div>
              <p className={StyleEmail.testimonial}>
                "Eazy DMS simplifies our Distributors Tally data. The Eazy DMS
                portal is quite exhaustive and adaptable to changes. We faced
                quite a few hurdles in the transition but the team's diligence
                saw us successfully through the same. The team has also come a
                long way in improving its customer service operations. "
              </p>
              <p className={StyleEmail.overview}>
                <b>Kanica Rangnekar</b>Digital Data Strategy & Implementation
                Manager
              </p>
              <div className={StyleEmail.star_rating}>
                <ul class="list-inline">
                  <i class="fa fa-star"></i>

                  <i class="fa fa-star"></i>

                  <i class="fa fa-star"></i>

                  <i class="fa fa-star"></i>

                  <i class="fa fa-star-half-o"></i>
                </ul>
              </div>
            </div>
          </Carousel>
          <div className="CustomerSliderButton" style={{ marginTop: "0px" }}>
            <button
              className="btn customerSlidercssforbutton"
              onClick={NextTestimony}
            >
              <FontAwesomeIcon icon={faLongArrowAltLeft} />
            </button>
            <button
              className="btn customerSlidercssforbutton"
              onClick={PrevTestimony}
            >
              <FontAwesomeIcon icon={faLongArrowAltRight} />
            </button>
          </div>
        </div>
      </section>
      <section className={StyleEmail.clients}>
        <h2
          style={{ width: "100%" }}
          className={`${StyleEmail.section_title} h1`}
        >
          Trusted by the Best
        </h2>
        <div class="container">
          <div class="row">
            <div class="col-md-2 col-6 plaptop">
              <img
                src="https://eazysites.s3.amazonaws.com/eazydms/emaildms/images/10.jpg"
                class="client-logo"
              />
            </div>
            <div class="col-md-2 col-6">
              <img
                src="https://eazysites.s3.amazonaws.com/eazydms/emaildms/images/1.jpg"
                class="client-logo"
              />
            </div>
            <div class="col-md-2 col-6">
              <img
                src="https://eazysites.s3.amazonaws.com/eazydms/emaildms/images/2.jpg"
                class="client-logo"
              />
            </div>
            <div class="col-md-2 col-6">
              <img
                src="https://eazysites.s3.amazonaws.com/eazydms/emaildms/images/3-3.jpg"
                class="client-logo"
              />
            </div>
            <div class="col-md-2 col-6">
              <img
                src="https://eazysites.s3.amazonaws.com/eazydms/emaildms/images/3.jpg"
                class="client-logo"
              />
            </div>
            <div class="col-md-2 col-6">
              <img
                src="https://eazysites.s3.amazonaws.com/eazydms/emaildms/images/11.jpg"
                class="client-logo"
              />
            </div>
          </div>

          <div style={{ marginTop: "20px" }} class="row">
            <div class="col-md-2 col-6 plaptop">
              <img
                src="https://eazysites.s3.amazonaws.com/eazydms/emaildms/images/dorset.jpg"
                class="client-logo"
              />
            </div>
            <div class="col-md-2 col-6">
              <img
                src="https://eazysites.s3.amazonaws.com/eazydms/emaildms/images/5.jpg"
                class="client-logo"
              />
            </div>
            <div class="col-md-2 col-6">
              <img
                src="https://eazysites.s3.amazonaws.com/eazydms/emaildms/images/6.jpg"
                class="client-logo"
              />
            </div>
            <div class="col-md-2 col-6">
              <img
                src="https://eazysites.s3.amazonaws.com/eazydms/emaildms/images/8.jpg"
                class="client-logo"
              />
            </div>
            <div class="col-md-2 col-6">
              <img
                src="https://eazysites.s3.amazonaws.com/eazydms/emaildms/images/9.jpg"
                class="client-logo"
              />
            </div>
            <div class="col-md-2 col-6 plaptop">
              <img
                src="https://eazysites.s3.amazonaws.com/eazydms/emaildms/images/5-5.jpg"
                class="client-logo"
              />
            </div>
          </div>
        </div>
      </section>
      <section id={StyleEmail.awards}>
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-xs-12">
              <h2>Awards & Recognition</h2>
            </div>
            <div class="col-md-3 col-xs-6 col-sm-6">
              <img
                src="https://eazysites.s3.amazonaws.com/eazydms/emaildms/images/aw1.png"
                class="image-responsive"
              />
            </div>
            <div class="col-md-3 col-xs-6 col-sm-6">
              <img
                src="https://eazysites.s3.amazonaws.com/eazydms/emaildms/images/aw2.png"
                class="image-responsive"
              />
            </div>
            <div class="col-md-3 col-xs-6 col-sm-6">
              <img
                src="https://eazysites.s3.amazonaws.com/eazydms/emaildms/images/aw3.png"
                class="image-responsive"
              />
            </div>
            <div class="col-md-3 col-xs-6 col-sm-6">
              <img
                src="https://eazysites.s3.amazonaws.com/eazydms/emaildms/images/aw4.png"
                class="image-responsive"
              />
            </div>
          </div>
        </div>
      </section>
      <div style={{ marginTop: "40px", paddingTop: "10px" }} class="container">
        <h2 className={`${StyleEmail.section_title} mb-2 h1 text-center`}>
          Our Presence
        </h2>
        <div className={StyleEmail.dash}></div>
      </div>
      <div
        style={{ padding: "10px 2%", marginBottom: "20px" }}
        class="fluid-container plaptop"
      >
        <div class="row">
          <div class="col ">
            <img
              style={{ width: "100%" }}
              src="https://eazysites.s3.amazonaws.com/eazydms/emaildms/images/ahmedabad-1.jpg"
              class="align-middle img-responsive"
            />
          </div>
          <div class="col ">
            <img
              style={{ width: "100%" }}
              src="https://eazysites.s3.amazonaws.com/eazydms/emaildms/images/begaluru-1.jpg"
              class="align-middle img-responsive"
            />
          </div>
          <div class="col ">
            <img
              style={{ width: "100%" }}
              src="https://eazysites.s3.amazonaws.com/eazydms/emaildms/images/gurgaon-1.jpg"
              class="align-middle img-responsive"
            />
          </div>
          <div class="col ">
            <img
              style={{ width: "100%" }}
              src="https://eazysites.s3.amazonaws.com/eazydms/emaildms/images/kolkata-1.jpg"
              class="align-middle img-responsive"
            />
          </div>
          <div class="col ">
            <img
              style={{ width: "100%" }}
              src="https://eazysites.s3.amazonaws.com/eazydms/emaildms/images/mumbai-1.jpg"
              class="align-middle img-responsive"
            />
          </div>
        </div>
      </div>
      {/* <div style={{ padding: "10px 2%" }} class="fluid-container presence">
        <div class="row">
          <div class="col-xs-12 col1 ">
            <img
              style={{ width: "100%" }}
              src="https://eazysites.s3.amazonaws.com/eazydms/emaildms/images/ahmedabad-1.jpg"
              class="align-middle img-responsive"
            />
          </div>
          <div class="col-xs-12 col1 ">
            <img
              style={{ width: "100%" }}
              src="https://eazysites.s3.amazonaws.com/eazydms/emaildms/images/begaluru-1.jpg"
              class="align-middle img-responsive"
            />
          </div>
          <div class="col-xs-12 col1 ">
            <img
              style={{ width: "100%" }}
              src="https://eazysites.s3.amazonaws.com/eazydms/emaildms/images/gurgaon-1.jpg"
              class="align-middle img-responsive"
            />
          </div>
          <div class="col-xs-12 col1 ">
            <img
              style={{ width: "100%" }}
              src="https://eazysites.s3.amazonaws.com/eazydms/emaildms/images/kolkata-1.jpg"
              class="align-middle img-responsive"
            />
          </div>
          <div class="col-xs-12 col1 ">
            <img
              style={{ width: "100%" }}
              src="https://eazysites.s3.amazonaws.com/eazydms/emaildms/images/mumbai-1.jpg"
              class="align-middle img-responsive"
            />
          </div>
        </div>
      </div> */}
      <footer>
        <div class="container-fluid py-3">
          <div class="container">
            <div class="row">
              <div class="col-md-8">
                <div class="row py-0">
                  <div class="col-sm-1 hidden-md-down">
                    {/* <div class="bg-circle bg-info"> */}
                    <i
                      class="fa fa-2x fa-fw fa-address-card"
                      aria-hidden="true "
                      style={{ color: "#4ad1e5" }}
                    ></i>
                    {/* </div> */}
                  </div>
                  <div class="col-sm-11 text-white">
                    <div style={{ color: "#fff" }}>
                      <h4>Contact</h4>
                      <p style={{ color: "#fff" }}>
                        +91 8130285554 | contact@eazydms.com
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 text-right copy">
                <div class="d-inline-block social">
                  <div
                    class="bg-circle-outline icon d-inline-block"
                    style={{ background: "#3b5998", marginRight: "10px" }}
                  >
                    <a
                      target="_blank"
                      href="https://www.facebook.com/eazyerp/?ref=settings"
                    >
                      <span className={StyleEmail.sociallandingbIcon}>
                        <FontAwesomeIcon
                          icon={faFacebook}
                          style={{ fontSize: "22px" }}
                        />
                      </span>

                      {/* <i class="fab fa-fw fa-facebook text-white"></i> */}
                    </a>
                  </div>
                  <div
                    class="bg-circle-outline icon d-inline-block"
                    style={{ background: "#3b5998", marginRight: "10px" }}
                  >
                    <a target="_blank" href="https://twitter.com/EazyERP">
                      <span className={StyleEmail.sociallandingbIcon}>
                        <FontAwesomeIcon
                          icon={faTwitter}
                          style={{ fontSize: "22px" }}
                        />
                      </span>
                      {/* <i class="fab fa-fw fa-twitter text-white"></i> */}
                    </a>
                  </div>

                  <div
                    class="bg-circle-outline icon d-inline-block"
                    style={{ background: "#3b5998", marginRight: "10px" }}
                  >
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/singhal-system-solutions-pvt-ltd---offical-page/"
                    >
                      <span className={StyleEmail.sociallandingbIcon}>
                        <FontAwesomeIcon
                          icon={faLinkedin}
                          style={{ fontSize: "22px" }}
                        />
                      </span>
                      {/* <i class="fab fa-fw fa-linkedin text-white"></i> */}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <p class="text-center m-1 copy" style={{ color: "black" }}>
        ©2019 EAZY Business Solutions. All right reserved.{" "}
      </p>
      <ResponsePopUp
              open={responseDetails.isResponseOpen}
              onClose={handleResponseClose}
              maxWidth={"xs"}
              content={responseDetails.responseContent}
              type={responseDetails.responseType}
              
            />
    </>
  );
};

export default EmailDmsPage;
