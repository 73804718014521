import React, { useState } from "react";
import { ContactFooter } from "./ContactFooter";
import ServerAPI from "../../ServerApi";
import map from "../../img/background/map1.png"
import { ResponsePopUp } from "../Alert";
import Loader from "../Loader";

export const ContactForm = () => {
  const [isLoading,setLoading]=useState(false)
  const [formDetails, setFormDetails] = useState({
    name: "",
    email: "",
    mobile: "",
    companyName: "",
    Country: "102",
    state: "",
    message: "",
  });
  const [responseDetails,setResponseDetails]= useState({
    isResponseOpen:false,
    responseContent:"",
    responseType:""
  })

  const [errorDetails, setErrorsDetails] = useState({
    name: "",
    email: "",
    mobile: "",
    companyName: "",
    state: "",
    message: "",
  });

  const handleResponseClose =()=>{
    setResponseDetails((prev)=>({...prev,isResponseOpen:false}))
  }

  const handleChange = (e) => {
    if (e.target) {
      let name = e.target.name;
      let value = e.target.value;
      setFormDetails((prev) => ({ ...prev, [name]: value }));
      setErrorsDetails((prev) => ({
        ...prev,
        [name]: value ? "" : "This Field Can't be Blank",
      }));
    } else if (e.element) {
      let name = e.element.id;
      let value = e.value;
      setFormDetails(() => ({ ...formDetails, [name]: value }));
    }
  };
  const handleKeyPress = (e) => {
    const value = e.key;
    const isNumber = /^[0-9]$/.test(value);
    if (!isNumber) {
      e.preventDefault();
    }
  };
  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    if (formDetails["name"] == "") {
      formIsValid = false;
      setErrorsDetails((prev) => ({
        ...prev,
        ["name"]: "This Field Can't be Blank",
      }));
    }
    if (formDetails["message"] == "") {
      formIsValid = false;
      setErrorsDetails((prev) => ({
        ...prev,
        ["message"]: "This Field Can't be Blank",
      }));
    }
    if (formDetails["email"] == "") {
      formIsValid = false;
      setErrorsDetails((prev) => ({
        ...prev,
        ["email"]: "This Field Can't be Blank",
      }));
    } else if (formDetails["email"] != "") {
      const isEmail =
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/.test(
          formDetails.email
        );
      const isValidCompanyEmail = /^[a-zA-Z0-9_.+-]+@gmail\.com$/.test(
        formDetails.email
      );
      if (!isEmail) {
        formIsValid = false;
        setErrorsDetails((prev) => ({
          ...prev,
          ["email"]: "Enter a valid email Address",
        }));
      }
      if (isValidCompanyEmail) {
        formIsValid = false;
        setErrorsDetails((prev) => ({
          ...prev,
          ["email"]: "Enter a valid Company Email Address",
        }));
      }
    }

    if (formDetails["companyName"] == "") {
      formIsValid = false;
      setErrorsDetails((prev) => ({
        ...prev,
        ["companyName"]: "This Field Can't be Blank",
      }));
    }
    if (formDetails["mobile"] == "") {
      formIsValid = false;
      setErrorsDetails((prev) => ({
        ...prev,
        ["mobile"]: "This Field Can't be Blank",
      }));
    }
    if (formDetails["state"] == "") {
      formIsValid = false;
      setErrorsDetails((prev) => ({
        ...prev,
        ["state"]: "This Field Can't be Blank",
      }));
    }

    return formIsValid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      setLoading(true)
      const payload = {
        contactDetail: {
          source: "EazyDMS",
          pageName: "Contact Us",
          company_name: formDetails.companyName,
          name: formDetails.name,
          email: formDetails.email,
          mobile: formDetails.mobile,
          state: formDetails.state,
          country: "India",
          message: formDetails.message,
          sendEmail: "true",
        },
      };
      fetch(ServerAPI, {
        method: "post",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status == true || res.status == "true") {
            setResponseDetails({
              isResponseOpen:true,
              responseContent:res.result,
              responseType:"success"
            })
            setFormDetails({
              name: "",
              email: "",
              mobile: "",
              companyName: "",
              Country: "102",
              state: "",
              message: "",
            });
            setErrorsDetails({
              name: "",
              email: "",
              mobile: "",
              companyName: "",
              state: "",
              message: "",
            });
          } else {
            setResponseDetails({
              isResponseOpen:true,
              responseContent:res.error,
              responseType:"error"
            })
            setFormDetails({
              name: "",
              email: "",
              mobile: "",
              companyName: "",
              Country: "102",
              state: "",
              message: "",
            });
          }
          setLoading(false)
        })
        .catch((err) =>
        {setResponseDetails({
          isResponseOpen:true,
          responseContent:"Something Went Wrong,Try Again",
          responseType:"error"
        })
        setLoading(false)
          console.log(err, "Error While trying to send request demo data")}
        );
    } 
  };
  return (
    <>
     {isLoading && <Loader/>}
      <div
        class="contact-page area-padding"
        style={{ backgroundColor: "#eef1f3" }}
      >
        <div class="container">
          <div class="row">
            <div class="col-md-5 col-sm-5 col-xs-12">
              <p>
                Reach out to us with your queries and concerns and we will be
                there for you in a click!
              </p>
              <div class="contact-form" id="contactForm">
                <div class="row">
                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      class="form-control"
                      placeholder="Name*"
                      value={formDetails.name}
                      onChange={handleChange}
                      style={{
                        marginBottom: errorDetails.name == "" ? "" : "0px",
                      }}
                    />
                    {errorDetails.name != "" ? (
                      <span style={{ color: "red" }}>
                        This Field Can't be Blank
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <input
                      type="text"
                      id="mobile"
                      name="mobile"
                      onKeyPress={handleKeyPress}
                      class="form-control"
                      placeholder="Mobile*"
                      maxlength="15"
                      value={formDetails.mobile}
                      onChange={handleChange}
                      style={{
                        marginBottom: errorDetails.mobile == "" ? "" : "0px",
                      }}
                    />
                    {errorDetails.mobile != "" ? (
                      <span style={{ color: "red" }}>
                        This Field Can't be Blank
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <input
                      type="email"
                      class="email form-control"
                      id="email"
                      name="email"
                      placeholder="Email*"
                      value={formDetails.email}
                      onChange={handleChange}
                      // onKeyPress={handleEmailCheck}
                      style={{
                        marginBottom: errorDetails.email == "" ? "" : "0px",
                      }}
                    />
                    {errorDetails.email != "" ? (
                      <span style={{ color: "red" }}>{errorDetails.email}</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    class="col-md-12 col-sm-12 col-xs-12"
                    id="error_email"
                    style={{
                      display: "block",
                      paddingLeft: "18px",
                      color: "red",
                    }}
                  ></div>

                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <input
                      type="text"
                      class="form-control"
                      id="companyName"
                      name="companyName"
                      placeholder="Company Name*"
                      value={formDetails.companyName}
                      onChange={handleChange}
                      style={{
                        marginBottom:
                          errorDetails.companyName == "" ? "" : "0px",
                      }}
                    />
                    {errorDetails.companyName != "" ? (
                      <span style={{ color: "red" }}>
                        This Field Can't be Blank
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <select class="form-control" name="country" id="country">
                      <option value="102">India</option>
                    </select>
                  </div>
                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <select
                      class="form-control"
                      name="state"
                      id="state"
                      onChange={handleChange}
                      style={{ color: "#999" }}
                      value={formDetails.state}
                    >
                      <option value="" selected="selected">
                        State*
                      </option>
                      <option value="Andhra Pradesh">Andhra Pradesh</option>
                      <option value="Arunachal Pradesh">
                        Arunachal Pradesh
                      </option>
                      <option value="Andaman and Nicobar Island">
                        Andaman and Nicobar Island
                      </option>
                      <option value="Assam">Assam</option>
                      <option value="Bihar">Bihar</option>
                      <option value="Chandigarh">Chandigarh</option>
                      <option value="Chhattisgarh">Chhattisgarh</option>
                      <option value="Dadra and Nagar Haveli and Daman and Diu">
                        Dadra and Nagar Haveli and Daman and Diu
                      </option>
                      <option value="Delhi">Delhi</option>
                      <option value="Goa">Goa</option>
                      <option value="Gujarat">Gujarat</option>
                      <option value="Haryana">Haryana</option>
                      <option value="Himachal Pradesh">Himachal Pradesh</option>
                      <option value="Jharkhand">Jharkhand</option>
                      <option value="Jammu and Kashmir">
                        Jammu and Kashmir
                      </option>
                      <option value="Karnataka">Karnataka</option>
                      <option value="Kerala">Kerala</option>
                      <option value="Ladakh">Ladakh</option>
                      <option value="Lakshadweep">Lakshadweep</option>
                      <option value="Madhya Pradesh">Madhya Pradesh</option>
                      <option value="Maharashtra">Maharashtra</option>
                      <option value="Manipur">Manipur</option>
                      <option value="Meghalaya">Meghalaya</option>
                      <option value="Mizoram">Mizoram</option>
                      <option value="Nagaland">Nagaland</option>
                      <option value="Odisha">Odisha</option>
                      <option value="Punjab">Punjab</option>
                      <option value="Puducherry">Puducherry</option>
                      <option value="Rajasthan">Rajasthan</option>
                      <option value="Sikkim">Sikkim</option>
                      <option value="Tamil Nadu">Tamil Nadu</option>
                      <option value="Telangana">Telangana</option>
                      <option value="Tripura">Tripura</option>
                      <option value="Uttar Pradesh">Uttar Pradesh</option>
                      <option value="Uttarakhand">Uttarakhand</option>
                      <option value="West Bengal">West Bengal</option>
                    </select>
                    {errorDetails.state != "" ? (
                      <span style={{ color: "red" }}>
                        This Field Can't be Blank
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <textarea
                      id="contact_message"
                      name="message"
                      onChange={handleChange}
                      rows="3"
                      placeholder="Message*"
                      class="form-control"
                      value={formDetails.message}
                    ></textarea>
                    {errorDetails.message != "" ? (
                      <span style={{ color: "red" }}>
                        This Field Can't be Blank
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <input
                      type="checkbox"
                      id="subscribe_news"
                      name="subscribe_news"
                      // checked={false}
                    />
                    <label
                      style={{
                        fontSize: "14px",
                        padding: "6px 12px",
                        color: "#555",
                        fontWeight: "300",
                      }}
                      for="subscribe_news"
                    >
                      I want to subscribe to the newsletter.{" "}
                    </label>
                  </div>
                  <div class="break-p"></div>
                  <div class="col-md-12 col-sm-12">
                    <div
                      class="g-recaptcha"
                      data-sitekey="6Le_aW8UAAAAAMWrCedYvbz-yDuEpSOnzMQJu6q9"
                      id="contactCaptcha"
                    ></div>
                    <input
                      type="hidden"
                      class="hiddenRecaptcha"
                      name="contactCaptcha_input"
                      id="contactCaptcha_input"
                    />
                  </div>
                  <div
                    class="col-md-12 col-sm-12 col-xs-12 err"
                    id="contactFormErr"
                    style={{
                      color: "red",
                      fontWeight: "bolder",
                      fontSize: "20px",
                      float: "left",
                      display: "none",
                      textAlign: "center",
                    }}
                  ></div>

                  <div class="clearfix"></div>
                  <div class="col-md-12 col-sm-12 col-xs-12 text-center eazydmscontactsubmitbtn">
                    <button
                      type="submit"
                      id="submit"
                      onClick={handleSubmit}
                      class="contact-btn"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-7 col-sm-7 col-xs-12">
              <img
                src={map}
                alt="Eazy Business Solutions Presence on Map"
              />
            </div>
          </div>
          <ContactFooter />
          <ResponsePopUp
              open={responseDetails.isResponseOpen}
              onClose={handleResponseClose}
              maxWidth={"xs"}
              content={responseDetails.responseContent}
              type={responseDetails.responseType}
            />
        </div>
      </div>
    </>
  );
};
