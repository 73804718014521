import React, { Fragment } from "react";
import { AboutBackGround } from "../Components/About/Background";
import Footer from "../Components/Sections/footer";
import { Topbar } from "../Components/Topbar";
import AfterTopBar from "../Components/AfterTopbar/AfterTopbar";
const AboutPage = () => {
  return (
    <>
      <Fragment>
        <div>
          <div className="main_header">
            <Topbar />
            <AfterTopBar />
          </div>
          <AboutBackGround />
          <Footer />
        </div>
      </Fragment>
    </>
  );
};
export default AboutPage;
