import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { RecentPost } from "../recentPost";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { Topbar } from "../../Topbar";
import AfterTopBar from "../../AfterTopbar/AfterTopbar";
import Footer from "../../Sections/footer";

export const BlogTallyIntoDms = () => {
  return (
    <>
      <div className="main_header">
        <Topbar />
        <AfterTopBar />
      </div>
      <div class="page-area4">
        <div class="breadcumb-overlay"></div>
        <div class="container">
          <div class="row text-center">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="breadcrumb">
                <div class="section-headline white-headline text-center">
                  <h4
                    style={{
                      fontSize: "48px",
                      letterSpacing: "2px",
                      lineHeight: "48px",
                      color: "#fff",
                      display: "inline-block",
                      maxWidth: "100%",
                    }}
                  >
                    Why Tally integrated DMS?
                  </h4>
                </div>
                <ul>
                  <li className="home-bread">
                    Home
                    <span style={{ marginLeft: "7px" }}></span>
                    <FontAwesomeIcon icon={faAngleRight} />
                    <span style={{ marginLeft: "7px" }}></span> Blog
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="blog-page-area area-padding">
        <div class="container">
          <div class="blog-details">
            <div class="row">
              <div class="col-md-8 col-sm-8 col-xs-12">
                <article class="blog-post-wrapper">
                  <div class="blog-banner">
                    <a href="#" class="blog-images">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/blog/blog-tallyint.jpg"
                        alt="Why Tally Integrated DMS blog cover"
                      />
                    </a>
                    <div class="blog-content">
                      <br />
                      <p>
                        Present day markets are huge and multifaceted with
                        thousands of distributors, retailers and billions of
                        potential transactions. How can your company take
                        control? How can you manage your secondary sales and
                        promotions while still cutting costs? <br />
                        With rapid changes in market dynamics, businesses around
                        the world are evolving by every minute. In an
                        increasingly competitive environment, having a radical
                        and methodological system for tracking channel sales is
                        very important in order to achieve increased revenues
                        and faster ROIs. Given this scenario, more & more
                        companies are opting for distributor management software
                        for a complete visibility of their channel sales.
                      </p>
                      <h4 style={{ fontWeight: "600" }}>Typical DMS:</h4>
                      <p>
                        Most prevalent DMS available in the market today are in
                        the form of customized portals or web portals.
                        Distributors have to work on customized web portal for
                        each brand and maintain their data individually.
                        <br />
                        This worked well few years back when one distributor
                        worked for few brands. However, with growing
                        competition, the concept of multi brand distributors has
                        come up. These distributors work with numerous brands at
                        a given time. It becomes cumbersome for distributors to
                        learn how to use different customized portals for the
                        different brands they are managing and hence increasing
                        resistance to deploy a system.
                      </p>

                      <h4 style={{ fontWeight: "600" }}>
                        Pain Areas of a Typical DMS:
                      </h4>
                      <ul class="gun">
                        <li>
                          {" "}
                          <span class="icon1">
                            <FontAwesomeIcon icon={faCircleCheck} />
                          </span>
                          Difficult to maintain separate customized portals for
                          Multiple brands
                        </li>
                        <li>
                          {" "}
                          <span class="icon1">
                            <FontAwesomeIcon icon={faCircleCheck} />
                          </span>
                          Duplication of work for the Distributor
                        </li>
                        <li>
                          {" "}
                          <span class="icon1">
                            <FontAwesomeIcon icon={faCircleCheck} />
                          </span>
                          Fresh training required for Distributor
                        </li>
                        <li>
                          {" "}
                          <span class="icon1">
                            <FontAwesomeIcon icon={faCircleCheck} />
                          </span>
                          Huge Support & license cost
                        </li>
                        <li>
                          {" "}
                          <span class="icon1">
                            <FontAwesomeIcon icon={faCircleCheck} />
                          </span>
                          Increase in Manpower cost
                        </li>
                      </ul>
                      <p>
                        Brands need an easy way to collate information from its
                        distributors that doesn’t interfere with their normal
                        business process and can be integrated seamlessly.{" "}
                      </p>
                      <h4 style={{ fontWeight: "600" }}>
                        Tally Integrated DMS
                      </h4>

                      <p>
                        Indian Distributors run their business on TALLY. The
                        entire Chartered Accountant lobby vouches for the
                        benefits and usage of TALLY to manage the Indian
                        accounting books. Distributor’s network across the
                        country is either versed with this legacy accounting
                        software or takes the help of a CA to manage its books
                        that eventually function on TALLY. Therefore, TALLY is
                        the most instrumental equipment to do business in India.{" "}
                      </p>
                      <p>
                        We have seen many implementation hazards of Fortune
                        companies when it comes to India. Many stalwarts have
                        succumbed to the operational inefficiencies and
                        environment provided to implement a DMS (Distribution
                        Management Software) successfully in India. One of the
                        major fallback is infrastructure. This is where a Tally
                        Integrated DMS can help overcome these challenges.
                      </p>
                      <p>
                        It works seamlessly with distributor’s TALLY. Making
                        their life easy by reducing the pain of duplication,
                        multiple entries in different systems, data mismatch,
                        Item alteration, Price sanctity and lot more just by
                        working on their existing TALLY system. The Manufacturer
                        can pull the relevant information on the secondary sales
                        data from the distributors Tally and get the information
                        real time.
                      </p>
                      <p>
                        A Tally integrated DMS like EAZY DMS is not just pulling
                        the data; it is also capable of pushing the masters,
                        prices and schemes that eventually brings a lot of grip
                        and control to the principle. The master control enables
                        the principle to have a complete control over
                        distributor’s MRP, Item Alteration, Ledger Alteration,
                        Back date entries, Negative Stock billings and Reorder
                        levels. The PO generated by Distributor’s Tally syncs
                        automatically thus automating the Primary Sales as well.
                      </p>
                      <h4 style={{ fontWeight: "600" }}>
                        Benefits of using a Tally integrated DMS
                      </h4>
                      <div class="row">
                        <ul class="gun">
                          <li>
                            {" "}
                            <span class="icon1">
                              <FontAwesomeIcon icon={faCircleCheck} />
                            </span>
                            Centralization of Data
                          </li>
                          <li>
                            {" "}
                            <span class="icon1">
                              <FontAwesomeIcon icon={faCircleCheck} />
                            </span>
                            Data can be queued to sync even in offline mode
                          </li>
                          <li>
                            {" "}
                            <span class="icon1">
                              <FontAwesomeIcon icon={faCircleCheck} />
                            </span>
                            Import & export data from distributors seamlessly
                          </li>
                          <li>
                            {" "}
                            <span class="icon1">
                              <FontAwesomeIcon icon={faCircleCheck} />
                            </span>
                            Record information at minute level
                          </li>
                          <li>
                            {" "}
                            <span class="icon1">
                              <FontAwesomeIcon icon={faCircleCheck} />
                            </span>
                            SKU Mapping
                          </li>
                          <li>
                            {" "}
                            <span class="icon1">
                              <FontAwesomeIcon icon={faCircleCheck} />
                            </span>
                            No Duplicate efforts
                          </li>
                          <li>
                            {" "}
                            <span class="icon1">
                              <FontAwesomeIcon icon={faCircleCheck} />
                            </span>
                            Minimal Change Management
                          </li>
                          <li>
                            {" "}
                            <span class="icon1">
                              <FontAwesomeIcon icon={faCircleCheck} />
                            </span>
                            Less resistance from Distributors
                          </li>
                        </ul>
                      </div>
                      <br />

                      <p>
                        A Tally integrated DMS, bridges the information gap that
                        manufacturer’s having a distribution network normally
                        face, without having to deploy any new system at the
                        distributor’s end. It gives them real time access to the
                        secondary sales data & inventory and enables better
                        decision making. Therefore, there is no need to
                        regularly follow up with your distributors for reports
                        and information.
                      </p>
                    </div>
                  </div>
                </article>
                <div class="clear"></div>
                <div class="single-post-comments"></div>
              </div>
              <div class="col-md-4">
                <RecentPost />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
