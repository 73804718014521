import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faEnvelope,
  faMapMarkerAlt,
  faPhoneAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import ParticleMain from "../Sections/pariclesSections";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
// import "./landingb.css";
import {
  faFacebook,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import Stylesland from "./landingb.module.css";
import ServerAPI from "../../ServerApi";
import { ResponsePopUp } from "../Alert";
import Loader from "../Loader";

const LandingBPage = () => {
  let sliderTestimony = useRef();
  const [isLoading,setLoading]=useState(false)
  const [formDetails, setFormDetails] = useState({
    fullname: "",
    company: "",
    mobile: "",
    email: "",
    state: "",
  });

  const [errorDetails, setErrorsDetails] = useState({
    fullname: "",
    company: "",
    mobile: "",
    email: "",
    state: "",
  });
  const [responseDetails,setResponseDetails]= useState({
    isResponseOpen:false,
    responseContent:"",
    responseType:""
  })
  const handleResponseClose =()=>{
    setResponseDetails((prev)=>({...prev,isResponseOpen:false}))
  }
  const handleChange = (e) => {
    if (e.target) {
      let name = e.target.name;
      let value = e.target.value;
      setFormDetails((prev) => ({ ...prev, [name]: value }));
      setErrorsDetails((prev) => ({
        ...prev,
        [name]: value ? "" : "This Field Can't be Blank",
      }));
    } else if (e.element) {
      let name = e.element.id;
      let value = e.value;
      setFormDetails(() => ({ ...formDetails, [name]: value }));
    }
  };
  const PrevTestimony = () => {
    sliderTestimony.current.onClickNext();
  };
  const NextTestimony = () => {
    sliderTestimony.current.onClickPrev();
  };
  const handleKeyPress = (e) => {
    const value = e.key;
    const isNumber = /^[0-9]$/.test(value);
    if (!isNumber) {
      e.preventDefault();
    }
  };
  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    if (formDetails["fullname"] == "") {
      formIsValid = false;
      setErrorsDetails((prev) => ({
        ...prev,
        ["fullname"]: "This Field Can't be Blank",
      }));
    }
    if (formDetails["email"] == "") {
      formIsValid = false;
      setErrorsDetails((prev) => ({
        ...prev,
        ["email"]: "This Field Can't be Blank",
      }));
    } else if (formDetails["email"] != "") {
      const isEmail =
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/.test(
          formDetails.email
        );
      const isValidCompanyEmail = /^[a-zA-Z0-9_.+-]+@gmail\.com$/.test(
        formDetails.email
      );
      if (!isEmail) {
        formIsValid = false;
        setErrorsDetails((prev) => ({
          ...prev,
          ["email"]: "Enter a valid email Address",
        }));
      }
      if (isValidCompanyEmail) {
        formIsValid = false;
        setErrorsDetails((prev) => ({
          ...prev,
          ["email"]: "Enter a valid Company Email Address",
        }));
      }
    }

    if (formDetails["company"] == "") {
      formIsValid = false;
      setErrorsDetails((prev) => ({
        ...prev,
        ["company"]: "This Field Can't be Blank",
      }));
    }
    if (formDetails["mobile"] == "") {
      formIsValid = false;
      setErrorsDetails((prev) => ({
        ...prev,
        ["mobile"]: "This Field Can't be Blank",
      }));
    }
    if (formDetails["state"] == "") {
      formIsValid = false;
      setErrorsDetails((prev) => ({
        ...prev,
        ["state"]: "This Field Can't be Blank",
      }));
    }

    return formIsValid;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true)
      const payload = {
        contactDetail: {
          source: "EazyDMS",
          pageName: "landingb",
          company_name: formDetails.companyName,
          name: formDetails.fullname,
          email: formDetails.email,
          mobile: formDetails.mobile,
          state: formDetails.state,
          sendEmail: "true",
        },
      };
      fetch(ServerAPI, {
        method: "post",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status == true || res.status == "true") {
            setResponseDetails({
              isResponseOpen:true,
              responseContent:res.result,
              responseType:"success"
            })
            setFormDetails({
              fullname: "",
              company: "",
              mobile: "",
              email: "",
              state: "",
            });
          } else {
            setResponseDetails({
              isResponseOpen:true,
              responseContent:res.error,
              responseType:"error"
            })
            setFormDetails({
              fullname: "",
              company: "",
              mobile: "",
              email: "",
              state: "",
            });
          }
          setLoading(false)
        })
        .catch((err) =>
        {setResponseDetails({
          isResponseOpen:true,
          responseContent:"Something Went Wrong",
          responseType:"error"
        })
        setLoading(false)
          console.log(err, "Error While trying to send request demo data")}
        );
    }
  };

  return (
    <>
      {isLoading && <Loader/>}
      <div className="landing__page__wrapper">
        <section
          // class="banner"
          className={Stylesland.banner}
        >
          <div class="container" style={{ padding: "2rem" }}>
            <div class="row justify-content-center">
              <div
                //   style="margin:0 auto"
                style={{ margin: "0 auto" }}
                class="col-md-8 col-sm-10 col-xs-12 text-center"
              >
                <img
                  // style="margin-bottom:20px;"
                  style={{ marginBottom: "20px" }}
                  src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images/e-logo.webp"
                  class="logo mx-auto"
                />

                <h2
                  // class="m-2 "
                  style={{ color: "#fff" }}
                  className={`m-2 ${Stylesland.adj_font}`}
                >
                  Distributor Management Software for Tracking FMCG/FMCD
                  Secondary Sales
                </h2>
              </div>
            </div>
            <div
              style={{ marginTop: "20px" }}
              //   style="margin-top:20px;"
              class="row"
            >
              <div
                // class="col-xl-8 col-lg-8 col-md-6 col-sm-12 text-center set_height_iframe"
                className={`col-xl-8 col-lg-8 col-md-6 col-sm-12 text-center ${Stylesland.set_height_iframe}`}
                // style={{ height: "400px !important" }}
              >
                <iframe
                  style={{ margin: "0 auto", marginTop: "15px" }}
                  // style="margin: 0 auto;margin-top:15px"
                  width="100%"
                  src="https://www.youtube.com/embed/fz3Q8pi5azo"
                  frameborder="0"
                  allow="autoplay; encrypted-media"
                  allowfullscreen
                ></iframe>
              </div>

              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <form
                  style={{ marginTop: "15px" }}
                  id={Stylesland.contactform}
                  name="contactform"
                  // class="top"
                  className={Stylesland.top}
                  onSubmit={handleSubmit}
                >
                  <div
                    // class="card border-primary rounded-0"
                    className={`${Stylesland.card}  ${Stylesland.rounded_0}`}
                  >
                    <div
                      className={`${Stylesland.card_header} p-0`}
                      // class="card-header p-0"
                    >
                      <div class=" text-white text-center py-2">
                        <h5>
                          <span className="landingBenevolpeIcon">
                            <FontAwesomeIcon
                              icon={faEnvelope}
                              style={{ marginRight: "10px" }}
                            />
                          </span>
                          Request A Demo
                        </h5>
                        <p class="m-0"></p>
                      </div>
                    </div>
                    <div
                      class="card-body p-3"
                      // className={`$`}
                    >
                      <div
                        // class="form-group"
                        className={Stylesland.form_group}
                      >
                        <div class="input-group mb-2">
                          <div
                            // class="input-group-prepend"
                            className={Stylesland.input_group_prepand}
                          >
                            <div
                              class="input-group-text"
                              style={{
                                borderBottomRightRadius: "0px",
                                borderTopRightRadius: "0px",
                              }}
                            >
                              <span
                                className="landingFormIcon"
                                //
                              >
                                <FontAwesomeIcon icon={faUser} />
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              flex: "1",
                            }}
                          >
                            <input
                              type="text"
                              class="form-control"
                              id="fullname"
                              name="fullname"
                              value={formDetails.fullname}
                              onChange={handleChange}
                              placeholder="Full Name"
                              style={{
                                borderBottomLeftRadius: "0px",
                                borderTopLeftRadius: "0px",
                              }}
                            />
                            {errorDetails.fullname != "" ? (
                              <span style={{ color: "red" }}>
                                This Field Can't be Blank
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="row err" id="error_name"></div>

                      <div class="form-group">
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <div
                              class="input-group-text"
                              style={{
                                borderBottomRightRadius: "0px",
                                borderTopRightRadius: "0px",
                              }}
                            >
                              <span className="landingFormIcon">
                                <FontAwesomeIcon icon={faBuilding} />
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              flex: "1",
                            }}
                          >
                            <input
                              type="text"
                              class="form-control"
                              id="company"
                              name="company"
                              placeholder="Company"
                              value={formDetails.company}
                              onChange={handleChange}
                              style={{
                                borderBottomLeftRadius: "0px",
                                borderTopLeftRadius: "0px",
                              }}
                            />
                            {errorDetails.company != "" ? (
                              <span style={{ color: "red" }}>
                                This Field Can't be Blank
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="row err" id="error_company_name"></div>

                      <div class="form-group">
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <div
                              class="input-group-text"
                              style={{
                                borderBottomRightRadius: "0px",
                                borderTopRightRadius: "0px",
                              }}
                            >
                              <span className="landingFormIcon">
                                <FontAwesomeIcon icon={faPhoneAlt} />
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              flex: "1",
                            }}
                          >
                            <input
                              type="text"
                              class="form-control"
                              id="mobile"
                              name="mobile"
                              value={formDetails.mobile}
                              onChange={handleChange}
                              onKeyPress={handleKeyPress}
                              maxlength="15"
                              placeholder="Phone Number"
                              style={{
                                borderBottomLeftRadius: "0px",
                                borderTopLeftRadius: "0px",
                              }}
                            />
                            {errorDetails.mobile != "" ? (
                              <span style={{ color: "red" }}>
                                This Field Can't be Blank
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="row err" id="error_phone"></div>
                      <div class="form-group">
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <div
                              class="input-group-text"
                              style={{
                                borderBottomRightRadius: "0px",
                                borderTopRightRadius: "0px",
                              }}
                            >
                              <span className="landingFormIcon">
                                <FontAwesomeIcon icon={faEnvelope} />
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              flex: "1",
                            }}
                          >
                            <input
                              type="email"
                              class="form-control"
                              id="email"
                              name="email"
                              value={formDetails.email}
                              onChange={handleChange}
                              placeholder="Email"
                              style={{
                                borderBottomLeftRadius: "0px",
                                borderTopLeftRadius: "0px",
                              }}
                            />
                            {errorDetails.email != "" ? (
                              <span style={{ color: "red" }}>
                                {errorDetails.email}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="row err" id="error_email"></div>
                      <div class="form-group">
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <div
                              class="input-group-text"
                              style={{
                                borderBottomRightRadius: "0px",
                                borderTopRightRadius: "0px",
                              }}
                            >
                              <span className="landingFormIcon">
                                <FontAwesomeIcon icon={faMapMarkerAlt} />
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              flex: "1",
                            }}
                          >
                            <select
                              class="form-control"
                              id="state"
                              name="state"
                              onChange={handleChange}
                              style={{
                                borderBottomLeftRadius: "0px",
                                borderTopLeftRadius: "0px",
                              }}
                              value={formDetails.state}
                            >
                              <option
                                value=""
                                label="Select a State"
                                selected="selected"
                              >
                                Select a State
                              </option>
                              <option value="Andhra Pradesh">
                                Andhra Pradesh
                              </option>
                              <option value="Arunachal Pradesh">
                                Arunachal Pradesh
                              </option>
                              <option value="Andaman and Nicobar Island">
                                Andaman and Nicobar Island
                              </option>
                              <option value="Assam">Assam</option>
                              <option value="Bihar">Bihar</option>
                              <option value="Chandigarh">Chandigarh</option>
                              <option value="Chhattisgarh">Chhattisgarh</option>
                              <option value="Dadra and Nagar Haveli and Daman and Diu">
                                Dadra and Nagar Haveli and Daman and Diu
                              </option>
                              <option value="Delhi">Delhi</option>
                              <option value="Goa">Goa</option>
                              <option value="Gujarat">Gujarat</option>
                              <option value="Haryana">Haryana</option>
                              <option value="Himachal Pradesh">
                                Himachal Pradesh
                              </option>
                              <option value="Jharkhand">Jharkhand</option>
                              <option value="Jammu and Kashmir">
                                Jammu and Kashmir
                              </option>
                              <option value="Karnataka">Karnataka</option>
                              <option value="Kerala">Kerala</option>
                              <option value="Ladakh">Ladakh</option>
                              <option value="Lakshadweep">Lakshadweep</option>
                              <option value="Madhya Pradesh">
                                Madhya Pradesh
                              </option>
                              <option value="Maharashtra">Maharashtra</option>
                              <option value="Manipur">Manipur</option>
                              <option value="Meghalaya">Meghalaya</option>
                              <option value="Mizoram">Mizoram</option>
                              <option value="Nagaland">Nagaland</option>
                              <option value="Odisha">Odisha</option>
                              <option value="Punjab">Punjab</option>
                              <option value="Puducherry">Puducherry</option>
                              <option value="Rajasthan">Rajasthan</option>
                              <option value="Sikkim">Sikkim</option>
                              <option value="Tamil Nadu">Tamil Nadu</option>
                              <option value="Telangana">Telangana</option>
                              <option value="Tripura">Tripura</option>
                              <option value="Uttar Pradesh">
                                Uttar Pradesh
                              </option>
                              <option value="Uttarakhand">Uttarakhand</option>
                              <option value="West Bengal">West Bengal</option>
                            </select>
                            {errorDetails.state != "" ? (
                              <span style={{ color: "red" }}>
                                This Field Can't be Blank
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="row err" id="error_place"></div>

                      <div class="form-group">
                        <div
                          class="g-recaptcha"
                          data-sitekey="6Le_aW8UAAAAAMWrCedYvbz-yDuEpSOnzMQJu6q9"
                          required
                        ></div>
                        <div id="errorCaptcha" style={{ color: "red" }}></div>
                      </div>

                      <div class="text-center">
                        <input
                          type="submit"
                          value="Submit"
                          class="btn btn-danger btn-block rounded-0 py-2"
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section
          style={{ background: "#fafafa" }}
          // id="what-we-do"
          id={Stylesland.what_we_do}
        >
          <div class="container">
            <div class="row mt-3">
              <div
                // style="margin:0 auto;"
                style={{ margin: "0 auto" }}
                class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              >
                <h2 className={`${Stylesland.section_title} mb-2 h1`}>
                  Current Challenges in Channel Management
                </h2>
                <div className={Stylesland.dash}></div>
              </div>
              <div className={`${Stylesland.adj_card_font} row mt-3`}>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                  <div className={Stylesland.card1}>
                    <div
                      className={`${Stylesland.card_block} ${Stylesland.block_i}`}
                    >
                      <h5
                        className={Stylesland.card_title}
                        style={{ color: "#212529" }}
                      >
                        Absence of Real time Channel Sales Authentic Data
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                  <div className={Stylesland.card1}>
                    <div
                      className={`${Stylesland.card_block} ${Stylesland.block_i}`}
                    >
                      <h5
                        className={Stylesland.card_title}
                        style={{ color: "#212529" }}
                      >
                        Time Consuming Manual Data Collection
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                  <div className={Stylesland.card1}>
                    <div
                      className={`${Stylesland.card_block} ${Stylesland.block_i}`}
                    >
                      <h5
                        className={Stylesland.card_title}
                        style={{ color: "#212529" }}
                      >
                        Resistance from Distributors
                      </h5>
                    </div>
                  </div>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                  <div className={Stylesland.card1}>
                    <div
                      className={`${Stylesland.card_block} ${Stylesland.block_i}`}
                    >
                      <h5
                        className={Stylesland.card_title}
                        style={{ color: "#212529" }}
                      >
                        {" "}
                        Multiple Brand Management for Distributors
                      </h5>
                    </div>
                  </div>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                  <div className={Stylesland.card1}>
                    <div
                      className={`${Stylesland.card_block} ${Stylesland.block_i}`}
                    >
                      <h5
                        className={Stylesland.card_title}
                        style={{ color: "#212529" }}
                      >
                        {" "}
                        Order fill rate of Secondry Sales
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                  <div className={Stylesland.card1}>
                    <div
                      className={`${Stylesland.card_block} ${Stylesland.block_i}`}
                    >
                      <h5
                        className={Stylesland.card_title}
                        style={{ color: "#212529" }}
                      >
                        Misappropriation of Sales, Discount & Scheme Data by
                        Distributors{" "}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id={Stylesland.what_we_do}>
          <div class="container">
            <div class="row mt-3">
              <div
                style={{ margin: "0 auto" }}
                // style="margin:0 auto;"
                class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              >
                <h2
                  class="section-title mb-2 h1"
                  className={`${Stylesland.section_title} mb-2 h1`}
                >
                  Smoothly Manage your Entire Distributor Network!
                </h2>
                <div className={Stylesland.dash}></div>
              </div>
            </div>
            <div
              className={`${Stylesland.adj_card_font} v2 mt-3 row`}
              // class="row mt-3 adj_card_font v2"
            >
              <div class="col-xl-4 col-lg-4 col-md-4 col-6">
                <div className={Stylesland.card}>
                  <div
                    className={`${Stylesland.card_block} ${Stylesland.block_4}`}
                    // class="card-block block-4"
                  >
                    <h5
                      className={Stylesland.card_title}
                      style={{ color: "#212529" }}
                    >
                      Tally/Busy
                      <br /> Integration
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-6">
                <div id="landing_b_card_adj" className={Stylesland.ca_rd}>
                  
                  <img
                    width="45"
                    height="50"
                    src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images/website.png"
                    alt=""
                  />
                  <h5
                    className={`ml-2 ${Stylesland.card_title}`}
                    style={{ color: "#212529" }}
                  >
                    Web 
                    DMS
                  </h5>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-6">
                <div id="landing_b_card_adj" className={Stylesland.ca_rd}>
                  <img
                    width="45"
                    height="50"
                    src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images/tracking.png"
                    alt=""
                  />
                  <h5
                    className={`ml-2 ${Stylesland.card_title}`}
                    style={{ color: "#212529" }}
                  >
                    Mobile  DMS
                  </h5>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-6">
                <div id="landing_b_card_adj" className={Stylesland.ca_rd}>
                  <img
                    width="45"
                    height="50"
                    src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images/api (5).png"
                    alt=""
                  />
                  <h5
                    className={`ml-2 ${Stylesland.card_title}`}
                    style={{ color: "#212529" }}
                  >
                    API 
                    Integration
                  </h5>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-6">
                <div className={Stylesland.card}>
                  <div
                    className={`${Stylesland.card_block} ${Stylesland.block_10}`}
                  >
                    <h5
                      className={Stylesland.card_title}
                      style={{ color: "#212529" }}
                    >
                      Scheme & Claims 
                      Management
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-6">
                <div className={Stylesland.card}>
                  <div
                    className={`${Stylesland.card_block} ${Stylesland.block_6}`}
                  >
                    <h5
                      className={Stylesland.card_title}
                      style={{ color: "#212529" }}
                    >
                      Replenishment & Order
                      
                      Management
                    </h5>
                  </div>
                </div>
              </div>

              <div class="col-xl-4 col-lg-4 col-md-4 col-6">
                <div className={Stylesland.card}>
                  <div
                    className={`${Stylesland.card_block} ${Stylesland.block_3}`}
                  >
                    <h5
                      className={Stylesland.card_title}
                      style={{ color: "#212529" }}
                    >
                      {" "}
                      Channel Sales
                      
                      Performance Tracking
                    </h5>
                  </div>
                </div>
              </div>

              <div class="col-xl-4 col-lg-4 col-md-4 col-6">
                <div className={Stylesland.card}>
                  <div
                    className={`${Stylesland.card_block} ${Stylesland.block_11}`}
                  >
                    <h5
                      className={Stylesland.card_title}
                      style={{ color: "#212529" }}
                    >
                      {" "}
                      Advance AI Powered 
                      Vernacular SFA
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-6">
                <div className={Stylesland.card}>
                  <div
                    className={`${Stylesland.card_block} ${Stylesland.block_12}`}
                  >
                    <h5
                      className={Stylesland.card_title}
                      style={{ color: "#212529" }}
                    >
                      Complete Master & Price Control{" "}
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-6">
                <div className={Stylesland.card}>
                  <div
                    className={`${Stylesland.card_block} ${Stylesland.block_13}`}
                  >
                    <h5
                      className={Stylesland.card_title}
                      style={{ color: "#212529" }}
                    >
                      Advance AI/ ML based 
                      BI Analytics{" "}
                    </h5>
                  </div>
                </div>
              </div>

              <div class="col-xl-4 col-lg-4 col-md-4 col-6">
                <div className={Stylesland.card}>
                  <div
                    className={`${Stylesland.card_block} ${Stylesland.block_5}`}
                  >
                    <h5
                      className={Stylesland.card_title}
                      style={{ color: "#212529" }}
                    >
                      Stock & Sales
                      Visibility
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-6">
                <div className={Stylesland.card}>
                  <div
                    className={`${Stylesland.card_block} ${Stylesland.block_14}`}
                  >
                    <h5
                      className={Stylesland.card_title}
                      style={{ color: "#212529" }}
                    >
                      Offline Mode & Multi 
                      Brand Invoicing
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ParticleMain />
        <section style={{ marginTop: "20px", paddingTop: "0" }}>
          <h2
            style={{ marginBottom: "20px" }}
            className={`${Stylesland.section_title} mb-2 h1`}
          >
            What Our Clients Say About Us
          </h2>
          <div className={Stylesland.dash}></div>
          <div
            // style=""
            id={Stylesland.myCarousel}
            // id="myCarousel"
            className={`${Stylesland.carousel} ${Stylesland.slide} ${Stylesland.testi}`}
            // class="carousel slide testi"
            data-ride="carousel"
          >
            <Carousel
              autoPlay
              infiniteLoop={true}
              interval={3000}
              showArrows={false}
              ref={sliderTestimony}
              showIndicators={false}
              showStatus={false}
            >
              <div
                // className={`${Stylesland.item} ${Stylesland.caroud}`}
                class="item  active"
              >
                <div
                  // className={Stylesland.img_box}
                  className={Stylesland.img_box}
                >
                  <img
                    src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images/doms.png"
                    alt=""
                  />
                </div>
                <p className={Stylesland.testimonial}>
                  "..it has helped us on getting daily updated data on
                  superstockists to distributor billing, ..done away with manual
                  data compiling work at our end and also helped in
                  Auto-replenishment of stocks resulting in smooth flow at our
                  Superstockist."
                </p>
                <p className={Stylesland.overview}>
                  <b>Krishna Anand</b> Vice-president Sales, DOMS Industries
                  Pvt. Ltd.
                </p>
                <div className={Stylesland.star_rating}>
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star-half-o"></i>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="item  active">
                <div className={Stylesland.img_box}>
                  <img
                    src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images/WEIK.png"
                    alt=""
                  />
                </div>
                <p className={Stylesland.testimonial}>
                  "..our challenges were to capture channel sales and inventory
                  without investing much on the infrastructure ..with the strong
                  support of EazyDMS team, together we can plug the gap."
                </p>
                <p className={Stylesland.overview}>
                  <b> Amit Singh</b> Digital Transformation Head, Weikfield
                </p>
                <div className={Stylesland.star_rating}>
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star-half-o"></i>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="item  active">
                <div className={Stylesland.img_box}>
                  <img
                    src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images-webp/MERIONO.webp"
                    alt=""
                  />
                </div>
                <p className={Stylesland.testimonial}>
                  "EazyDMS allows hosting Oracle Cloud that provides the
                  security as well as reliability to our customers, we are able
                  to do the inventory monitoring which helps the company to
                  serve the customers more efficiently."
                </p>
                <p className={Stylesland.overview}>
                  <b>Anand Chaudhary</b>Head IT, Merino Industries
                </p>
                <div className={Stylesland.star_rating}>
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star-half-o"></i>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="item  active">
                <div className={Stylesland.img_box}>
                  <img
                    src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images-webp/MERIONO.webp"
                    alt=""
                  />
                </div>
                <p className={Stylesland.testimonial}>
                  "EazyDMS allows hosting Oracle Cloud that provides the
                  security as well as reliability to our customers, we are able
                  to do the inventory monitoring which helps the company to
                  serve the customers more efficiently."
                </p>
                <p className={Stylesland.overview}>
                  <b>Anand Chaudhary</b>Head IT, Merino Industries
                </p>
                <div className={Stylesland.star_rating}>
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star-half-o"></i>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="item  active">
                <div className={Stylesland.img_box}>
                  <img
                    src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images/rrcables.jpg"
                    alt=""
                    style={{ height: "30px" }}
                  />
                </div>
                <p className={Stylesland.testimonial}>
                  "..we can map all the parameters of a secondary sales
                  transaction which has helped us to strategize in a better way
                  in the distribution network. It was also easy for the
                  distributors as they don't have to spend any extra effort to
                  maintain Tally, by syncing it every day."
                </p>
                <p className={Stylesland.overview}>
                  <b>Dipankar Dutta</b>National Sales Head of RR SNM
                </p>
                <div className={Stylesland.star_rating}>
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star-half-o"></i>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="item  active">
                <div className={Stylesland.img_box}>
                  <img
                    src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images-webp/Scheneider_L&T_logo.webp"
                    alt=""
                  />
                </div>
                <p className={Stylesland.testimonial}>
                  "today we have a significantly good accurate information about
                  our secondary sales. This has also helped us to reduce the
                  cost through the savings which we could do by shifting the
                  schemes from Primary to Secondary.
                </p>
                <p className={Stylesland.overview}>
                  <b>Sameer Chaudhary</b>Scheneider
                </p>
                <div className={Stylesland.star_rating}>
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star-half-o"></i>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="item  active">
                <div className={Stylesland.img_box}>
                  <img
                    src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images-webp/KENSTAR.webp"
                    alt=""
                  />
                </div>
                <p className={Stylesland.testimonial}>
                  "it's really a full Integrated platform which works very
                  easily with Tally Busy Accounting systems...they have a lot of
                  new features which we'll be a part of in their journey."
                </p>
                <p className={Stylesland.overview}>
                  <b>Pratyush</b>Strategic Team, Kenstar
                </p>
                <div className={Stylesland.star_rating}>
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star-half-o"></i>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="item  active">
                <div className={Stylesland.img_box}>
                  <img
                    src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images/cr.jpg"
                    alt=""
                  />
                </div>
                <p className={Stylesland.testimonial}>
                  “Eazy DMS is a robust & effective Distributor Management
                  Software that has helped our team in garnering real time sales
                  data for timely decision making from our distributors across
                  India which was not possible with our inhouse developed DMS.
                  Data accuracy has improved tremendously enabling us in better
                  decision making."
                </p>
                <p className={Stylesland.overview}>
                  <b>Onkar Burnwal</b>Senior Marketing Manager
                </p>
                <div className={Stylesland.star_rating}>
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star" aria-hidden="true"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="item  active">
                <div className={Stylesland.img_box}>
                  <img
                    src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images/gy.jpg"
                    alt=""
                  />
                </div>
                <p className={Stylesland.testimonial}>
                  “One of our major pain areas was collecting secondary sales
                  data on-time. After evaluating all the available Distributor
                  Management Software's in the market, we narrowed down Eazy DMS
                  due to its ability to fetch information from distributor's
                  Tally. This allowed us to convince our distributors easily
                  since we were not burdening them with any additional work.“
                </p>
                <p className={Stylesland.overview}>
                  <b>Manish Verma</b> Head – Sales Operations
                </p>
                <div className={Stylesland.star_rating}>
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star-half-o"></i>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="item  active">
                <div className={Stylesland.img_box}>
                  <img
                    src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images/shell.jpg"
                    alt=""
                  />
                </div>
                <p className={Stylesland.testimonial}>
                  "Eazy DMS simplifies our Distributors Tally data. The Eazy DMS
                  portal is quite exhaustive and adaptable to changes. We faced
                  quite a few hurdles in the transition but the team's diligence
                  saw us successfully through the same. The team has also come a
                  long way in improving its customer service operations. "
                </p>
                <p className={Stylesland.overview}>
                  <b>Kanica Rangnekar</b>Digital Data Strategy & Implementation
                  Manager
                </p>
                <div className={Stylesland.star_rating}>
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-star-half-o"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </Carousel>
            <div className="CustomerSliderButton" style={{ marginTop: "0px" }}>
              <button
                className="btn customerSlidercssforbutton"
                onClick={NextTestimony}
              >
                <FontAwesomeIcon icon={faLongArrowAltLeft} />
              </button>
              <button
                className="btn customerSlidercssforbutton"
                onClick={PrevTestimony}
              >
                <FontAwesomeIcon icon={faLongArrowAltRight} />
              </button>
            </div>
          </div>
        </section>

        <section
          className={`${Stylesland.clients} pb-0`}
          // class="clients pb-0"
          id={Stylesland.trust}
        >
          <div class="container">
            <div class="row text-center justify-content-center">
              <div class="col-md-7">
                <h2 className={`${Stylesland.section_title} text-center h1`}>
                  Trusted by the Best
                </h2>
                <div
                  className={`${Stylesland.divider} ${Stylesland.div_transparent}`}
                ></div>
              </div>
            </div>
            <div class="row pt-3">
              <div class="col-xl-2 col-lg-2 col-md-3 col-6">
                <img
                  src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images-webp/General_Mills.webp"
                  className={Stylesland.client_logo}
                />
              </div>
              <div class="col-xl-2 col-lg-2 col-md-3 col-6">
                <img
                  src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images-webp/Bungee.webp"
                  className={`${Stylesland.client_logo} bungee`}
                />
              </div>
              <div class="col-xl-2 col-lg-2 col-md-3 col-6">
                <img
                  src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images/3-3.jpg"
                  className={Stylesland.client_logo}
                />
              </div>
              <div class="col-xl-2 col-lg-2 col-md-3 col-6">
                <img
                  src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images/haier.jpg"
                  className={Stylesland.client_logo}
                />
              </div>
              <div class="col-xl-2 col-lg-2 col-md-3 col-6">
                <img
                  src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images/Philips_signify_logo.png"
                  className={`${Stylesland.client_logo} signyfy`}
                />
              </div>
              <div class="col-xl-2 col-lg-2 col-md-3 col-6">
                <img
                  src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images/compton.jpg"
                  className={Stylesland.client_logo}
                />
              </div>

              <div class="col-xl-2 col-lg-2 col-md-3 col-6">
                <img
                  src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images-webp/Astal_pipes.webp"
                  className={Stylesland.client_logo}
                />
              </div>
              <div class="col-xl-2 col-lg-2 col-md-3 col-6">
                <img
                  src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images/hindware.png"
                  className={`${Stylesland.client_logo} hindware`}
                />
              </div>
              <div class="col-xl-2 col-lg-2 col-md-3 col-6">
                <img
                  src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images/greenply_logo.png"
                  className={`${Stylesland.client_logo} greenply`}
                />
              </div>
              <div class="col-xl-2 col-lg-2 col-md-3 col-6">
                <img
                  src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images-webp/tafe.webp"
                  className={`${Stylesland.client_logo} tafe`}
                />
              </div>
              <div class="col-xl-2 col-lg-2 col-md-3 col-6">
                <img
                  src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images-webp/Official_BPCL.webp"
                  className={`${Stylesland.client_logo} bpcl`}
                />
              </div>
              <div class="col-xl-2 col-lg-2 col-md-3 col-6">
                <img
                  src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images/3.jpg"
                  className={Stylesland.client_logo}
                />
              </div>
            </div>
          </div>
        </section>
        <section class="pt-0 pb-0" id={Stylesland.awards}>
          <div class="container">
            <div class="row">
              <div class="col-md-12 col-xs-12">
                <div
                  className={`${Stylesland.divider} ${Stylesland.div_transparent}`}
                ></div>
                <h2>Awards & Recognition</h2>
              </div>
            </div>
          </div>
          <img
            width="100%"
            class="img-fluid"
            src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images-webp/website banner award.webp"
            alt="Awards Banner"
          />
        </section>
        <div
          style={{ marginTop: "40px", paddingTop: "10px" }}
          class="container"
        >
          <h2 className={`${Stylesland.section_title} mb-2 h1 text-center`}>
            Our Presence
          </h2>
          <div className={Stylesland.dash}></div>
        </div>

        <div
          style={{ padding: "10px 2%", marginBottom: "20px" }}
          class="fluid-container plaptop"
        >
          <div class="row">
            <div class="col-xl col-lg col-md-4 mb-4">
              <img
                style={{ width: "100%" }}
                src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images-webp/ahmedabad-1.webp"
                class="align-middle img-responsive"
              />
            </div>
            <div class="col-xl col-lg col-md-4 mb-4">
              <img
                style={{ width: "100%" }}
                src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images/begaluru-1.jpg"
                class="align-middle img-responsive"
              />
            </div>
            <div class="col-xl col-lg col-md-4 mb-4">
              <img
                style={{ width: "100%" }}
                src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images/gurgaon-1.jpg"
                class="align-middle img-responsive"
              />
            </div>
            <div class="col-xl col-lg col-md-6 mb-4">
              <img
                style={{ width: "100%" }}
                src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images-webp/kolkata-1.webp"
                class="align-middle img-responsive"
              />
            </div>
            <div class="col-xl col-lg col-md-6 mb-4">
              <img
                style={{ width: "100%" }}
                src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images/mumbai-1.jpg"
                class="align-middle img-responsive"
              />
            </div>
          </div>
        </div>
        {/* <div style={{ padding: "10px 2%" }} class="fluid-container presence">
          <div class="row">
            <div class="col-xs-12 col1 ">
              <img
                style={{ width: "100%" }}
                src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images-webp/ahmedabad-1.webp"
                class="align-middle img-responsive"
              />
            </div>
            <div class="col-xs-12 col1 ">
              <img
                style={{ width: "100%" }}
                src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images/begaluru-1.jpg"
                class="align-middle img-responsive"
              />
            </div>
            <div class="col-xs-12 col1 ">
              <img
                style={{ width: "100%" }}
                src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images/gurgaon-1.jpg"
                class="align-middle img-responsive"
              />
            </div>
            <div class="col-xs-12 col1 ">
              <img
                style={{ width: "100%" }}
                src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images-webp/kolkata-1.webp"
                class="align-middle img-responsive"
              />
            </div>
            <div class="col-xs-12 col1 ">
              <img
                style={{ width: "100%" }}
                src="https://eazysites.s3.amazonaws.com/eazydms/landingb/images/mumbai-1.jpg"
                class="align-middle img-responsive"
              />
            </div>
          </div>
        </div> */}
      </div>
      <footer>
        <div class="container-fluid py-3">
          <div class="container">
            <div class="row">
              <div class="col-md-8">
                <div class="row py-0">
                  <div class="col-sm-1 hidden-md-down">
                    {/* <div class="bg-circle bg-info"> */}
                    <i
                      class="fa fa-2x fa-fw fa-address-card"
                      aria-hidden="true "
                      style={{ color: "#4ad1e5" }}
                    ></i>
                    {/* </div> */}
                  </div>
                  <div class="col-sm-11 text-white">
                    <div style={{ color: "#fff" }}>
                      <h4>Contact</h4>
                      <p style={{ color: "#fff" }}>
                        +91 8130285554 | contact@eazydms.com
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 text-right copy">
                <div class="d-inline-block social">
                  <div
                    class="bg-circle-outline icon d-inline-block"
                    style={{ background: "#3b5998", marginRight: "10px" }}
                  >
                    <a
                      target="_blank"
                      href="https://www.facebook.com/eazyerp/?ref=settings"
                    >
                      <span className={Stylesland.sociallandingbIcon}>
                        <FontAwesomeIcon
                          icon={faFacebook}
                          style={{ fontSize: "22px" }}
                        />
                      </span>

                      {/* <i class="fab fa-fw fa-facebook text-white"></i> */}
                    </a>
                  </div>
                  <div
                    class="bg-circle-outline icon d-inline-block"
                    style={{ background: "#3b5998", marginRight: "10px" }}
                  >
                    <a target="_blank" href="https://twitter.com/EazyERP">
                      <span className={Stylesland.sociallandingbIcon}>
                        <FontAwesomeIcon
                          icon={faTwitter}
                          style={{ fontSize: "22px" }}
                        />
                      </span>
                      {/* <i class="fab fa-fw fa-twitter text-white"></i> */}
                    </a>
                  </div>

                  <div
                    class="bg-circle-outline icon d-inline-block"
                    style={{ background: "#3b5998", marginRight: "10px" }}
                  >
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/singhal-system-solutions-pvt-ltd---offical-page/"
                    >
                      <span className={Stylesland.sociallandingbIcon}>
                        <FontAwesomeIcon
                          icon={faLinkedin}
                          style={{ fontSize: "22px" }}
                        />
                      </span>
                      {/* <i class="fab fa-fw fa-linkedin text-white"></i> */}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <p class="text-center m-1 copy" style={{ color: "black" }}>
        ©2019 EAZY Business Solutions. All right reserved.{" "}
      </p>
      <ResponsePopUp
              open={responseDetails.isResponseOpen}
              onClose={handleResponseClose}
              maxWidth={"xs"}
              content={responseDetails.responseContent}
              type={responseDetails.responseType}
             
            />
    </>
  );
};

export default LandingBPage;
