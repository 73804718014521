import React, { Fragment } from "react";

import Home from "../Home/Home";
import ClientSection from "./ClientSections";
import ParticleMain from "./pariclesSections";
import Footer from "./footer";
import { Topbar } from "../Topbar";
import AfterTopBar from "../AfterTopbar/AfterTopbar";

const Sections = () => {
  return (
    <Fragment>
      <div>
        <div className="main_header">
          <Topbar />
          <AfterTopBar />
        </div>
        <Home />
        <ClientSection />
        <ParticleMain />
        <Footer />
      </div>
    </Fragment>
  );
};

export default Sections;
