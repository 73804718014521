import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
// import "./Particle.css";
export default function ParticleComponent() {
  const particlesInit = async (main) => {
    // console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(main);
  };

  return (
    <>
      <div className="particle_container">
        <Particles
          canvasClassName="canvas_section"
          id="tsparticles"
          init={particlesInit}
          options={{
            fullScreen: {
              enable: false,
              zIndex: 1,
            },
            particles: {
              number: {
                value: 50,
                density: {
                  enable: false,
                  value_area: 800,
                },
              },
              color: {
                value: "#fff",
              },
              shape: {
                type: "circle",
                stroke: {
                  width: 0,
                  color: "#ff0000",
                },
                polygon: {
                  nb_sides: 9,
                },
                image: {
                  src: "",
                  width: 100,
                  height: 100,
                },
              },
              opacity: {
                value: 1,
                random: false,
                anim: {
                  enable: false,
                  speed: 2,
                  opacity_min: 0,
                  sync: false,
                },
              },
              size: {
                value: 2,
                random: false,
                anim: {
                  enable: false,
                  speed: 10,
                  size_min: 0,
                  sync: false,
                },
              },
              particles: {
                color: {
                  value: "#032a63",
                },
              },
              line_linked: {
                enable: true,
                distance: 100,
                color: "#fff",
                opacity: 1,
                width: 1,
              },
              move: {
                enable: true,
                speed: 2,
                direction: "none",
                random: false,
                straight: false,
                out_mode: "out",
                bounce: false,
                attract: {
                  enable: false,
                  rotateX: 3000,
                  rotateY: 3000,
                },
              },
              array: [],
            },
            interactivity: {
              detect_on: "canvas",
              events: {
                onhover: {
                  enable: true,
                  mode: "grab",
                },
                onclick: {
                  enable: true,
                  mode: "push",
                },
                resize: true,
              },
              modes: {
                grab: {
                  distance: 400,
                  line_linked: {
                    opacity: 1,
                  },
                },
                bubble: {
                  distance: 400,
                  size: 20,
                  duration: 2,
                  opacity: 8,
                  speed: 3,
                },
                repulse: {
                  distance: 400,
                },
                push: {
                  particles_nb: 4,
                },
                remove: {
                  particles_nb: 2,
                },
              },
            },
            retina_detect: true,
            background: {
              //   color: "#fff",
              image: "",
              position: "50% 50%",
              repeat: "no-repeat",
              size: "cover",
              position: "relative",
            },
          }}
        />
      </div>
    </>
  );
}
