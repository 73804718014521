import React, { useEffect, useState } from "react";

import Nav from "../Nav/Nav";
function AfterTopBar() {
  const [navClass, setNavClass] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let navClass = "";
      if (window.scrollY >= 100) {
        navClass = "scrolled";
      }
      setNavClass(navClass);
    });
  }, []);
  return (
    <>
      <div
        className={`header-logo-support ${navClass}`}
        // style={{ color: "#fff" }}
      >
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <Nav navClass={navClass} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AfterTopBar;
