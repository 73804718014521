import React from "react";
import { faAngleRight, faBackward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { CustomerSupport } from "./CustomerSupport";
import { CustomerFooter } from "./CustomerFooter";
import Footer from "../Sections/footer";
import { Topbar } from "../Topbar";
import AfterTopBar from "../AfterTopbar/AfterTopbar";

const CustomePage = () => {
  return (
    <>
      <div className="main_header">
        <Topbar />
        <AfterTopBar />
      </div>
      <div className="page-area1">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="breadcrumb">
                <div className="section-headline white-headline text-center">
                  <h4
                    style={{
                      fontSize: "48px",
                      letterSpacing: "2px",
                      lineHeight: "48px",
                      color: "#fff",
                      display: "inline-block",
                      maxWidth: "100%",
                    }}
                  >
                    CUSTOMERS
                  </h4>
                </div>
                <ul>
                  <li className="home-bread">
                    Home
                    <span style={{ marginLeft: "7px" }}></span>
                    <FontAwesomeIcon icon={faAngleRight} />
                    <span style={{ marginLeft: "7px" }}></span> Customer
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="area-padding">
        <div className="container">
          <div className="row mb-5 mt-4">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="section-headline text-center">
                <h4 style={{ display: "inline-block", maxWidth: "100%" }}>
                  Our <span className="color">Customers</span>
                </h4>
              </div>
            </div>
          </div>
          <div className="well-inner" style={{ color: "#7F8499" }}>
            <div className="row">
              <div className="col-lg-2 col-md-4 col-6">
                <div className="well-services cust">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/2.jpg"
                      alt="Client logo Crompton"
                    />
                  </div>
                  <div className="well-content">
                    <p>Crompton </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <div className="well-services cust">
                  <div className="well-icon text-center">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/Haier_logo.png"
                      //   style="padding:20px 10px;"
                      style={{ padding: "20px 10px" }}
                      alt="Client logo Haier"
                    />
                  </div>
                  <div className="well-content">
                    <p>Haier</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <div className="well-services cust">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/Philips_signify_logo.png"
                      alt="Client logo Philips"
                    />
                  </div>
                  <div className="well-content">
                    <p>Philips</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <div className="well-services cust">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/Scheneider_L&T_logo.png"
                      alt="Client logo Scheneider"
                    />
                  </div>
                  <div className="well-content">
                    <p>Scheneider + L&T</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <div className="well-services cust">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/Logo_Legrand.png"
                      alt="Client logo Legrand"
                      style={{ paddingTop: "10px", paddingLeft: "15px" }}
                    />
                  </div>
                  <div className="well-content">
                    <p>Legrand</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <div className="well-services cust">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/V-G.png"
                      alt="Client logo V-Guard"
                    />
                  </div>
                  <div className="well-content">
                    <p>V-Guard </p>
                  </div>
                </div>
              </div>

              {/* <div className="col-lg-2 col-md-4 col-6">
                <div className="well-services cust">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/haldiramnagpur.png"
                      alt="Client logo HALDIRAM"
                      style={{ paddingTop: "10px", paddingLeft: "15px" }}
                    />
                  </div>
                  <div className="well-content">
                    <p>Haldiram Nagpur</p>
                  </div>
                </div>
              </div> 

              <div className="col-lg-2 col-md-4 col-6">
                <div className="well-services cust">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/General_Mills.jpg"
                      style={{ height: "96px" }}
                      alt="Client Logo General_mills"
                    />
                  </div>
                  <div className="well-content">
                    <p>General Mills</p>
                  </div>
                </div>
              </div> */}

              <div className="col-lg-2 col-md-4 col-6">
                <div className="well-services cust">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/anmol.jpg"
                      alt="Client Logo Cremica"
                    />
                  </div>
                  <div className="well-content">
                    <p>Anmol Biscuits</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <div className="well-services cust">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/Bungee.png"
                      alt="Client logo Bungee"
                      style={{ height: "86px" }}
                    />
                  </div>
                  <div className="well-content">
                    <p>Bunge</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <div className="well-services cust">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/WEIK.png"
                      alt="Client logo Weikfield"
                      style={{ height: "86px" }}
                    />
                  </div>
                  <div className="well-content">
                    <p>Weikfield</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <div className="well-services cust">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/7.jpg"
                      alt="Client logo MDH"
                    />
                  </div>
                  <div className="well-content">
                    <p>MDH Group</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <div className="well-services cust">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/campus.jpg"
                      alt="Client logo Campus"
                    />
                  </div>
                  <div className="well-content">
                    <p>Campus Shoes</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <div className="well-services cust">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/VIP.png"
                      alt="Client logo VIP"
                    />
                  </div>
                  <div className="well-content">
                    <p>VIP</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <div className="well-services cust">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/hamilton.jpg"
                      alt="Client logo Hamilton Group"
                    />
                  </div>
                  <div className="well-content">
                    <p>Hamilton Group</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <div className="well-services cust">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/Mamaearth_logo.jpeg"
                      alt="Client logo Mamaearth"
                      tyle={{ height: "86px" }}
                    />
                  </div>
                  <div className="well-content">
                    <p>Mamaearth</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <div className="well-services cust">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/doms.png"
                      alt="Client logo DOMS"
                      class="customer_long_image"
                    />
                  </div>
                  <div className="well-content">
                    <p>DOMS</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <div className="well-services cust">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/borosil.jpg"
                      alt="Client logo BOROSIL"
                      style={{ height: "86px", width: "145px" }}
                    />
                  </div>
                  <div className="well-content">
                    <p>Borosil</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <div className="well-services cust">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/AstalPipes_logo.png"
                      alt="Client logo Astral Adhesives"
                    />
                  </div>
                  <div className="well-content">
                    <p>Astral Adhesive</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <div className="well-services cust">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/hindware.png"
                      alt="Client logo Hindware"
                      style={{
                        width: "145px",
                        paddingTop: "15px",
                        paddingBottom: "15px",
                      }}
                    />
                  </div>
                  <div className="well-content">
                    <p>Hindware</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <div className="well-services cust">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/greenply_logo.png"
                      alt="Client logo Greenphy"
                      style={{ width: "145px" }}
                    />
                  </div>
                  <div className="well-content">
                    <p>Greenphy</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <div className="well-services cust">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/16.jpg"
                      alt="Client logo Dorset"
                    />
                  </div>
                  <div className="well-content">
                    <p>Dorset</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <div className="well-services cust">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/assa-abloy-logo.png"
                      alt="Client logo Dorset"
                    />
                  </div>
                  <div className="well-content">
                    <p>Assa Abloy</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <div className="well-services cust">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/HENKEL.png"
                      alt="Client logo Henkel"
                    />
                  </div>
                  <div className="well-content">
                    <p>Henkel</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <div className="well-services cust">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/tafe.png"
                      alt="Client logo TAFE"
                    />
                  </div>
                  <div className="well-content">
                    <p>TAFE</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <div className="well-services cust">
                  <div className="well-icon text-center">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/Official_BPCL.jpg"
                      alt="Client logo BPCL"
                      class="customer_long_image"
                    />
                  </div>
                  <div className="well-content">
                    <p>BPCL</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <div className="well-services cust">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/jamna-dms.jpg"
                      alt="Client logo Jamna Auto"
                    />
                  </div>
                  <div className="well-content">
                    <p>Jamna Auto</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <div className="well-services cust">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/3.jpg"
                      alt="Client logo GoodYear"
                    />
                  </div>
                  <div className="well-content">
                    <p>Goodyear</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <div className="well-services cust">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/motul.jpeg"
                      alt="Client logo MOTUL"
                      style={{
                        width: "145px",
                        paddingTop: "23px",
                        paddingBottom: "23px",
                      }}
                    />
                  </div>
                  <div className="well-content">
                    <p>Motul</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <div className="well-services cust">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/Hella_logo.png"
                      alt="Client logo Hella"
                    />
                  </div>
                  <div className="well-content">
                    <p>Hella</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomerFooter />
      <Footer />
    </>
  );
};

export default CustomePage;
