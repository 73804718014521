import ParticleComponent from "./ParticleComponent";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandshake,
  faShippingFast,
  faShoppingCart,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
// import "./Particle.css";
import VisibilitySensor from "react-visibility-sensor";
import CountUp, { useCountUp } from "react-countup";
export default function ParticleMain() {
  return (
    <>
      <div>
        <div className="container particles">
          <div className="row align-items-center" style={{ marginTop: "50px", marginBottom: "50px" }}>
            <div className="col-md-5 col-sm-6">
              <div className="number-background">
                <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                  {({ isVisible }) => (
                    <div class="serv-section-desc">
                      {isVisible ? (
                        <CountUp
                          className="number"
                          end={16}
                          duration="5"
                          suffix=""
                        />
                      ) : null}
                      <span className="mini">Years</span>
                    </div>
                  )}
                </VisibilitySensor>
                <ParticleComponent />

              </div>
              <div className="yrss mt-2">
                <h2>Years of  Excellence</h2>

              </div>
              <div className="yrss_p">
                <p className="my-3">
                  EAZY Business Solutions was incorporated in 2007 with a vision to
                  provide best IT Service solution via its bouquet of products like
                  Eazy ERP, Eazy DMS, Eazy CRM, Retail Eazy to name a few.
                </p>
                <Link to="/" className="btn btn-danger btn_shadow mt-2 mb-3">
                  Know More <i class="fas fa-long-arrow-alt-right mt-1"></i>
                </Link>
              </div>
            </div>
            <div className="col-md-7 mt_5 pl-5 col-sm-6">
              <div className="yrs mt-4">
                <h2>Years of  Excellence</h2>
                <p className="my-3">
                  EAZY Business Solutions was incorporated in 2007 with a vision to
                  provide best IT Service solution via its bouquet of products like
                  Eazy ERP, Eazy DMS, Eazy CRM, Retail Eazy to name a few.
                </p>

                <Link to="/" className="btn btn-danger btn_shadow mt-2 mb-3 reqst_demo">
                  Know More <i class="fas fa-long-arrow-alt-right mt-1"></i>
                </Link>
              </div>




            </div>

          </div>


        </div>
        <div className="right_cont">
          <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-6">
              <VisibilitySensor partialVisibility offset={{ bottom: 100 }}>
                {({ isVisible }) => (
                  <div class="serv-section-desc">
                    <FontAwesomeIcon
                      icon={faHandshake}
                      style={{ fontSize: "30px" }}
                    />
                    <br />
                    {isVisible ? (
                      <CountUp
                        className="numm"
                        end={700}
                        duration="5"
                        suffix="+"
                      />
                    ) : null}
                    <h4>Brands</h4>
                  </div>
                )}
              </VisibilitySensor>
            </div>

            <div className="col-lg-3 col-md-6 col-6">
              <VisibilitySensor partialVisibility offset={{ bottom: 100 }}>
                {({ isVisible }) => (
                  <div class="serv-section-desc">
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{ fontSize: "30px" }}
                    />
                    <br />
                    {isVisible ? (
                      <CountUp
                        className="numm"
                        end={350}
                        duration="5"
                        suffix="+"
                      />
                    ) : null}
                    <h4>Employees</h4>
                  </div>
                )}
              </VisibilitySensor>
            </div>

            <div className="col-lg-3 col-md-6 col-6">
              <VisibilitySensor partialVisibility offset={{ bottom: 100 }}>
                {({ isVisible }) => (
                  <div class="serv-section-desc">
                    <FontAwesomeIcon
                      icon={faShippingFast}
                      style={{ fontSize: "30px" }}
                    />
                    <br />
                    {isVisible ? (
                      <CountUp
                        className="numm"
                        end={40}
                        duration="5"
                        suffix="K+"
                      />
                    ) : null}
                    <h4>Distributors</h4>
                  </div>
                )}
              </VisibilitySensor>
            </div>

            <div className="col-lg-3 col-md-6 col-6">
              <VisibilitySensor partialVisibility offset={{ bottom: 100 }}>
                {({ isVisible }) => (
                  <div class="serv-section-desc">
                    <FontAwesomeIcon
                      icon={faShoppingCart}
                      style={{ fontSize: "30px" }}
                    />
                    <br />
                    {isVisible ? (
                      <CountUp
                        className="numm"
                        end={4}
                        duration="5"
                        suffix="Lakh +"
                      />
                    ) : null}
                    <h4>Retailers</h4>
                  </div>
                )}
              </VisibilitySensor>
            </div>
          </div>
          </div>
        </div>
      </div>
    </>
  );
}
