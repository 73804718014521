import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faCircleCheck,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { Topbar } from "../Topbar";
import AfterTopBar from "../AfterTopbar/AfterTopbar";
import Footer from "./footer";
export const PrivacyPolicy = () => {
  return (
    <>
      <div className="main_header">
        <Topbar />
        <AfterTopBar />
      </div>
      <div class="page-area7">
        <div class="container">
          <div class="row text-center">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="breadcrumb">
                <div class="section-headline white-headline text-center">
                  <h3 className="text-dark">Welcome to our Privacy Policy</h3>
                  <div style={{ height: "10px", width: "100%" }}></div>
                  <h5 style={{ color: "#000" }}>
                    Your privacy is critically important to us.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="area-padding bg-color">
        <div class="container" style={{ color: "#6c757d" }}>
          <div class="row">
            <div
              style={{
                minHeight: "100px",
                textAlign: "center",
                paddingTop: "20px",
              }}
              class="col-md-12 col-sm-12 col-xs-12 "
            >
              <div
                style={{
                  fontFamily: "Georgia,Times,Times New Roman,serif",
                  fontSize: "16px",
                  //   textAlign: "justify",
                }}
              >
                Eazy ERP Technologies Pvt. Ltd. is located at:
                <br />
                Eazy ERP Technologies Pvt. Ltd. 219-220, Vipul Agora Building MG
                Road Haryana, India. Ph: 01244794479
                <br />
                <br />
              </div>
              <div
                style={{
                  fontFamily: "Georgia,Times,Times New Roman,serif",
                  fontSize: "16px",
                  textAlign: "justify",
                }}
              >
                It is Eazy ERP Technologies Pvt. Ltd.'s policy to respect your
                privacy regarding any information we may collect while operating
                our website. This Privacy Policy applies to eazydms.com
                (hereinafter, "us", "we", or "eazydms.com"). We respect your
                privacy and are committed to protecting personally identifiable
                information you may provide us through the Website. We have
                adopted this privacy policy ("Privacy Policy") to explain what
                information may be collected on our Website, how we use this
                information, and under what circumstances we may disclose the
                information to third parties. This Privacy Policy applies only
                to information we collect through the Website and does not apply
                to our collection of information from other sources. This
                Privacy Policy, together with the Terms and conditions posted on
                our Website, set forth the general rules and policies governing
                your use of our Website. Depending on your activities when
                visiting our Website, you may be required to agree to additional
                terms and conditions.
                <br />
              </div>
              <div
                style={{
                  fontFamily: "Georgia,Times,Times New Roman,serif",
                  fontSize: "16px",
                  textAlign: "left",
                  marginTop: "25px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <h4 style={{ color: "black" }}>Website Visitors</h4>
                Like most website operators, Eazy ERP Technologies Pvt. Ltd.
                collects non-personally-identifying information of the sort that
                web browsers and servers typically make available, such as the
                browser type, language preference, referring site, and the date
                and time of each visitor request. Eazy ERP Technologies Pvt.
                Ltd.'s purpose in collecting non-personally identifying
                information is to better understand how Eazy ERP Technologies
                Pvt. Ltd.'s visitors use its website. From time to time, Eazy
                ERP Technologies Pvt. Ltd. may release
                non-personally-identifying information in the aggregate, e.g.,
                by publishing a report on trends in the usage of its website.
                Eazy ERP Technologies Pvt. Ltd. also collects potentially
                personally-identifying information like Internet Protocol (IP)
                addresses for logged in users and for users leaving comments on
                http://eazydms.com blog posts. Eazy ERP Technologies Pvt. Ltd.
                only discloses logged in user and commenter IP addresses under
                the same circumstances that it uses and discloses
                personally-identifying information as described below.
                <br />
                <br />
                <h4 style={{ color: "black" }}>
                  Gathering of Personally-Identifying Information
                </h4>
                Certain visitors to Eazy ERP Technologies Pvt. Ltd.'s websites
                choose to interact with Eazy ERP Technologies Pvt. Ltd. in ways
                that require Eazy ERP Technologies Pvt. Ltd. to gather
                personally-identifying information. The amount and type of
                information that Eazy ERP Technologies Pvt. Ltd. gathers depends
                on the nature of the interaction. For example, we ask visitors
                who sign up for a demo at https://eazydms.com/landingb to
                provide a username, company name, phone number, email address
                and location. By providing this information they agree to opt in
                for our newsletters, emailers, promotions etc.
                <br />
                <br />
                <h4 style={{ color: "black" }}>Security</h4>
                The security of your Personal Information is important to us,
                but remember that no method of transmission over the Internet,
                or method of electronic storage is 100% secure. While we strive
                to use commercially acceptable means to protect your Personal
                Information, we cannot guarantee its absolute security.
                <br />
                <br />
                <h4 style={{ color: "black" }}>Advertisements</h4>
                Ads appearing on our website may be delivered to users by
                advertising partners, who may set cookies. These cookies allow
                the ad server to recognize your computer each time they send you
                an online advertisement to compile information about you or
                others who use your computer. This information allows ad
                networks to, among other things, deliver targeted advertisements
                that they believe will be of most interest to you. This Privacy
                Policy covers the use of cookies by Eazy ERP Technologies Pvt.
                Ltd. and does not cover the use of cookies by any advertisers.
                <br />
                <br />
                <h4 style={{ color: "black" }}>Links To External Sites</h4>
                Our Service may contain links to external sites that are not
                operated by us. If you click on a third party link, you will be
                directed to that third party's site. We strongly advise you to
                review the Privacy Policy and terms and conditions of every site
                you visit. We have no control over, and assume no responsibility
                for the content, privacy policies or practices of any third
                party sites, products or services.
                <br />
                <br />
                <h4 style={{ color: "black" }}>
                  Eazydms.com uses Google AdWords for remarketing
                </h4>
                Eazydms.com uses the remarketing services to advertise on third
                party websites (including Google) to previous visitors to our
                site. It could mean that we advertise to previous visitors who
                haven't completed a task on our site, for example using the
                contact form to make an enquiry. This could be in the form of an
                advertisement on the Google search results page, or a site in
                the Google Display Network. Third-party vendors, including
                Google, use cookies to serve ads based on someone's past visits.
                Of course, any data collected will be used in accordance with
                our own privacy policy and Google's privacy policy. You can set
                preferences for how Google advertises to you using the Google Ad
                Preferences page, and if you want to you can opt out of
                interest-based advertising entirely by cookie settings or
                permanently using a browser plugin.
                <br />
                <br />
                <h4 style={{ color: "black" }}>
                  Protection of Certain Personally-Identifying Information
                </h4>
                Eazy ERP Technologies Pvt. Ltd. discloses potentially
                personally-identifying and personally-identifying information
                only to those of its employees, contractors and affiliated
                organizations that (i) need to know that information in order to
                process it on Eazy ERP Technologies Pvt. Ltd.'s behalf or to
                provide services available at Eazy ERP Technologies Pvt. Ltd.'s
                website, and (ii) that have agreed not to disclose it to others.
                Some of those employees, contractors and affiliated
                organizations may be located outside of your home country; by
                using Eazy ERP Technologies Pvt. Ltd.'s website, you consent to
                the transfer of such information to them. Eazy ERP Technologies
                Pvt. Ltd. will not rent or sell potentially
                personally-identifying and personally-identifying information to
                anyone. Other than to its employees, contractors and affiliated
                organizations, as described above, Eazy ERP Technologies Pvt.
                Ltd. discloses potentially personally-identifying and
                personally-identifying information only in response to a
                subpoena, court order or other governmental request, or when
                Eazy ERP Technologies Pvt. Ltd. believes in good faith that
                disclosure is reasonably necessary to protect the property or
                rights of Eazy ERP Technologies Pvt. Ltd., third parties or the
                public at large. If you are a registered user of
                http://eazydms.com and have supplied your email address, Eazy
                ERP Technologies Pvt. Ltd. may occasionally send you an email to
                tell you about new features, solicit your feedback, or just keep
                you up to date with what's going on with Eazy ERP Technologies
                Pvt. Ltd. and our products. We primarily use our blog to
                communicate this type of information, so we expect to keep this
                type of email to a minimum. If you send us a request (for
                example via a support email or via one of our feedback
                mechanisms), we reserve the right to publish it in order to help
                us clarify or respond to your request or to help us support
                other users. Eazy ERP Technologies Pvt. Ltd. takes all measures
                reasonably necessary to protect against the unauthorized access,
                use, alteration or destruction of potentially
                personally-identifying and personally-identifying information.
                <br />
                <br />
                <h4 style={{ color: "black" }}>Aggregated Statistics</h4>
                Eazy ERP Technologies Pvt. Ltd. may collect statistics about the
                behavior of visitors to its website. Eazy ERP Technologies Pvt.
                Ltd. may display this information publicly or provide it to
                others. However, Eazy ERP Technologies Pvt. Ltd. does not
                disclose your personally-identifying information.
                <br />
                <br />
                <h4 style={{ color: "black" }}>Cookies</h4>
                To enrich and perfect your online experience, Eazy ERP
                Technologies Pvt. Ltd. uses "Cookies", similar technologies and
                services provided by others to display personalized content,
                appropriate advertising and store your preferences on your
                computer. A cookie is a string of information that a website
                stores on a visitor's computer, and that the visitor's browser
                provides to the website each time the visitor returns. Eazy ERP
                Technologies Pvt. Ltd. uses cookies to help Eazy ERP
                Technologies Pvt. Ltd. identify and track visitors, their usage
                of http://eazydms.com, and their website access preferences.
                Eazy ERP Technologies Pvt. Ltd. visitors who do not wish to have
                cookies placed on their computers should set their browsers to
                refuse cookies before using Eazy ERP Technologies Pvt. Ltd.'s
                websites, with the drawback that certain features of Eazy ERP
                Technologies Pvt. Ltd.'s websites may not function properly
                without the aid of cookies. By continuing to navigate our
                website without changing your cookie settings, you hereby
                acknowledge and agree to Eazy ERP Technologies Pvt. Ltd.'s use
                of cookies.
                <br />
                <br />
                <h4 style={{ color: "black" }}>Privacy Policy Changes</h4>
                Although most changes are likely to be minor, Eazy ERP
                Technologies Pvt. Ltd. may change its Privacy Policy from time
                to time, and in Eazy ERP Technologies Pvt. Ltd.'s sole
                discretion. Eazy ERP Technologies Pvt. Ltd. encourages visitors
                to frequently check this page for any changes to its Privacy
                Policy. Your continued use of this site after any change in this
                Privacy Policy will constitute your acceptance of such change.
                <br />
                <br />
                Privacy Policy: In case of any concerns please mail us at
                contact@eazyerp.com. You may also contact us at number mentioned
                below:
                <br />
                <br />
                Abhilasha Sharma,
                <br />
                0124-4794479
                <br />
                Unit No. 219-220, 2nd Floor
                <br />
                Vipul Agora Building
                <br />
                Gurugram, haryana
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
