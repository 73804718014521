import React from "react";


import Section from "../../HOC/Section";
import GreatFacilities from "./GreatFaclities";
const ClientSection = () => {
  return (
    <>

      <div
        className="welcome-area area-padding"
        style={{
          // backgroundImage: "linear-gradient(to bottom,#025282, #05273a66)",
          // backgroundSize: "100% 30%",
          // backgroundRepeat: "no-repeat",
          // background:"#D4EFFF",
        }}
      >
        <div className="container">
          <div className="row mb-4 justify-content-center">
            <div className="col-md-8 col-12">
              <div className="center-headline text-center">
                <h3 style={{ color: "#025282" }}>
                  Transform Your Business with Fusion EAZY DMS
                </h3>

                <h6 style={{ color: "#025282" }}>
                  Utilize the power of Tally through amalgamation of technology
                </h6>


                <div
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, transparent, rgb(2, 82, 130), transparent)",
                    height: "1px",
                    marginTop: "20px"
                  }}
                ></div>
              </div>
            </div>
          </div>

          <div className="row">

            <div className="col-lg-3 col-md-4 col-6">
              <div className="well-services">
                <div className="well-icon-main">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/feature/master.png"
                      alt="Stock and Sales Visibility icon"
                    />
                  </div>
                </div>
                <div className="well-content">
                  <h4>Stock & Sales Visibility</h4>
                  <p>
                    Seamless Analysis of Stock & Secondary Sales – Product,
                    Distributor,Salesforce Wise.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-6">
              <div className="well-services">

                <div className="well-icon-main">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.ap-south-1.amazonaws.com/eazydms/img/feature/inventory.png"
                      alt="Multi brand invoicing icon"
                    />
                  </div>
                </div>
                <div className="well-content">
                  <h4>Multi- brand Invoicing </h4>
                  <p>
                    Import of data from Multi brand distributors via SKU
                    Mapping ensures credibility.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-6">
              <div className="well-services ">
                <div className="well-icon-main">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.ap-south-1.amazonaws.com/eazydms/img/feature/channel.png"
                      alt="Complete master and price control icon"
                    />
                  </div>
                </div>
                <div className="well-content">
                  <h4>Complete Master & Price Control</h4>
                  <p>
                    Full control over distributor’s MRP, Back date entries,
                    Negative Stocks, Reorder levels.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-6">
              <div className="well-services ">
                <div className="well-icon-main">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.ap-south-1.amazonaws.com/eazydms/img/feature/purchase-order.png"
                      alt="Replenishment and order management icon"
                    />
                  </div>
                </div>
                <div className="well-content">
                  <h4>Replenishment & Order Management</h4>
                  <p>
                    Full control over Negative Stock, Back date entry, Zero
                    Stock Item, Reorder Level.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-6">
              <div className="well-services">
                <div className="well-icon-main">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.ap-south-1.amazonaws.com/eazydms/img/feature/claim.png"
                      alt="Scheme Management icon"
                    />
                  </div>
                </div>
                <div className="well-content">
                  <h4>Scheme Claim Management</h4>
                  <p>
                    Analyze real-time scheme achievements and manage
                    distributor’s claims effectively.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-6">
              <div className="well-services ">
                <div className="well-icon-main">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.ap-south-1.amazonaws.com/eazydms/img/feature/return.png"
                      alt="Optional Distributor Portal icon"
                    />
                  </div>
                </div>
                <div className="well-content">
                  <h4>Distributor Portal </h4>
                  <p>
                    Facility to import/export data at the distributor’s end.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-6">
              <div className="well-services ">
                <div className="well-icon-main">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.ap-south-1.amazonaws.com/eazydms/img/feature/Distributors.png"
                      alt="Integrated Sales Force Automation icon"
                    />
                  </div>
                </div>
                <div className="well-content">
                  <h4>Sales Force Automation</h4>
                  <p>
                    Effectively monitor sales staff performance at all
                    hierarchy levels through mobile SFA app.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-6">
              <div className="well-services ">
                <div className="well-icon-main">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.ap-south-1.amazonaws.com/eazydms/img/feature/benefit.png"
                      alt="Rich Data Analytics icon"
                    />
                  </div>
                </div>
                <div className="well-content">
                  <h4>Rich Data Analytics</h4>
                  <p>
                    Graphical Dashboards & comprehensive reports for Stock,
                    Sale, Purchase for management.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-6">
              <div className="well-services ">
                <div className="well-icon-main">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.ap-south-1.amazonaws.com/eazydms/img/feature/retaileazy.png"
                      alt="Retailer’s Self Ordering App"
                    />
                  </div>
                </div>
                <div className="well-content">
                  <h4>Retail EAZY </h4>
                  <p>
                    Retailer’s Self Ordering App Eliminates the hassle of
                    using multiple apps for Multi Brands
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-6">
              <div className="well-services ">
                <div className="well-icon-main">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.ap-south-1.amazonaws.com/eazydms/img/feature/Loyalty.png"
                      alt="multiple loyalty programsn"
                    />
                  </div>
                </div>
                <div className="well-content">
                  <h4>Loyalty Management </h4>
                  <p>
                    Leverage your Channel with end to end retailer &
                    influencer Loyalty Solutions
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-6">
              <div className="well-services ">
                <div className="well-icon-main">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.ap-south-1.amazonaws.com/eazydms/img/feature/financing.png"
                      alt="attractive ROI"
                    />
                  </div>
                </div>
                <div className="well-content">
                  <h4>Channel Financing </h4>
                  <p>
                    Empower your distributor/dealers at very attractive ROI
                    without affecting their working capital eligibility
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-6">
              <div className="well-services ">
                <div className="well-icon-main">
                  <div className="well-icon">
                    <img
                      src="https://eazysites.s3.ap-south-1.amazonaws.com/eazydms/img/feature/Integratederp.png"
                      alt="DMS integrated with any ERP"
                    />
                  </div>
                </div>
                <div className="well-content">
                  <h4>Integrate with ERP </h4>
                  <p>Get your DMS integrated with any ERP</p>
                </div>
              </div>
            </div>

          </div>

          
        </div>
      </div>


      <Section id="quicklinksection" className="pt-0">
        <div className="container-fluid pb-5 px-lg-5">
          <div className="section-content">
            <div className="row d-flex justify-content-center">
              <div className="col-md-9">
                <div className="center-headline text-center">
                  <h3

                    className="py-3"
                  >
                    Trusted By The Best


                  </h3>
                  <div
                    style={{
                      backgroundImage:
                        "linear-gradient(to right, transparent, rgb(2, 82, 130), transparent)",
                      height: "1px",

                    }}
                  ></div>

                  {/* <p className="py-2">
                    Top FMCG, FMCD & Auto Components manufacturers choose EAZY
                    DMS to achieve greater visibility without the hassle of
                    changing systems at the distributor's end.{" "}
                  </p> */}
                </div>
              </div>
            </div>

            <div className="pos_rel index_client mt-4 row">

              <div class="col-lg-2 col-md-3 col-3">
                <div className="client_images">
                  <img
                    src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/pillsbury_new.jpg"
                    class="img-reponsive"
                    alt="Eazy ERP, ERP for Paint Industry"
                  />
                </div>
              </div>
              <div class="col-lg-2 col-md-3 col-3">
                <div className="client_images">
                  <img
                    src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/greenply_new.png"
                    class="img-reponsive"
                    alt="Eazy DMS, DMS for FMCD"
                  />
                </div>
              </div>
              <div class="col-lg-2 col-md-3 col-3">
                <div className="client_images">
                  <img
                    class="long_img"
                    src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/bharat_petroleum_new.jpg"

                    alt="Eazy DMS, DMS for FMCG"
                  />
                </div>
              </div>
              <div class="col-lg-2 col-md-3 col-3">
                <div className="client_images">
                  <img
                    src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/cello_new.jpg"
                    class="long_img"
                    alt="Eazy DMS, DMS for FMCD"
                  />
                </div>
              </div>
              <div class="col-lg-2 col-md-3 col-3">
                <div className="client_images">
                  <img
                    src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/crompton_new.jpg"
                    class="img-reponsive"
                    alt="Eazy DMS, DMS for FMCG"
                  />
                </div>
              </div>

              <div class="col-lg-2 col-md-3 col-3">
                <div className="client_images">
                  <img
                    src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/doms_new.jpg"
                    class="img-reponsive"
                    alt="Eazy ERP, ERP for Food & Spices"
                  />
                </div>
              </div>
              <div class="col-lg-2 col-md-3 col-3">
                <div className="client_images">
                  <img
                    src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/hindware_new.png"
                    class="img-reponsive"
                    alt="Eazy ERP, ERP for manufacturing"
                  />
                </div>
              </div>
              <div class="col-lg-2 col-md-3 col-3">
                <div className="client_images">
                  <img
                    src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/mamaearth_new.png"
                    class="img-reponsive"
                    alt="Eazy ERP, ERP for food industry"
                  />
                </div>
              </div>
              <div class="col-lg-2 col-md-3 col-3">
                <div className="client_images">
                  <img
                    src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/mdh_new.png"
                    class="img-reponsive"
                    alt="Eazy ERP, ERP for FMCG Industry"
                  />
                </div>
              </div>
              <div class="col-lg-2 col-md-3 col-3">
                <div className="client_images">
                  <img
                    src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/prince_new.jpg"
                    class="img-reponsive"
                    alt="Eazy ERP, ERP for food Processing Industry"
                  />
                </div>
              </div>
              <div class="col-lg-2 col-md-3 col-3">
                <div className="client_images">
                  <img
                    class="img-reponsive"
                    src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/signify_new.png"

                    alt="Eazy ERP, ERP for Automotive Industry"
                  />
                </div>
              </div>
              <div class="col-lg-2 col-md-3 col-3">
                <div className="client_images">
                  <img
                    src="https://eazysites.s3.amazonaws.com/eazydms/img/brand/tafe_new.jpg"
                    class="img-reponsive"
                    alt="Eazy ERP, ERP for Pumps & Valves"
                  />
                </div>
              </div>



              {/* <GreatFacilities /> */}
            </div>
          </div>
        </div>
      </Section>


      <div
        className="about-area welcome-area area-padding "
      //   style="padding-bottom:0px"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-12">
              <div className="center-headline text-center">
                <h3 style={{ color: "#025282" }}>
                  Why Fusion DMS?
                </h3>

                {/* <h6 style={{ color: "#025282" }}>
                Why Fusion DMS?
                </h6> */}


                <div
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, transparent, rgb(2, 82, 130), transparent)",
                    height: "1px",
                    marginTop: "20px"
                  }}
                ></div>


              </div>
            </div>
          </div>


          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 ">
              <div className="about-content">
                <p className="text-center">
                  Unlike the others, Fusion EAZY-DMS not only connects, infact
                  integrates with your Distributor’s Tally & Busy by providing
                  you complete control over the distributor's billing Software
                  with the same flavor of Portal based DMS yet enabling your
                  distributors/dealers to work offline. Being a web Based DMS
                  (Distributor Management Software) It provides you complete
                  visibility of Secondary Sales, Stock & Schemes with an
                  advanced approach with the feasibility of multi-brand billing
                  & offline working environment, For Rural Distributor have the
                  feasibility of Lite Mobile Based DMS with keeping the
                  philosophy of multi-brand & Offline DMS along with a lot of
                  features to enable distributors & dealers.
                </p>

                <div className="about-details text-center">
                  <div className="single-about">
                    <div className="icon-title">
                      <h5> </h5>
                    </div>
                  </div>
                  <div className="single-about">
                    <div className="icon-title">
                      <h5> </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientSection;
