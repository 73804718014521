import { Link } from "react-router-dom";

export const CaseStudyBody = () => {
  return (
    <>
      <div class="area-padding bg-color">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-sm-12 col-xs-12 ">
              <div class="company-case">
                <div class="case-study-image">
                  <img
                    src="https://eazysites.s3.amazonaws.com/eazydms/img/good-year.jpg"
                    style={{ display: "inline" }}
                    alt="GoodYear client logo"
                  />
                </div>
                <div class="case-study-desc">
                  Goodyear leverages Eazy DMS for Collecting Secondary Sales
                  Data!
                </div>
                <Link
                  class="prod-read"
                  target="new"
                  to="/CaseStudyGoodYear"
                  style={{ textDecoration: "none" }}
                >
                  View Case Study
                </Link>
              </div>
            </div>

            <div class="col-md-4 col-sm-12 col-xs-12 ">
              <div class="company-case">
                <div class="case-study-image">
                  <img
                    src="https://eazysites.s3.ap-south-1.amazonaws.com/eazydms/img/cromptionlogo.jpg"
                    style={{ display: "inline" }}
                    alt="Crompton client logo"
                  />
                </div>
                <div class="case-study-desc">
                  Crompton gears up for its secondary sales data collection with
                  EAZY DMS!
                </div>
                <Link
                  class="prod-read"
                  target="new"
                  to="/CaseStudyCrompton"
                  style={{ textDecoration: "none" }}
                >
                  View Case Study
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
