import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { RecentPost } from "../recentPost";
import { Topbar } from "../../Topbar";
import AfterTopBar from "../../AfterTopbar/AfterTopbar";
import Footer from "../../Sections/footer";

export const BlogOnSiteDistributor = () => {
  return (
    <>
      <div className="main_header">
        <Topbar />
        <AfterTopBar />
      </div>
      <div class="page-area4">
        <div class="breadcumb-overlay"></div>
        <div class="container">
          <div class="row text-center">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="breadcrumb">
                <div class="section-headline white-headline text-center">
                  <h4
                    style={{
                      fontSize: "48px",
                      letterSpacing: "2px",
                      lineHeight: "48px",
                      color: "#fff",
                      display: "inline-block",
                      maxWidth: "100%",
                    }}
                  >
                    BENEFITS OF AN ONSITE DISTRIBUTOR ROLLOUT IN DMS
                  </h4>
                </div>
                <ul>
                  <li className="home-bread">
                    Home
                    <span style={{ marginLeft: "7px" }}></span>
                    <FontAwesomeIcon icon={faAngleRight} />
                    <span style={{ marginLeft: "7px" }}></span> Blog
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="blog-page-area area-padding">
        <div class="container" style={{ color: "#6c757d" }}>
          <div class="blog-details">
            <div class="row">
              <div class="col-md-8 col-sm-8 col-xs-12">
                <article class="blog-post-wrapper">
                  <div class="blog-banner">
                    <a href="#" class="blog-images">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/blog/benefits-onsite.jpg"
                        alt="Benefits of onsite DMS rollout image"
                      />
                    </a>
                    <div class="blog-content">
                      <br />
                      <p>
                        Implementing a Distributor Management System is not just
                        limited to product installation or hosting at the parent
                        company. It involves rollouts and training to the vastly
                        spread distributor network as well. Whether you have
                        opted for a traditional DMS, portal-based DMS or a Tally
                        integrated DMS such as EAZY DMS, distributor rollouts
                        play a major role in the successful implementation. It
                        requires hand-holding to cater to the day-to-day queries
                        of the Distributor/Dealer on how to use the system
                        efficiently.
                      </p>
                      <p>
                        At EAZY DMS, our expertise lies not just in our product,
                        but in the way, the distributor rollouts are handled on
                        PAN India basis. We are partnered with an extensive
                        network of Tally & Busy Partners across the country that
                        physically visits the distributors and trains them on
                        how to use the solution efficiently. Due to this
                        expertise, we can easily achieve close to 100 rollouts
                        in a month. At EAZY DMS, we lay stress on onsite
                        rollouts of DMS at the distributor level as compared to
                        online training keeping in consideration the limited
                        connectivity, infrastructure at the distributor's end
                      </p>

                      <p
                        style={{
                          fontSize: "15pt",
                          fontWeight: "600",
                          color: "black",
                        }}
                      >
                        ADVANTAGES OF ONSITE ROLLOUTS vs. ONLINE TRAININGS
                      </p>

                      <p
                        style={{
                          paddingLeft: "50px",
                        }}
                      >
                        <b style={{ color: "black" }}>
                          1. Product Testing & Training:{" "}
                        </b>
                        Once the Distributor Management System has been linked
                        with the distributor's existing accounting system {"("}
                        Tally or Busy{")"}, the work has just begun. Now comes
                        the product testing phase, followed by the Distributor's
                        staff training so that all know how to utilize the new
                        product. An on-site implementation consultant is present
                        to both test the system and offers hands-on training
                        sessions to all the staff. This takes the worry and
                        guesswork out of using the new DMS so your company can
                        get more out of this investment.
                      </p>

                      <p
                        style={{
                          paddingLeft: "50px",
                        }}
                      >
                        <b style={{ color: "black" }}>2. Local Presence: </b>
                        Since EAZY DMS partners with regional Tally & Busy
                        partners spread across India, all our distributor
                        rollouts are handled by them. Our local partners
                        experience less resistance during rollouts from the
                        distributors as they are already associated with them in
                        the past, can speak the local language and understand
                        their pain areas in a better manner.
                      </p>
                      <p
                        style={{
                          paddingLeft: "50px",
                        }}
                      >
                        <b style={{ color: "black" }}>3. Local Support: </b>It
                        is much easier to resolve issues as and when they arise
                        through local Tally / Busy Partners as they have access
                        to the right people at the right time. Our local
                        partners can visit the distributors, provide trainings
                        or solve issues without an added cost to the company.
                        Not only does this help to reduce the impact of those
                        practical problems that arise at the start of every
                        rollout, it lasts throughout the project, allowing the
                        team and the distributors to work together.
                      </p>
                      <p
                        style={{
                          paddingLeft: "50px",
                        }}
                      >
                        <b style={{ color: "black" }}>4. On-Time Delivery: </b>
                        Out of sight is definitely out of mind. In an online
                        training, the immediacy and urgency of the project tends
                        to slip from the mind of the distributors. The
                        priorities of their day-jobs take over and the
                        collective time that the distributors dedicate to the
                        project starts to wane. Key decisions get delayed and
                        key questions go unanswered for days, or even weeks, at
                        a time and without them you can start to see timeline
                        slippage.
                      </p>
                      <p
                        style={{
                          paddingLeft: "50px",
                        }}
                      >
                        <b style={{ color: "black" }}>
                          5. Strong collaboration:{" "}
                        </b>{" "}
                        The physical divide between the project team and the
                        distributors apparent in an online training manifests a
                        psychological divide as it stands in the way of
                        developing trusted relationships. However, during an
                        onsite rollout, a "one team" approach works as the local
                        partners spend their time on-site, allowing them to
                        embed themselves within the distributor's business.
                      </p>
                      <p
                        style={{
                          paddingLeft: "50px",
                        }}
                      >
                        <b style={{ color: "black" }}>
                          6. Reduced boarding & lodging cost:{" "}
                        </b>{" "}
                        The increased expense and time required to have a
                        project team based on-site is considerable and this
                        alone is enough to deter some businesses from running
                        onsite implementations. As EAZY DMS leverages the vast
                        network of Tally & Busy partners, we are able to achieve
                        onsite rollouts at a very nominal cost.
                      </p>
                      <p
                        style={{
                          paddingLeft: "50px",
                        }}
                      >
                        <b style={{ color: "black" }}>
                          7. Reduced Time to Implement:{" "}
                        </b>{" "}
                        While some of the aspects of a distributor rollout can
                        be managed remotely, it can take weeks and even months
                        if there is a large amount of data to manage, this can
                        be reduced simply by having an on-site implementation
                        consultant to handle the tasks at the distributor end,
                        working directly with key team members to accomplish
                        more in less time. Problems and questions can be ironed
                        out immediately.
                      </p>

                      <p>
                        The benefits of onsite rollouts delivery easily outweigh
                        the risks involved. When opting for a new DMS, remember
                        to ask your solution provider about on-site distributor
                        rollouts as opposed to online training services so you
                        can get the most out of your distributor management
                        system with least resistance from your distributors.
                      </p>
                      <p></p>
                    </div>
                  </div>
                </article>
                <div class="clear"></div>
                <div class="single-post-comments"></div>
              </div>
              <div class="col-md-4">
                <RecentPost />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
