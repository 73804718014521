import React from "react";
import { Topbar } from "../Topbar";
import AfterTopBar from "../AfterTopbar/AfterTopbar";
import Footer from "../Sections/footer";

export const CaseStudyCrompton = () => {
  return (
    <>
      <div className="main_header">
        <Topbar />
        <AfterTopBar />
      </div>
      <div class="page-area1">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="breadcrumb text-center">
                <div class="case-cover">
                  <div class="description">
                    Goodyear leverages Eazy DMS for Automating Secondary Sales
                    data collection!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="area-padding bg-color">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-sm-12 col-xs-12 ">
              <div style={{ width: "100%", marginTop: "40px" }}></div>
              <div class="case-study-image">
                <img
                  src="https://eazysites.s3.ap-south-1.amazonaws.com/eazydms/img/cromptionlogo.jpg"
                  style={{ display: "inline" }}
                  alt="Crompton"
                />
              </div>
              <br />
              This case study highlights:
              <br />
              <br />
              <ul>
                <li>
                  {" "}
                  <span style={{ color: "#d40000", fontWeight: "bold" }}>
                    {" "}
                    -{" "}
                  </span>
                  Challenges faced by Crompton in tracking secondary sales
                </li>

                <li>
                  {" "}
                  <span style={{ color: "#d40000", fontWeight: "bold" }}>
                    {" "}
                    -{" "}
                  </span>
                  Why Crompton decided to replace its inhouse DMS?
                </li>
                <li>
                  {" "}
                  <span style={{ color: "#d40000", fontWeight: "bold" }}>
                    {" "}
                    -{" "}
                  </span>
                  How it benefitted from adopting EAZY DMS?
                </li>
              </ul>
              <br />
              <div class="case-words">
                “Eazy DMS is a robust & effective Distributor Management
                Software that has helped our team in garnering real-time sales
                data for timely decision making from our distributors across
                India which was not possible with our in-house developed DMS.
                Data accuracy has improved tremendously enabling us in better
                decision making.“
                <div class="sub-words">
                  -Onkar Burnwal, Senior Manager, Crompton
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12 ">
              <div
                style={{
                  WebkitBoxShadow: "0px 3px 26px -8px rgba(0,0,0,0.75)",
                  MozBoxShadow: "0px 3px 26px -8px rgba(0,0,0,0.75)",
                  boxShadow: "0px 3px 26px -8px rgba(0,0,0,0.75)",
                }}
                class="wrap-contact100"
              >
                <div
                  class="contact100-form-title"
                  style={{ background: "#024482" }}
                >
                  <span>Download The Case Study</span>
                </div>

                <form
                  style={{ marginTop: "15px" }}
                  class="contact100-form validate-form"
                >
                  <input
                    id="enqtype"
                    type="hidden"
                    name="enqtype"
                    value="CreateLead"
                  />
                  <div class="wrap-input100 validate-input">
                    <input
                      id="name"
                      class="input100"
                      type="text"
                      name="name"
                      placeholder="Full name"
                      onblur="blanckcheck('name','error_name')"
                      autocomplete="off"
                    />

                    <span class="focus-input100"></span>
                    <label class="label-input100" for="name">
                      <span class="lnr lnr-user m-b-2"></span>
                    </label>
                  </div>
                  <div class="row err" id="error_name"></div>

                  <div class="wrap-input100 validate-input">
                    <input
                      id="email"
                      class="input100"
                      type="text"
                      name="email"
                      placeholder="Eg. example@email.com"
                      onblur="mailchk('email','error_email')"
                      autocomplete="off"
                    />
                    <span class="focus-input100"></span>
                    <label class="label-input100" for="email">
                      <span class="lnr lnr-envelope m-b-5"></span>
                    </label>
                  </div>
                  <div class="row err" id="error_email"></div>

                  <div class="wrap-input100 validate-input">
                    <input
                      id="phone"
                      class="input100"
                      type="text"
                      name="phone"
                      placeholder="Eg. 9891989198"
                      onblur="numchk('phone','error_phone')"
                      autocomplete="off"
                    />
                    <span class="focus-input100"></span>
                    <label class="label-input100" for="phone">
                      <span class="lnr lnr-smartphone m-b-2"></span>
                    </label>
                  </div>
                  <div class="row err" id="error_phone"></div>

                  <div class="wrap-input100 validate-input">
                    <input
                      id="company"
                      class="input100"
                      type="text"
                      name="company"
                      placeholder="Eg. ABC Pvt. Ltd."
                      onblur="blanckcheck('company','error_company')"
                      autocomplete="off"
                    />
                    <input
                      id="case_study_company"
                      class="input100"
                      type="hidden"
                      name="case_study_company"
                      value="Goodyear DMS"
                    />
                    <span class="focus-input100"></span>
                    <label class="label-input100" for="phone">
                      <span class="lnr lnr-apartment m-b-2"></span>
                    </label>
                  </div>
                  <div class="row err" id="error_company"></div>

                  <div class="wrap-input100 validate-input">
                    <select
                      class="input100"
                      style={{ color: "#777" }}
                      id="place"
                      name="place"
                      onblur="blanckcheck('place','error_place')"
                    >
                      <option
                        value=""
                        label="Select a City"
                        selected="selected"
                      >
                        Select a City
                      </option>
                      <optgroup
                        id="city-optgroup-metropolitan"
                        label="Top-Metropolitan "
                      >
                        <option value="Ahmedabad">Ahmedabad</option>
                        <option value="Bangalore">Bengaluru/Bangalore</option>
                        <option value="Chandigarh">Chandigarh</option>
                        <option value="Chennai">Chennai</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Gurgaon">Gurgaon</option>
                        <option value="Hyderabad">
                          Hyderabad/Secunderabad
                        </option>
                        <option value="Kolkata">Kolkata</option>
                        <option value="Mumbai">Mumbai</option>
                        <option value="Noida">Noida</option>
                        <option value="Pune">Pune</option>
                      </optgroup>
                      <optgroup id="city-optgroup-ap" label="Andhra Pradesh">
                        <option value="Anantapur">Anantapur</option>
                        <option value="Guntakal">Guntakal</option>
                        <option value="Guntur">Guntur</option>
                        <option value="Hyderabad">
                          Hyderabad/Secunderabad
                        </option>
                        <option value="Kakinada">Kakinada</option>
                        <option value="Kurnool">Kurnool</option>
                        <option value="Nellore">Nellore</option>
                        <option value="Nizamabad">Nizamabad</option>
                        <option value="Rajahmundry">Rajahmundry</option>
                        <option value="Tirupati">Tirupati</option>
                        <option value="Vijayawada">Vijayawada</option>
                        <option value="Visakhapatnam">Visakhapatnam</option>
                        <option value="Warangal">Warangal</option>
                      </optgroup>
                      <optgroup
                        id="city-optgroup-ArunachalPradesh"
                        label="Arunachal Pradesh "
                      >
                        <option value="Itanagar">Itanagar</option>
                      </optgroup>
                      <optgroup id="city-optgroup-Assam" label="Assam">
                        <option value="Guwahati">Guwahati</option>
                        <option value="Silchar">Silchar</option>
                      </optgroup>
                      <optgroup id="city-optgroup-Bihar" label="Bihar">
                        <option value="Bhagalpur">Bhagalpur</option>
                        <option value="Patna">Patna</option>
                      </optgroup>
                      <optgroup
                        id="city-optgroup-chattisgarh"
                        label="chattisgarh"
                      >
                        <option value="Bhillai">Bhillai</option>
                        <option value="Bilaspur">Bilaspur</option>
                        <option value="Raipur">Raipur</option>
                      </optgroup>
                      <optgroup id="city-optgroup-goa" label="Goa">
                        <option value="Panaji">Panjim/Panaji</option>
                        <option value="Vasco Da Gama">Vasco Da Gama</option>
                      </optgroup>

                      <optgroup id="city-optgroup-gujrat" label="gujrat">
                        <option value="Ahmedabad">Ahmedabad</option>
                        <option value="Anand">Anand</option>
                        <option value="Ankleshwar">Ankleshwar</option>
                        <option value="Bharuch">Bharuch</option>
                        <option value="Bhavnagar">Bhavnagar</option>
                        <option value="Bhuj">Bhuj</option>
                        <option value="Gandhinagar">Gandhinagar</option>
                        <option value="Gir">Gir</option>
                        <option value="Jamnagar">Jamnagar</option>
                        <option value="Kandla">Kandla</option>
                        <option value="Porbandar">Porbandar</option>
                        <option value="Rajkot">Rajkot</option>
                        <option value="Surat">Surat</option>
                        <option value="Vadodara">Vadodara/Baroda</option>
                        <option value="Valsad">Valsad</option>
                        <option value="Vapi">Vapi</option>
                      </optgroup>
                      <optgroup id="city-optgroup-haryana" label="haryana">
                        <option value="Ambala">Ambala</option>
                        <option value="Chandigarh">Chandigarh</option>
                        <option value="Faridabad">Faridabad</option>
                        <option value="Gurgaon">Gurgaon</option>
                        <option value="Hisar">Hisar</option>
                        <option value="Karnal">Karnal</option>
                        <option value="Kurukshetra">Kurukshetra</option>
                        <option value="Panipat">Panipat</option>
                        <option value="Rohtak">Rohtak</option>
                      </optgroup>

                      <optgroup
                        id="city-optgroup-HimachalPradesh"
                        label="Himachal Pradesh"
                      >
                        <option value="Dalhousie">Dalhousie</option>
                        <option value="Dharmasala">Dharmasala</option>
                        <option value="Manali">Kulu/Manali</option>
                        <option value="Shimla">Shimla</option>
                      </optgroup>
                      <optgroup id="city-optgroup-JK" label="Jammu and Kashmir">
                        <option value="Jammu">Jammu</option>
                        <option value="Srinagar">Srinagar</option>
                      </optgroup>
                      <optgroup id="city-optgroup-Jharkhand" label="Jharkhand">
                        <option value="Bokaro">Bokaro</option>
                        <option value="Dhanbad">Dhanbad</option>
                        <option value="Jamshedpur">Jamshedpur</option>
                        <option value="Ranchi">Ranchi</option>
                      </optgroup>
                      <optgroup id="city-optgroup-Karnataka" label="Karnataka">
                        <option value="Bangalore">Bengaluru/Bangalore</option>
                        <option value="Belgaum">Belgaum</option>
                        <option value="Bellary">Bellary</option>
                        <option value="Bidar">Bidar</option>
                        <option value="Dharwad">Dharwad</option>
                        <option value="Gulbarga">Gulbarga</option>
                        <option value="Hubli">Hubli</option>
                        <option value="Kolar">Kolar</option>
                        <option value="Mangalore">Mangalore</option>
                        <option value="Mysore">Mysoru/Mysore</option>
                      </optgroup>
                      <optgroup id="city-optgroup-Kerela" label="Kerela">
                        <option value="Calicut">Calicut</option>
                        <option value="Cochin">Cochin</option>
                        <option value="Ernakulam">Ernakulam</option>
                        <option value="Kannur">Kannur</option>
                        <option value="Kochi">Kochi</option>
                        <option value="Kollam">Kollam</option>
                        <option value="Kottayam">Kottayam</option>
                        <option value="Kozhikode">Kozhikode</option>
                        <option value="Palakkad">Palakkad</option>
                        <option value="Palghat">Palghat</option>
                        <option value="Thrissur">Thrissur</option>
                        <option value="Trivandrum">Trivandrum</option>
                      </optgroup>
                      <optgroup id="city-optgroup-MP" label="Madhya Pradesh">
                        <option value="Bhopal">Bhopal</option>
                        <option value="Gwalior">Gwalior</option>
                        <option value="Indore">Indore</option>
                        <option value="Jabalpur">Jabalpur</option>
                        <option value="Ujjain">Ujjain</option>
                      </optgroup>
                      <optgroup
                        id="city-optgroup-Maharashtra"
                        label="Maharashtra"
                      >
                        <option value="Ahmednagar">Ahmednagar</option>
                        <option value="Aurangabad">Aurangabad</option>
                        <option value="Jalgaon">Jalgaon</option>
                        <option value="Kolhapur">Kolhapur</option>
                        <option value="Mumbai">Mumbai</option>
                        <option value="Mumbai Suburbs">Mumbai Suburbs</option>
                        <option value="Nagpur">Nagpur</option>
                        <option value="Nasik">Nasik</option>
                        <option value="Navi Mumbai">Navi Mumbai</option>
                        <option value="Pune">Pune</option>
                        <option value="Solapur">Solapur</option>
                      </optgroup>
                      <optgroup id="city-optgroup-Manipur" label="Manipur">
                        <option value="Imphal">Imphal</option>
                      </optgroup>
                      <optgroup id="city-optgroup-Meghalaya" label="Meghalaya">
                        <option value="Shillong">Shillong</option>
                      </optgroup>
                      <optgroup id="city-optgroup-Mizoram" label="Mizoram">
                        <option value="Aizawal">Aizawal</option>
                      </optgroup>
                      <optgroup id="city-optgroup-Nagaland" label="Nagaland">
                        <option value="Dimapur">Dimapur</option>
                      </optgroup>
                      <optgroup id="city-optgroup-Orissa" label="Orissa">
                        <option value="Bhubaneshwar">Bhubaneshwar</option>
                        <option value="Cuttak">Cuttak</option>
                        <option value="Paradeep">Paradeep</option>
                        <option value="Puri">Puri</option>
                        <option value="Rourkela">Rourkela</option>
                      </optgroup>
                      <optgroup id="city-optgroup-Punjab" label="Punjab">
                        <option value="Amritsar">Amritsar</option>
                        <option value="Bathinda">Bathinda</option>
                        <option value="Chandigarh">Chandigarh</option>
                        <option value="Jalandhar">Jalandhar</option>
                        <option value="Ludhiana">Ludhiana</option>
                        <option value="Mohali">Mohali</option>
                        <option value="Pathankot">Pathankot</option>
                        <option value="Patiala">Patiala</option>
                      </optgroup>
                      <optgroup id="city-optgroup-Rajasthan" label="Rajasthan">
                        <option value="Ajmer">Ajmer</option>
                        <option value="Jaipur">Jaipur</option>
                        <option value="Jaisalmer">Jaisalmer</option>
                        <option value="Jodhpur">Jodhpur</option>
                        <option value="Kota">Kota</option>
                        <option value="Udaipur">Udaipur</option>
                      </optgroup>
                      <optgroup id="city-optgroup-Sikkim" label="Sikkim">
                        <option value="Gangtok">Gangtok</option>
                      </optgroup>
                      <optgroup id="city-optgroup-Tamil" label="Tamil Nadu">
                        <option value="Chennai">Chennai</option>
                        <option value="Coimbatore">Coimbatore</option>
                        <option value="Cuddalore">Cuddalore</option>
                        <option value="Erode">Erode</option>
                        <option value="Hosur">Hosur</option>
                        <option value="Madurai">Madurai</option>
                        <option value="Nagerkoil">Nagerkoil</option>
                        <option value="Ooty">Ooty</option>
                        <option value="Salem">Salem</option>
                        <option value="Thanjavur">Thanjavur</option>
                        <option value="Tirunalveli">Tirunalveli</option>
                        <option value="Trichy">Trichy</option>
                        <option value="Tuticorin">Tuticorin</option>
                        <option value="Vellore">Vellore</option>
                      </optgroup>
                      <optgroup id="city-optgroup-Tripura" label="Tripura">
                        <option value="Agartala">Agartala</option>
                      </optgroup>
                      <optgroup
                        id="city-optgroup-Pondichery"
                        label="Pondichery"
                      >
                        <option value="Chandigarh">Chandigarh</option>
                        <option value="Dadra & Nagar Haveli-Silvassa">
                          Dadra & Nagar Haveli-Silvassa
                        </option>
                        <option value="Daman & Diu">Daman & Diu</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Pondichery">Pondichery</option>
                      </optgroup>
                      <optgroup
                        id="city-optgroup-UttarPradesh"
                        label="Uttar Pradesh"
                      >
                        <option value="Agra">Agra</option>
                        <option value="Aligarh">Aligarh</option>
                        <option value="Allahabad">Allahabad</option>
                        <option value="Bareilly">Bareilly</option>
                        <option value="Faizabad">Faizabad</option>
                        <option value="Ghaziabad">Ghaziabad</option>
                        <option value="Gorakhpur">Gorakhpur</option>
                        <option value="Kanpur">Kanpur</option>
                        <option value="Lucknow">Lucknow</option>
                        <option value="Mathura">Mathura</option>
                        <option value="Meerut">Meerut</option>
                        <option value="Moradabad">Moradabad</option>
                        <option value="Noida">Noida</option>
                        <option value="Varanasi">Varanasi/Banaras</option>
                      </optgroup>
                      <optgroup
                        id="city-optgroup-Uttaranchal"
                        label="Uttaranchal"
                      >
                        <option value="Dehradun">Dehradun</option>
                        <option value="Roorkee">Roorkee</option>
                      </optgroup>

                      <optgroup
                        id="city-optgroup-WestBengal"
                        label="West Bengal"
                      >
                        <option value="Asansol">Asansol</option>
                        <option value="Durgapur">Durgapur</option>
                        <option value="Haldia">Haldia</option>
                        <option value="Kharagpur">Kharagpur</option>
                        <option value="Kolkatta">Kolkatta</option>
                        <option value="Siliguri">Siliguri</option>
                      </optgroup>
                      <optgroup id="city-optgroup-Other" label="Other">
                        <option value="Other">Other</option>
                      </optgroup>
                    </select>
                    <span class="focus-input100"></span>
                    <label class="label-input100" for="place">
                      <span class="lnr lnr-map-marker m-b-2"></span>
                    </label>
                  </div>
                  <div class="row err" id="error_place"></div>
                  <div
                    id="loading-image"
                    style={{ display: "none", left: "40%", top: "40%" }}
                  >
                    <img src="https://eazysites.s3.amazonaws.com/eazydms/img/loading_spinner.gif" />
                  </div>
                  <div class="container-contact100-form-btn">
                    <button class="contact100-form-btn">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
