import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faCircleCheck,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { Topbar } from "../Topbar";
import AfterTopBar from "../AfterTopbar/AfterTopbar";
import Footer from "./footer";

export const Dms = () => {
  const [activeTab, setActiveTab] = useState(0);

  const changeTab = (arg) => {
    if (arg === activeTab) {
      console.log("$H@nK@r");
      setActiveTab(0);
    } else {
      setActiveTab(arg);
    }
  };

  return (
    <>
      <div className="main_header">
        <Topbar />
        <AfterTopBar />
      </div>
      <div class="page-area6">
        <div class="container">
          <div class="row  text-center">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="breadcrumb">
                <div class="section-headline white-headline text-center">
                  <h4
                    style={{
                      fontSize: "48px",
                      letterSpacing: "2px",
                      lineHeight: "48px",
                      color: "#fff",
                      display: "inline-block",
                      maxWidth: "100%",
                    }}
                  >
                    TAP THE UNTAPPED MARKET <br />
                    WITH <br />
                    HYBRID Distributor Management System
                  </h4>
                </div>
                <ul>
                  <li className="home-bread">
                    Home
                    <span style={{ marginLeft: "7px" }}></span>
                    <FontAwesomeIcon icon={faAngleRight} />
                    <span style={{ marginLeft: "7px" }}></span> Eazy DMS
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="area-padding bg-color">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="section-headline text-center">
                <p style={{ maxWidth: "100%" }}>
                  EAZY-DMS (Distributor Management System), the only DMS which
                  comes with a hybrid approach providing the web based DMS yet
                  feels like a portal based DMS. It integrates with
                  Distributor’s Tally & Busy and provides complete visibility of
                  Secondary Sales and stock to manufacturers without the hassle
                  of changing systems at the distributor's end.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="area-padding">
        <div class="container">
          <div class="row">
            <div class="col-md-12 text-center">
              <h4
                style={{
                  fontSize: "30px",
                  lineHeight: "32px",
                  color: "#444",
                  textAlign: "center",
                  margin: "0 0 15px",
                  display: "block",
                  letterSpacing: "0px",
                }}
              >
                Current Challenges in Distributor Management
              </h4>

              <p>
                India with its large base-of-pyramid and rural markets present
                distinct challenges for distribution. Brands not only have to
                manage a vast network of big and small distributors spread out
                across the country but also need to juggle the conflicting needs
                of different channel partners.{" "}
              </p>
            </div>
            <div class="col-md-6">
              <div class="pain-area">
                <h4>
                  Standard Distributor Management <br />
                  Method
                </h4>
                <p>
                  Usually used by small organizations that have inadequate
                  capital, tech infrastructure and limited penetration of the
                  internet
                </p>
                <h5>Pain Areas:</h5>
                <ul class="gun">
                  <li>
                    {" "}
                    <span class="icon1">
                      <FontAwesomeIcon icon={faCircleCheck} />
                    </span>{" "}
                    Deciphering unstructured Data gathered through excel, emails
                    & SMS, Physical Reports, etc.
                  </li>
                  <li>
                    {" "}
                    <span class="icon1">
                      <FontAwesomeIcon icon={faCircleCheck} />
                    </span>
                    Lack of real-time & actionable Market Data for decision
                    making
                  </li>
                  <li>
                    {" "}
                    <span class="icon1">
                      <FontAwesomeIcon icon={faCircleCheck} />
                    </span>
                    Misappropriation of Scheme data by distributors{" "}
                  </li>
                  <li>
                    {" "}
                    <span class="icon1">
                      <FontAwesomeIcon icon={faCircleCheck} />
                    </span>
                    Lack of visibility of performing product & outlet at
                    granular level{" "}
                  </li>
                  <li>
                    {" "}
                    <span class="icon1">
                      <FontAwesomeIcon icon={faCircleCheck} />
                    </span>
                    Target vs Actual non alignment with overall Sales Plan
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-6">
              <div class="pain-area">
                <h4>Conventional Distributor Management System</h4>
                <p>
                  Distributors use a customized web portal for each brand and
                  maintain their data individually
                </p>
                <h5>Pain Areas:</h5>
                <ul class="gun">
                  <li>
                    {" "}
                    <span class="icon1">
                      <FontAwesomeIcon icon={faCircleCheck} />
                    </span>
                    Difficult to install portal based Distributor Management
                    System for distributors dealing with Multiple brands
                  </li>
                  <li>
                    {" "}
                    <span class="icon1">
                      <FontAwesomeIcon icon={faCircleCheck} />
                    </span>{" "}
                    Duplication of work for the Distributor
                  </li>
                  <li>
                    {" "}
                    <span class="icon1">
                      <FontAwesomeIcon icon={faCircleCheck} />
                    </span>{" "}
                    Fresh training required for Distributor
                  </li>
                  <li>
                    {" "}
                    <span class="icon1">
                      <FontAwesomeIcon icon={faCircleCheck} />
                    </span>
                    Huge Support & license cost
                  </li>
                  <li>
                    {" "}
                    <span class="icon1">
                      <FontAwesomeIcon icon={faCircleCheck} />
                    </span>
                    Increase in Manpower cost
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="area-padding  benifit-bg">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="center-headline text-center">
                <h4
                  style={{
                    color: "#fff",
                    fontSize: "34px",
                    letterSpacing: "0px",
                    lineHeight: "42px",
                    fontWeight: "700",
                  }}
                >
                  Eazy DMS with Hybrid Approach is here to rescue{" "}
                </h4>
              </div>
            </div>
          </div>
          <div class="well-inner">
            <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="well-content">
                  <ul class="gun1">
                    <li>
                      {" "}
                      <span class="icon1">
                        <FontAwesomeIcon icon={faCircleCheck} />
                      </span>
                      Multi-Brand Approach
                    </li>
                    <li>
                      {" "}
                      <span class="icon1">
                        <FontAwesomeIcon icon={faCircleCheck} />
                      </span>
                      Complete Sales Tracking Capability at Secondary level
                    </li>
                    <li>
                      {" "}
                      <span class="icon1">
                        <FontAwesomeIcon icon={faCircleCheck} />
                      </span>
                      Better product planning with actual sales insights
                    </li>
                    <li>
                      {" "}
                      <span class="icon1">
                        <FontAwesomeIcon icon={faCircleCheck} />
                      </span>{" "}
                      Reduced overall sales realization & processing time
                    </li>
                    <li>
                      {" "}
                      <span class="icon1">
                        <FontAwesomeIcon icon={faCircleCheck} />
                      </span>
                      Streamlined sales return and scheme settlements
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="well-content">
                  <ul class="gun1">
                    <li>
                      {" "}
                      <span class="icon1">
                        <FontAwesomeIcon icon={faCircleCheck} />
                      </span>
                      Offline Mode
                    </li>
                    <li>
                      {" "}
                      <span class="icon1">
                        <FontAwesomeIcon icon={faCircleCheck} />
                      </span>
                      Faster stock replenishment
                    </li>
                    <li>
                      {" "}
                      <span class="icon1">
                        <FontAwesomeIcon icon={faCircleCheck} />
                      </span>
                      Supervision of Distributor/Dealer
                    </li>
                    <li>
                      {" "}
                      <span class="icon1">
                        <FontAwesomeIcon icon={faCircleCheck} />
                      </span>{" "}
                      Minimal change management as distributors work on their
                      existing Tally
                    </li>
                    <li>
                      {" "}
                      <span class="icon1">
                        <FontAwesomeIcon icon={faCircleCheck} />
                      </span>{" "}
                      Seamless visibility of products movement across channels
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="area-padding">
        <div class="container">
          <div class="row">
            <div class="">
              <div class="row">
                <div class="col-md-6 accordion">
                  <div
                    className={`accordion__header ${
                      activeTab === 1 ? "is-active" : ""
                    }`}
                    onClick={() => changeTab(1)}
                  >
                    <h4 class="check-title">
                      <a
                        style={{ textDecoration: "none" }}
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#feature"
                      >
                        <span class="acc-icons"></span>
                        <img
                          src="https://eazysites.s3.amazonaws.com/eazydms/img/feature/Implementation.png"
                          alt="Complete Master & Price Control Icon"
                          width="40px"
                          style={{ marginRight: "10px" }}
                        />
                        Complete Master & Price Control
                        <p style={{ marginBottom: "0px", marginTop: "5px" }}>
                          Maintain uniform master data and streamline processes
                          across the entire supply chain
                        </p>
                      </a>
                    </h4>
                    <span class="accordion__toggle"></span>
                  </div>
                  <div
                    class="accordion__body"
                    style={{ display: activeTab === 1 ? "block" : "none" }}
                  >
                    <ul class="gun">
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Sales Tracking Capability at Primary and Secondary and
                        Tertiary level
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        SKU Mapping
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Complete control over distributor’s MRP, Item
                        Alteration, Ledger Alteration, Back date entries,
                        Negative Stocks, Reorder levels etc
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Export new prices to distributors centrally
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Store transfer (to & from Warehouse)
                      </li>
                    </ul>
                  </div>

                  <div
                    className={`accordion__header ${
                      activeTab === 2 ? "is-active" : ""
                    }`}
                    onClick={() => changeTab(2)}
                  >
                    <h4 class="check-title">
                      <a
                        style={{ textDecoration: "none" }}
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#feature3"
                      >
                        <span class="acc-icons"></span>
                        <img
                          src="https://eazysites.s3.amazonaws.com/eazydms/img/feature/Distributors.png"
                          alt="Inventory Management Icon"
                          width="38px"
                          style={{ marginRight: "10px" }}
                        />
                        Inventory Management
                        <p style={{ marginBottom: "0px", marginTop: "5px" }}>
                          Reduce out of stock situations, sales loss and holding
                          costs and improve supply chain efficiency
                        </p>
                      </a>
                    </h4>
                    <span class="accordion__toggle"></span>
                  </div>
                  <div
                    class="accordion__body"
                    style={{ display: activeTab === 2 ? "block" : "none" }}
                  >
                    <ul class="gun">
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Track every single movement of inventor supply chain
                        partners
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Visibility of the inventory across the network
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Inter distributor stock transfer
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Visibility of saleable and damaged stock
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Maintain stock in multiple locations across distribution
                        branches
                      </li>
                    </ul>
                  </div>

                  <div
                    className={`accordion__header ${
                      activeTab === 3 ? "is-active" : ""
                    }`}
                    onClick={() => changeTab(3)}
                  >
                    <h4 class="check-title">
                      <a
                        style={{ textDecoration: "none" }}
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#feature3"
                      >
                        <span class="acc-icons"></span>
                        <img
                          src="https://eazysites.s3.amazonaws.com/eazydms/img/feature/purchase-order.png"
                          alt="Purchase order Management Icon"
                          width="33px"
                          style={{ marginRight: "10px", height: "35px" }}
                        />
                        Purchase Order Management
                        <p style={{ marginBottom: "0px", marginTop: "5px" }}>
                          Enable flexibility in purchase order management to
                          better address market demand
                        </p>
                      </a>
                    </h4>
                    <span class="accordion__toggle"></span>
                  </div>
                  <div
                    class="accordion__body"
                    style={{ display: activeTab === 3 ? "block" : "none" }}
                  >
                    <ul class="gun">
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Completely automated through integration with ERP
                        systems
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Control replenishment based on inventory norms
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Visibility of inventory levels across the supply chain
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Tweak orders suggested by the system to order based on
                        market demand
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Re-order levels{" "}
                      </li>
                    </ul>
                  </div>

                  <div
                    className={`accordion__header ${
                      activeTab === 4 ? "is-active" : ""
                    }`}
                    onClick={() => changeTab(4)}
                  >
                    <h4 class="check-title">
                      <a
                        style={{ textDecoration: "none" }}
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#feature4"
                      >
                        <span class="acc-icons"></span>
                        <img
                          src="https://eazysites.s3.amazonaws.com/eazydms/img/feature/channel.png"
                          alt="Channel management icon"
                          width="38px"
                          style={{ marginRight: "10px" }}
                        />
                        Channel Management
                        <p style={{ marginBottom: "0px", marginTop: "5px" }}>
                          Track channel performance and empower the business to
                          meet the needs of the customers
                        </p>
                      </a>
                    </h4>
                    <span class="accordion__toggle"></span>
                  </div>
                  <div
                    class="accordion__body"
                    style={{ display: activeTab === 4 ? "block" : "none" }}
                  >
                    <ul class="gun">
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Price discounts for specific channels
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Visibility of channel wise sales performance
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-md-6 accordion">
                  <div
                    className={`accordion__header ${
                      activeTab === 5 ? "is-active" : ""
                    }`}
                    onClick={() => changeTab(5)}
                  >
                    <h4 class="check-title">
                      <a
                        style={{ textDecoration: "none" }}
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#feature9"
                      >
                        <span class="acc-icons"></span>
                        <img
                          src="https://eazysites.s3.amazonaws.com/eazydms/img/feature/integrated-sfa.png"
                          alt="Integrated SFA Icon"
                          width="40px"
                          style={{ marginRight: "10px" }}
                        />
                        Integrated SFA
                        <p style={{ marginBottom: "0px", marginTop: "5px" }}>
                          Collect orders from retailers through mobile/tablet
                          app
                          <br />
                          <br />
                        </p>
                      </a>
                    </h4>
                    <span class="accordion__toggle"></span>
                  </div>
                  <div
                    class="accordion__body"
                    style={{ display: activeTab === 5 ? "block" : "none" }}
                  >
                    <ul class="gun">
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Validate the visit and punctuality of Salesmen at the
                        point of sales
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>{" "}
                        Effectively monitor sales staff performance at all
                        hierarchy levels{" "}
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Monitor brands’ visibility at the outlet and assess
                        market competition effectively
                      </li>
                    </ul>
                  </div>

                  <div
                    className={`accordion__header ${
                      activeTab === 6 ? "is-active" : ""
                    }`}
                    onClick={() => changeTab(6)}
                  >
                    <h4 class="check-title">
                      <a
                        style={{ textDecoration: "none" }}
                        data-toggle="collapse"
                        class="active"
                        data-parent="#accordion"
                        href="#feature5"
                      >
                        <span class="acc-icons"></span>
                        <img
                          src="https://eazysites.s3.amazonaws.com/eazydms/img/feature/sync.png"
                          alt="Syncronization with tally Icon"
                          width="35px"
                          style={{ marginRight: "10px" }}
                        />
                        Synchronization with Tally
                        <p style={{ marginBottom: "0px", marginTop: "5px" }}>
                          Independent & Seamless Synchronization with tally
                          <br />
                          <br />
                        </p>
                      </a>
                    </h4>
                    <span class="accordion__toggle"></span>
                  </div>
                  <div
                    class="accordion__body"
                    style={{ display: activeTab === 6 ? "block" : "none" }}
                  >
                    <ul class="gun">
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Centralization of Data
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Data can be queued to sync even in offline mode{" "}
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Import & export data from distributors seamlessly{" "}
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Record information at minute level{" "}
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        SKU Mapping ensures credibility for multi brand
                        Distributors <br />
                        <br />
                      </li>
                    </ul>
                  </div>

                  <div
                    className={`accordion__header ${
                      activeTab === 7 ? "is-active" : ""
                    }`}
                    onClick={() => changeTab(7)}
                  >
                    <h4 class="check-title">
                      <a
                        style={{ textDecoration: "none" }}
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#feature6"
                      >
                        <span class="acc-icons"></span>
                        <img
                          src="https://eazysites.s3.amazonaws.com/eazydms/img/feature/claim.png"
                          alt="Claim Management Icon"
                          width="38px"
                          style={{ marginRight: "10px", height: "36px" }}
                        />
                        Scheme & Claims Management
                        <p style={{ marginBottom: "0px", marginTop: "5px" }}>
                          Analyze real-time scheme achievements and manage
                          Distributor’s Claims Effectively
                        </p>
                      </a>
                    </h4>
                    <span class="accordion__toggle"></span>
                  </div>
                  <div
                    class="accordion__body"
                    style={{ display: activeTab === 7 ? "block" : "none" }}
                  >
                    <ul class="gun">
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Design and Execute Dynamic Schemes in distributor
                        management system
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Automatic claim generation for key processes like price
                        discounts, promotions.{" "}
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Manual claim generation for specific expenses like
                        logistics, salesmen on field expenses.{" "}
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Flexible workflow for company approval (Sales, trade
                        marketing & finance)
                      </li>
                    </ul>
                  </div>

                  <div
                    className={`accordion__header ${
                      activeTab === 8 ? "is-active" : ""
                    }`}
                    onClick={() => changeTab(8)}
                  >
                    <h4 class="check-title">
                      <a
                        style={{ textDecoration: "none" }}
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#feature7"
                      >
                        <span class="acc-icons"></span>
                        <img
                          src="https://eazysites.s3.amazonaws.com/eazydms/img/feature/rollback.png"
                          alt="Data Rollback & Attributes Icon"
                          width="35px"
                        />
                        Data Rollback & Attributes
                        <p style={{ marginBottom: "0px", marginTop: "5px" }}>
                          Rollback data location wise, period and transaction
                          wise
                          <br />
                          <br />{" "}
                        </p>
                      </a>
                    </h4>
                    <span class="accordion__toggle"></span>
                  </div>
                  <div
                    class="accordion__body"
                    style={{ display: activeTab === 8 ? "block" : "none" }}
                  >
                    <ul class="gun">
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Manage attributes – Item wise, Ledger wise
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Many more add-on features and customizable capabilities{" "}
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Comprehensive reports for Stock, Sale, Purchase{" "}
                      </li>
                    </ul>
                  </div>

                  <div
                    className={`accordion__header ${
                      activeTab === 9 ? "is-active" : ""
                    }`}
                    onClick={() => changeTab(9)}
                  >
                    <h4 class="check-title">
                      <a
                        style={{ textDecoration: "none" }}
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#feature8"
                      >
                        <span class="acc-icons"></span>
                        <img
                          src="https://eazysites.s3.amazonaws.com/eazydms/img/feature/analytical-tools.png"
                          alt="Analytical Tools Icon"
                          width="40px"
                          style={{ marginRight: "10px", height: "37px" }}
                        />
                        Analytical Tools
                        <p style={{ marginBottom: "0px", marginTop: "5px" }}>
                          Real-time Business Insights for decision making
                          <br />
                          <br />
                        </p>
                      </a>
                    </h4>
                    <span class="accordion__toggle"></span>
                  </div>
                  <div
                    class="accordion__body"
                    style={{ display: activeTab === 9 ? "block" : "none" }}
                  >
                    <ul class="gun">
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Validate the visit and punctuality of Salesmen at the
                        point of sales
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>{" "}
                        Effectively monitor sales staff performance at all
                        hierarchy levels{" "}
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Monitor brands’ visibility at the outlet and assess
                        market competition effectively
                      </li>
                    </ul>
                  </div>

                  <div
                    className={`accordion__header ${
                      activeTab === 10 ? "is-active" : ""
                    }`}
                    onClick={() => changeTab(10)}
                  >
                    <h4 class="check-title">
                      <a
                        style={{ textDecoration: "none" }}
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#feature10"
                      >
                        <span class="acc-icons"></span>
                        <img
                          src="https://eazysites.s3.amazonaws.com/eazydms/img/feature/Integratederp.png"
                          alt="Integrated SFA Icon"
                          width="40px"
                          style={{ marginRight: "10px" }}
                        />
                        Integrated ERP
                        <p style={{ marginBottom: "0px", marginTop: "5px" }}>
                          An integrated ERP is available to focus on the primary
                          sales for easing the dataflow with accuracy.
                        </p>
                      </a>
                    </h4>
                    <span class="accordion__toggle"></span>
                  </div>
                  <div
                    class="accordion__body"
                    style={{ display: activeTab === 10 ? "block" : "none" }}
                  >
                    <ul class="gun">
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Improve operation's alignment with strategic plans.
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Let the business work as per the strategic plans.{" "}
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Early warning signals, access to the right information
                        in real time.
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Track opportunities, and react just in time.
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Better alignment of resources and organizational
                        objectives.
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Improved Inventory management.
                      </li>
                      <li>
                        {" "}
                        <span class="icon1">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                        Generate faster and higher ROI.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="fluid-container bg-cloud">
        <div class="row">
          <div class="col-md-12 text-center">
            <h3 style={{ color: "#ffffff" }}>
              Not Sure About Distributor Management System Implementation on a
              Large Scale?{" "}
            </h3>
            <h4
              style={{
                marginTop: "30px",
                marginBottom: "25px",
                fontSize: "22px",
                color: "#ffffff",
              }}
            >
              Start with a sample size of 5 to 10 Distributors with our SaaS
              version!{" "}
            </h4>
            <a
              style={{
                marginBottom: "30px",
                cursor: "pointer",
                textDecoration: "none",
              }}
              data-toggle="modal"
              data-target="#requestdemo"
              class="prod-read red"
            >
              GET YOUR FREE DEMO
            </a>
          </div>
        </div>
      </section>
      <div class="bg-color area-padding " style={{ paddingBottom: "0px" }}>
        <div class="container">
          <div class="row">
            <div class="col-md-8 col-sm-12 col-xs-12">
              <div style={{ marginBottom: "15px" }} class="about-content">
                <h4
                  style={{
                    fontSize: "30px",
                    letterSpacing: "0px",
                    lineHeight: "32px",
                    color: "#444",
                    display: "inline-block",
                    maxWidth: "100%",
                    margin: "0 0 15px",
                    fontWeight: "700",
                  }}
                >
                  Benefits for Distributors
                </h4>
                <div class="about-details text-center">
                  <div class="full-width">
                    <div class="icon-title margin-0">
                      <span class="icon1">
                        <FontAwesomeIcon icon={faCircleCheck} />
                      </span>
                      <p>Know availability of stock at warehouse</p>
                    </div>
                  </div>

                  <div class="full-width">
                    <div class="icon-title margin-0">
                      <span class="icon1">
                        <FontAwesomeIcon icon={faCircleCheck} />
                      </span>
                      <p>Automated alerts to avoid stock outs</p>
                    </div>
                  </div>
                  <div class="full-width">
                    <div class="icon-title margin-0">
                      <span class="icon1">
                        <FontAwesomeIcon icon={faCircleCheck} />
                      </span>
                      <p>Ideal stocking based on sale patterns</p>
                    </div>
                  </div>
                  <div class="full-width">
                    <div class="icon-title margin-0">
                      <span class="icon1">
                        <FontAwesomeIcon icon={faCircleCheck} />
                      </span>
                      <p>Information on in-transit stock and delivery date</p>
                    </div>
                  </div>
                  <div class="full-width">
                    <div class="icon-title margin-0">
                      <span class="icon1">
                        <FontAwesomeIcon icon={faCircleCheck} />
                      </span>
                      <p> Quicker processing of claims </p>
                    </div>
                  </div>
                  <div class="full-width">
                    <div class="icon-title margin-0">
                      <span class="icon1">
                        <FontAwesomeIcon icon={faCircleCheck} />
                      </span>
                      <p> Eliminate needs of shipping paper bills </p>
                    </div>
                  </div>
                  <div class="full-width">
                    <div class="icon-title margin-0">
                      <span class="icon1">
                        <FontAwesomeIcon icon={faCircleCheck} />
                      </span>
                      <p>Operational efficiency for Distributors </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-4 col-xs-12">
              <img
                src="https://eazysites.s3.amazonaws.com/eazydms/img/DMS1.png"
                alt="Eazy DMS screenshot"
                style={{ marginTop: "15px" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div class="banner-area">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="banner-content text-left">
                <h4>Download EAZY DMS Presentation</h4>
                <div class="banner-contact-btn">
                  <a
                    class="banner-btn"
                    href="img/pdf/eazydms.pdf"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <FontAwesomeIcon icon={faDownload} /> Download PDF
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="welcome-area area-padding">
        <div class="container">
          <div class="well-inner">
            <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="well-services">
                  <div class="well-icon">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/feature/Implementation.png"
                      alt="Implementation Rollout Model Icon"
                    />
                  </div>
                  <div class="well-content">
                    <h4>Implementation/Rollout Model</h4>
                    <p class="truncate">
                      Our aim is not only to provide the Next Gen of Hybrid
                      Distributor Management System, but also to provide the
                      best experience and to achieve maximum rollouts. We
                      provide a dedicated Account Manager who takes care of the
                      progress of the project timely rollouts leading to
                      successful implementation of DMS. We are tied up with an
                      extensive network of Tally Partners who would physically
                      visit your Distributors/Dealers and train them about the
                      software. This way we have been able to increase the
                      efficiency and successfully accomplish 400 rollouts in a
                      month
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="well-services">
                  <div class="well-icon">
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/feature/support.png"
                      alt="Support or Help Desk Model Icon"
                    />
                  </div>
                  <div class="well-content">
                    <h4>Support/Helpdesk Model</h4>
                    <p class="truncate">
                      Our work doesn’t end with installation DMS (Distributor
                      Management System). We want you to take maximum result out
                      of our DMS which requires hand holding. To cater to the
                      day-to-day queries of the Distributor/Dealer on how to use
                      the system, Eazy DMS provides you with a dedicated
                      helpdesk resources who become the single point of contact
                      for all the issues and coordination for your
                      Distributor/Dealer network related to Tally, Busy or your
                      distributor Management System
                      <br />
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
