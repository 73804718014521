import React from "react";
import pagenotfound from "../../img/background/pagenotfound.jpg";
// import Styleerr from "./ErrorPage.module.css";
import { Link } from 'react-router-dom';
const ErrorPage = () => {
  return (
    <>
      <div class="cont_principal">
        <section class="wrapper">

          <div class="container">

            <div id="scene" class="scene" data-hover-only="false">


              <div class="circle" data-depth="1.2"></div>
              <div class="circle2" data-depth="1.2"></div>
              <div class="one" data-depth="0.9">
                <div class="content">
                  <span class="piece"></span>
                  <span class="piece"></span>
                  <span class="piece"></span>
                </div>
              </div>

              <div class="two" data-depth="0.60">
                <div class="content">
                  <span class="piece"></span>
                  <span class="piece"></span>
                  <span class="piece"></span>
                </div>
              </div>

              <div class="three" data-depth="0.40">
                <div class="content">
                  <span class="piece"></span>
                  <span class="piece"></span>
                  <span class="piece"></span>
                </div>
              </div>

              <p class="p404" data-depth="0.50">404</p>
              <p class="p404" data-depth="0.10">404</p>

            </div>

            <div class="text">
              <article>
                <p>Uh oh! Looks like you got lost. Go back to the homepage!</p>
                <button clasName="btn btn_info_error">
                  <Link  to="/" > Back to website</Link>
                  </button>
                
              </article>
            </div>

          </div>
        </section>
        {/* <div class="cont_error">
          <h1>Oops..! 404 Page Not Found</h1>
          <img src={pagenotfound} height="500" width="500" alt="not found" />
          <a style={{ marginTop: "10px" }} href="https://eazydms.com" class="">
            Back to website
          </a>
        </div> */}
        <div class="cont_aura_1"></div>
        <div class="cont_aura_2"></div>
      </div>
    </>
  );
};
export default ErrorPage;
