import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
function Home(props) {
  return (
    <div>
      <div id="demo" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#demo"
            data-bs-slide-to="0"
            class="active"
          ></button>
          <button
            type="button"
            data-bs-target="#demo"
            data-bs-slide-to="1"
          ></button>
          
        </div>

        <div class="carousel-inner">
          <div class="carousel-item active">
          
          <img
            src="https://eazysites.s3.amazonaws.com/eazydms/img/slider/infuse_excellence.jpg"
            alt=""
            class="d-block"
            
          />
          </div>

          <div class="carousel-item">
          
          <img
            src="https://eazysites.s3.amazonaws.com/eazydms/img/slider/website_ban_new.jpg"
            alt=""
            class="d-block"
           
          />
          </div>
         

          
        </div>

        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#demo"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon"></span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#demo"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon"></span>
        </button>
        {/* </div>*/}
      </div>
    </div>
  );
}

export default Home;
