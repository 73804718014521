import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faAngleRight, faBackward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import whyus from '../../assets/images/whyus.png';
import culture from '../../assets/images/culture.png';
import React from "react";
import TruncateText from '../Truncate/TruncateText';


export const AboutBackGround = () => {
  return (
    <>
      <div className="page-area1">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="breadcrumb">
                <div className="section-headline white-headline text-center">
                  <h4
                    style={{
                      fontSize: "48px",
                      letterSpacing: "2px",
                      lineHeight: "48px",
                      color: "#fff",
                      display: "inline-block",
                      maxWidth: "100%",
                    }}
                  >
                    About us
                  </h4>
                </div>
                <ul>
                  <li className="home-bread">
                    Home
                    <span style={{ marginLeft: "7px" }}></span>
                    <FontAwesomeIcon icon={faAngleRight} />
                    <span style={{ marginLeft: "7px" }}></span> About us
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="area-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="section-headline text-center">
              


                <p style={{ maxWidth: "100%", color: "#7f8499" }}>
                  EAZY Business Solutions was incorporated in 2007, in
                  association with the Singhal Group, one of NCR’s most reputed
                  companies in Financial Services and Real Estate Solutions.
                  Singhal Group has been at the forefront of creating brands
                  such as “Krish” in Real Estate, and offers a wide range of
                  financial consulting services in Personal, Industrial,
                  Business and Institutional segments. Today, with a team of
                  dynamic and experienced professionals at its helm, EAZY
                  Business Solutions has become one of the fastest growing ERP
                  Product, Project Development and IT consulting companies. It
                  has a veritable Pan-India presence, allowing great reach and
                  accessibility to companies across the nation. The company is
                  growing its foothold internationally with presence in
                  Bangladesh, Nigeria, Saudi Arabia, South Africa, Algeria and
                  Kenya.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="about-area area-padding">
        <div class="container">
          <div class="row"></div>
          <div class="about-details ">
            <div class="about-content">
              <div
                class="section-headline text-center"
                style={{ color: "#025282" }}
              >
                <h3 class="text-center">
                  WHAT <span class="colorr">WE DO</span>
                </h3>
                <p>
                  We offer a wide range of IT Solutions like ERP, CRM, DMS,
                  Payroll & Asset Management that help organizations to improve
                  their operational efficiency while keeping their budget in
                  place
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="xyz">
                  <div class="icon-title my-0" style={{ color: "#025282" }}>
                    {/* <span>
                      <img
                        src="https://eazysites.s3.ap-south-1.amazonaws.com/eazydms/img/about/vision.png"
                        alt="Eazy DMS Vision Icon"
                      />
                    </span> */}
                    <h3 class="title-border mb-0">Vision</h3>
                  </div>
                  <TruncateText text="We intend to create value and empower all type of businesses
                    worldwide by providing highly capable and affordable
                    enterprise software solutions that enable the organizations
                    with improved productivity and efficiency."  />
                  
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="xyz">
                  <div class="icon-title my-0" style={{ color: "#025282" }}>
                    {/* <span>
                      <img
                        src="https://eazysites.s3.ap-south-1.amazonaws.com/eazydms/img/about/mission.png"
                        alt="Eazy DMS  Mission Icon"
                      />
                    </span> */}
                    <h3 class="title-border mb-0">Mission</h3>
                  </div>
                  <TruncateText text="Our mission is to be a pioneer and leader in providing the
                    most valued solutions in ERP software Industry worldwide. We
                    aim to empower our clients towards exponential growth and
                    excellence by providing innovative products and solutions." />
                  
                </div>
              </div>


            </div>
            <div className="xyzz">
              <div className="row">
                <div class="col-lg-8 col-md-8 col-sm-12 order-md-0 order-1">
                  <div class="">
                    <div class="icon-title my-0" style={{ color: "#025282" }}>

                      <h3 class="title-border mb-0">Culture</h3>
                    </div>
                    <TruncateText text="Our people are our invaluable resource and greatest
                      strength. We follow a philosophy that encourages our people
                      to learn and grow in camaraderie thus creating a supportive
                      and empowering workplace. We invest in our people and
                      provide them necessary learning environment which helps them
                      succeed and develop the skills needed to drive company's
                      growth as well as their individual development. We believe
                      in sustainable growth and thus hire, retain and develop our
                      leadership into a highly dexterous workforce."  />
                    
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12 order-md-1 order-0">

                  <img
                    src={culture}
                    alt="Organizations Culture Icon"
                  />

                </div>
              </div>
            </div>
            <div class="xyzz mt-5">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <img
                   src={whyus}
                    alt="Why Eazy DMS Icon"
                    
                  />
                </div>

                <div class="col-lg-8 col-md-8 col-sm-12">

                  <div class="icon-title my-0" style={{ color: "#025282" }}>

                    <h3 class="title-border mb-0">Why Us</h3>
                  </div>
                  <TruncateText text="Our customers choose us because we provide leading
                    technology, deliver unmatched services and training, and
                    offer some of the best value in the industry. Over the last
                    decade, our approach to business has helped transform us
                    from a small engineering upstart to a leading global
                    provider of ERP Software. Regardless of the project size and
                    complexity, we strive to provide our customers with great
                    technology, differentiated products and unmatched
                    engineering services delivered within a flexible and
                    cost-effective business model that meets our customer’s
                    needs."  />
                  
                </div>

                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="area-padding">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="section-headline text-center">
                <h3>
                  Our <span class="color">Products</span>
                </h3>
              </div>
            </div>
          </div>
          <div class="row my-4 justify-content-center">
            <div class="col-lg-4 col-md-6 col-6">
              <div class="product_1">
                <div class="icon">
                  {" "}
                  <img
                    src="https://eazysites.s3.ap-south-1.amazonaws.com/eazydms/img/about/eazy-erp.png"
                  // alt="Tally integrated Eazy ERP Icon"
                  />{" "}
                </div>
                <div class="prod_detail">
                  <h3>EAZY ERP</h3>
                  <p style={{ color: "#7f8499" }}>
                    Helps companies streamline their operations by automating
                    the workflow of all departments of the organization and
                    easing dataflow with accuracy...
                  </p>
                  <a
                    href="https://eazyerp.com/eazy-erp-india.php#solution"
                    class="prod-read"
                    target="_blank"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-6">
              <div class="product_1">
                <div class="icon">
                  {" "}
                  <img
                    src="https://eazysites.s3.ap-south-1.amazonaws.com/eazydms/img/about/eazy-dms.png"
                  // alt="Tally integrated Eazy DMS Icon"
                  />{" "}
                </div>
                <div class="prod_detail">
                  <h3>EAZY DMS</h3>
                  <p style={{ color: "#7f8499" }}>
                    A Distributor Management Software for tracking Secondary
                    Sales, up-to end level, helping organizations streamline key
                    factors...
                  </p>
                  <a
                    href="distributor-management-software"
                    class="prod-read"
                    target="_blank"
                  >
                    Read More
                  </a>{" "}
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-6">
              <div class="product_1">
                <div class="icon">
                  {" "}
                  <img
                    src="https://eazysites.s3.ap-south-1.amazonaws.com/eazydms/img/about/eazy-crm.png"
                  // alt="Eazy CRM Icon"
                  />{" "}
                </div>
                <div class="prod_detail">
                  <h3>EAZY CRM</h3>
                  <p style={{ color: "#7f8499" }}>
                    Provides businesses with the tools necessary to improve
                    customer relations and identify marketing advantages...
                  </p>
                  <a
                    href="https://eazyerp.com/eazy-crm.php#solution"
                    class="prod-read"
                    target="_blank"
                  >
                    Read More
                  </a>{" "}
                </div>
              </div>
            </div>
          
            <div className="col-lg-4 col-md-6 col-6">
              <div className="product_1">
                <div className="icon">
                  <img
                    src="https://eazysites.s3.ap-south-1.amazonaws.com/eazydms/img/about/eazy-asset.png"
                  // alt="Eazy Asset Icon"
                  />
                </div>
                <div className="prod_detail">
                  <h3>EAZY ASSET</h3>
                  <p style={{ color: "#7f8499" }}>
                    Cleanses and fortifies the fixed asset register to establish
                    a strong foundation for effective fixed asset management. It
                    provides the...
                  </p>
                  <a
                    href="https://eazyerp.com/eazy-asset.php#solution"
                    className="prod-read"
                    target="_blank"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-6">
              <div className="product_1">
                <div className="icon">
                  <img
                    src="https://eazysites.s3.ap-south-1.amazonaws.com/eazydms/img/about/EAZY SFA ICON.png"
                  // alt="Eazy Pay Icon"
                  />
                </div>
                <div className="prod_detail">
                  <h3>EAZY SFA</h3>
                  <p style={{ color: "#7f8499" }}>
                    Maximizes the potential of HR and Accounts by capturing more
                    than 100 credentials of a single employee, enabling timely
                    and accurate...
                  </p>
                  <a
                    href="https://eazyerp.com/eazy-sfa.php#solution"
                    className="prod-read"
                    target="_blank"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </div>
      <div className="banner-area download_pdf">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="banner-content text-left comp_pro_btn">
                <h4>Company Profile</h4>
                <div className="banner-contact-btn">
                  <a
                    className="banner-btn"
                    href="https://eazysites.s3.ap-south-1.amazonaws.com/eazydms/img/pdf/company-profile.pdf"
                    download=""
                  >
                    <i class="fa fa-download" aria-hidden="true"></i> Download
                    PDF
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="area-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="section-headline text-center">
                <h3>
                  Our <span className="color">Timeline</span>
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="tech-slideshow">
              <div className="mover-1"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="team-area bg-color area-padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="section-headline text-center">
                <h3>
                  Our <span className="color">Team</span>
                </h3>
                <p>
                  The professionals at EAZY share a commitment to Innovation,
                  professional training and integrity that distinguishes us as a
                  team and make us a recognized name
                </p>
              </div>
            </div>
          </div>
          <div className="team-member set_row">
            <div className="row">
              <div className="col">
                <div className="single-member">
                  <div className="team-img">
                    <a href="#">
                      <img
                        className="img-fluid"
                        src="https://eazysites.s3.ap-south-1.amazonaws.com/eazydms/img/team/Kunal Sir 2-1.png"
                        alt="Kunal Singhal | Founder & CEO | Eazy Business Solutions"
                      />
                    </a>
                  </div>
                  <div className="team-content">
                    <div className="team-title">
                      <h4>Kunal Singhal</h4>
                      <h6>Founder & CEO</h6>
                      <div className="linkdine_btn">
                        <a
                          href="https://www.linkedin.com/in/cakunalsinghal"
                          target="_blank"
                        >
                          <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="single-member">
                  <div className="team-img">
                    <a href="#">
                      <img
                        className="img-fluid"
                        src="https://eazysites.s3.ap-south-1.amazonaws.com/eazydms/img/team/Dharneesh_sir.png"
                        alt="Dharneesh sir | CoFounder & COO"
                      />
                    </a>
                  </div>
                  <div className="team-content">
                    <div className="team-title">
                      <h4>Dharneesh B R</h4>
                      <h6>CoFounder & COO</h6>
                      <div className="linkdine_btn">
                        <a
                          href="https://www.linkedin.com/in/dharneesh"
                          target="_blank"
                        >
                          <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="single-member">
                  <div className="team-img">
                    <a href="#">
                      <img
                        className="img-fluid"
                        src="https://eazysites.s3.ap-south-1.amazonaws.com/eazydms/img/team/Nitesh_sirr.jpg"
                        alt="Nitesh Purohit | VP Sales - DMS"
                      />
                    </a>
                  </div>
                  <div className="team-content">
                    <div className="team-title">
                      <h4>Nitesh Purohit</h4>
                      <h6>VP Sales - DMS</h6>
                      <div className="linkdine_btn">
                        <a
                          href="https://www.linkedin.com/in/nitesh-purohit-77744a24"
                          target="_blank"
                        >
                          <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="single-member">
                  <div className="team-img">
                    <a href="#">
                      <img
                        className="img-fluid"
                        src="https://eazysites.s3.ap-south-1.amazonaws.com/eazydms/img/team/Deepak_sir.png"
                        alt="Deepak Goyal | Development Head - DMS"
                      />
                    </a>
                  </div>
                  <div className="team-content">
                    <div className="team-title">
                      <h4>Deepak Goyal</h4>
                      <h6>Development Head - DMS</h6>
                      <div className="linkdine_btn">
                        <a
                          href="https://www.linkedin.com/in/deepak-goyal-06a41579"
                          target="_blank"
                        >
                          <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="single-member">
                  <div className="team-img">
                    <a href="#">
                      <img
                        className="img-fluid"
                        src="https://eazysites.s3.ap-south-1.amazonaws.com/eazydms/img/team/Kaushal_sir.jpg"
                        alt="Kaushal Mishra | Product Head - DMS"
                      />
                    </a>
                  </div>
                  <div className="team-content">
                    <div className="team-title">
                      <h4>Kaushal Mishra</h4>
                      <h6>Product Head - DMS</h6>
                      <div className="linkdine_btn">
                        <a
                          href="https://www.linkedin.com/in/kaushal-mishra-481b75137"
                          target="_blank"
                        >
                          <FontAwesomeIcon icon={faLinkedin} />
                          {/* <i className="fab fa-linkedin text-white"></i> */}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
