import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { RecentPost } from "../recentPost";
import { Topbar } from "../../Topbar";
import AfterTopBar from "../../AfterTopbar/AfterTopbar";
import Footer from "../../Sections/footer";

export const BlogUspOfSfa = () => {
  return (
    <>
      <div className="main_header">
        <Topbar />
        <AfterTopBar />
      </div>
      <div class="page-area4">
        <div class="breadcumb-overlay"></div>
        <div class="container">
          <div class="row text-center">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="breadcrumb">
                <div class="section-headline white-headline text-center">
                  <h4
                    style={{
                      fontSize: "48px",
                      letterSpacing: "2px",
                      lineHeight: "48px",
                      color: "#fff",
                      display: "inline-block",
                      maxWidth: "100%",
                    }}
                  >
                    USP’S OF EAZY SFA
                  </h4>
                </div>
                <ul>
                  <li className="home-bread">
                    Home
                    <span style={{ marginLeft: "7px" }}></span>
                    <FontAwesomeIcon icon={faAngleRight} />
                    <span style={{ marginLeft: "7px" }}></span> Blog
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="blog-page-area area-padding">
        <div class="container">
          <div class="blog-details">
            <div class="row">
              <div class="col-md-8 col-sm-8 col-xs-12">
                <article class="blog-post-wrapper">
                  <div class="blog-banner">
                    <a href="#" class="blog-images">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/blog/EAZY-SFA-DMS-blog-cover.png"
                        alt=""
                      />
                    </a>
                    <div class="blog-content">
                      <br />
                      <p>
                        Traditional sales force automation platforms help
                        enterprises keep a watch on their sales forces. However,
                        it must achieve beyond just tracking the field sales and
                        should also empower them to make tactical business
                        decisions on the go. An effective Sales Force Automation
                        solution empowers the sales team to measure the right
                        metrics that provide in-depth analysis to build accurate
                        strategic decisions.
                      </p>
                      <p>
                        EAZY SFA is one central canvas to catalyze empowerment
                        of field sales teams and power smarter sales decisions
                        with the aim of spurring growth both in the short and
                        long terms. It is not just any sales force automation
                        software, it is effective/intelligent Sales Force
                        Management.
                      </p>

                      <h4>EAZY SFA comprises of:</h4>

                      <div class="row">
                        <div
                          class="col-lg-4 col-md-4 col-sm-4"
                          data-effect="slide-left"
                        >
                          <a href="" style={{ textDecoration: "none" }}>
                            <div class="product_1">
                              <div
                                style={{
                                  marginBottom: "20px",
                                  marginTop: "25px",
                                }}
                                class=""
                              >
                                {" "}
                                <img
                                  src="https://eazysites.s3.amazonaws.com/eazydms/img/manager.png"
                                  alt=""
                                />{" "}
                              </div>

                              <div class="prod_detail">
                                <h5
                                  style={{
                                    color: "#004387",
                                    fontWeight: "600",
                                    textDecoration: "none",
                                  }}
                                >
                                  Manager’s App
                                </h5>

                                <p style={{ color: "red" }}>
                                  Obtain deep insights into sales trends and
                                  performance with real time data and use it to
                                  make better informed and smarter decisions for
                                  faster growth.
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>

                        <div
                          class="col-lg-4 col-md-4 col-sm-4"
                          data-effect="slide-left"
                        >
                          <a href="" style={{ textDecoration: "none" }}>
                            <div class="product_1">
                              <div
                                style={{
                                  marginBottom: "20px",
                                  marginTop: "25px",
                                }}
                                class=""
                              >
                                {" "}
                                <img
                                  src="https://eazysites.s3.amazonaws.com/eazydms/img/sfa.png"
                                  alt=""
                                />{" "}
                              </div>

                              <div class="prod_detail">
                                <h5
                                  style={{
                                    color: "#004387",
                                    fontWeight: "600",
                                    textDecoration: "none",
                                  }}
                                >
                                  Field Sales App
                                </h5>

                                <p style={{ color: "red" }}>
                                  Transform field sales with the all-in-one
                                  sales force automation (SFA) app and take
                                  control of your sales operations to drive
                                  better results and faster growth.
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>

                        <div
                          class="col-lg-4 col-md-4 col-sm-4"
                          data-effect="slide-left"
                        >
                          <a href="" style={{ textDecoration: "none" }}>
                            <div class="product_1">
                              <div
                                style={{
                                  marginBottom: "20px",
                                  marginTop: "25px",
                                }}
                                class=""
                              >
                                {" "}
                                <img
                                  src="https://eazysites.s3.amazonaws.com/eazydms/img/dash.png"
                                  alt=""
                                />{" "}
                              </div>

                              <div class="prod_detail">
                                <h5
                                  style={{
                                    color: "#004387",
                                    fontWeight: "600",
                                  }}
                                >
                                  Dashboard
                                </h5>

                                <p style={{ color: "red" }}>
                                  Harness the power of data to access the most
                                  relevant data sets for your businesses and
                                  devise informed strategies to keep your sales
                                  back-end in order.
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>

                      <p>&nbsp;</p>

                      <h5>Some of the key USP’s that separates EAZY SFA </h5>

                      <ul class="gun">
                        <li>
                          {" "}
                          <span class="icon1">
                            <FontAwesomeIcon icon={faCircleCheck} />
                          </span>
                          <b style={{ fontWeight: "bolder" }}>
                            Digital Ordering:
                          </b>{" "}
                          Allow sales to place orders using a smartphone rather
                          than manual order books. Arm your sales assets with
                          real time data on stock and delivery times allowing
                          them to make better sales pitches. Obtain greater
                          flexibility with outlet and SKU wise ordering while
                          also providing accurate stock information of each SKU
                          in real time.
                        </li>
                        <li>
                          {" "}
                          <span class="icon1">
                            <FontAwesomeIcon icon={faCircleCheck} />
                          </span>
                          <b style={{ fontWeight: "bolder" }}>
                            Authenticate Sales Rep visits:
                          </b>{" "}
                          Authenticate outlet visits by individual sales reps.
                          Get sequential ordering of authenticated outlets
                          alongside the ones missed by sales reps despites being
                          assigned for coverage.{" "}
                        </li>
                        <li>
                          {" "}
                          <span class="icon1">
                            <FontAwesomeIcon icon={faCircleCheck} />
                          </span>
                          <b style={{ fontWeight: "bolder" }}>
                            Real time Field & Sales Coverage Insights:
                          </b>{" "}
                          Get a holistic view of your sales force in action &
                          sales coverage in a particular area of operation with
                          real time data from the field. Review itineraries
                          adopted by sales reps with listing of outlets along
                          with time spent at each sales call.
                        </li>

                        <li>
                          {" "}
                          <span class="icon1">
                            <FontAwesomeIcon icon={faCircleCheck} />
                          </span>
                          <b style={{ fontWeight: "bolder" }}>
                            Sales Performance Reporting:
                          </b>{" "}
                          Automatically generate periodic reports measuring the
                          performance of your sales team allowing you to
                          recalibrate your sales team deployment strategy to
                          derive the best results.{" "}
                        </li>
                        <li>
                          {" "}
                          <span class="icon1">
                            <FontAwesomeIcon icon={faCircleCheck} />
                          </span>
                          <b style={{ fontWeight: "bolder" }}>
                            Manage Contacts:
                          </b>{" "}
                          Get insights about your customer outlets at your
                          fingertips. Keep track of each outlet and associated
                          sales histories and know when to upsell.
                        </li>
                        <li>
                          {" "}
                          <span class="icon1">
                            <FontAwesomeIcon icon={faCircleCheck} />
                          </span>
                          <b style={{ fontWeight: "bolder" }}>
                            Product Management:
                          </b>{" "}
                          Manage product lists and capture existing stock
                          alongside pricing of each SKU. Easily add, delete or
                          modify product properties. Updates get instantly
                          reflected in the digital catalogue and it can be
                          accessed by field sales reps.
                        </li>
                        <li>
                          {" "}
                          <span class="icon1">
                            <FontAwesomeIcon icon={faCircleCheck} />
                          </span>
                          <b style={{ fontWeight: "bolder" }}>
                            Intelligent Heat Maps:
                          </b>{" "}
                          Gain deeper insights into extremely complex and large
                          volumes of data with ease with intelligent heat maps
                          that represents complicated information in colorful
                          images allowing you to effortlessly point to the
                          insight you require.
                        </li>

                        <li>
                          {" "}
                          <span class="icon1">
                            <FontAwesomeIcon icon={faCircleCheck} />
                          </span>
                          <b style={{ fontWeight: "bolder" }}>
                            Measure Productivity:
                          </b>{" "}
                          Powerful productivity indices like beat efficiency,
                          adherence to plans, times spent at points of sale and
                          analytics helps sales reps self-assess themselves and
                          improve performance over a period of time.
                        </li>
                        <li>
                          {" "}
                          <span class="icon1">
                            <FontAwesomeIcon icon={faCircleCheck} />
                          </span>
                          <b style={{ fontWeight: "bolder" }}>
                            Predictive Analytics:
                          </b>{" "}
                          Advanced predictive analytics helps sales reps suggest
                          orders based on ordering history of the point of sale.
                          This allows time saving while also providing ordering
                          a seamless experience for both the sides.
                        </li>
                        <li>
                          {" "}
                          <span class="icon1">
                            <FontAwesomeIcon icon={faCircleCheck} />
                          </span>
                          <b style={{ fontWeight: "bolder" }}>
                            Targets and Achievements:
                          </b>{" "}
                          Set periodic targets and compare them against
                          achievements to measure efficiency and productivity on
                          a daily, monthly or weekly basis.{" "}
                        </li>
                        <li>
                          {" "}
                          <span class="icon1">
                            <FontAwesomeIcon icon={faCircleCheck} />
                          </span>
                          <b style={{ fontWeight: "bolder" }}>
                            Instant Notifications:
                          </b>{" "}
                          Get instantly notified about sales team activities,
                          reports and targets and receive instant communication
                          in case of breach.
                        </li>
                        <li>
                          {" "}
                          <span class="icon1">
                            <FontAwesomeIcon icon={faCircleCheck} />
                          </span>
                          <b style={{ fontWeight: "bolder" }}>
                            Instant Custom Surveys:
                          </b>{" "}
                          Instantly created and customized by your sales reps.
                          New points of sales can be easily added to the
                          directory during surveys and the data collected can be
                          used in decision making.
                        </li>
                        <li>
                          {" "}
                          <span class="icon1">
                            <FontAwesomeIcon icon={faCircleCheck} />
                          </span>
                          <b style={{ fontWeight: "bolder" }}>
                            Pre-defined & Custom Reports:
                          </b>{" "}
                          Get standardized reports based on predefined metrics
                          and KPIs designed to match your industry of operation.
                          Generate intelligent custom reports from the
                          dashboard. Also, reports can be generated
                          automatically and delivered to your mail on a daily,
                          weekly or monthly basis.{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </article>
                <div class="clear"></div>
                <div class="single-post-comments"></div>
              </div>
              <div class="col-md-4">
                <RecentPost />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
