import "./App.css";
import { HashRouter, Routes, Route, BrowserRouter } from "react-router-dom";
import { browserHistory } from "react-router";
import { Topbar } from "./Components/Topbar";
import AfterTopBar from "./Components/AfterTopbar/AfterTopbar";
import Sections from "./Components/Sections";
import AboutPage from "./HOC/AboutPage";
import CustomePage from "./Components/CustomerPage/Customer";
import { Media } from "./Components/Media/Media";
import { Contact } from "./Components/Contact/Contact";
import CaseStudy from "./Components/CaseStudy/CaseStudy";
import Footer from "./Components/Sections/footer";
import { CaseStudyGoodYear } from "./Components/CaseStudy/caseStudyGoodyear";
import { CaseStudyCrompton } from "./Components/CaseStudy/caseStudyCrompton";
import { BlogPageDmsNotPolicing } from "./Components/Blog/BlogPages/blogpageDmsNotPolicing";
import BlogPage from "./Components/Blog/Blog";
import { RightApproachToDms } from "./Components/Blog/BlogPages/rightApproachToDms";
import { BlogDmsCrm } from "./Components/Blog/BlogPages/blogDmsCrm";
import { BlogSteeringFmcgSector } from "./Components/Blog/BlogPages/blogSteeringFmcgSector";
import { BlogSaasVsPremisces } from "./Components/Blog/BlogPages/blogDetailsSaasvsonpremisces";
import { BlogOnSiteDistributor } from "./Components/Blog/BlogPages/blogOnSiteDistributor";
import { BlogAutoMobile } from "./Components/Blog/BlogPages/blogAutoMobile";
import { BlogTallyVsDms } from "./Components/Blog/BlogPages/blogTallyVsDms";
import { BlogUspOfSfa } from "./Components/Blog/BlogPages/blogUspOfSfa";
import { BlogFmcgNeedDms } from "./Components/Blog/BlogPages/blogFmcgNeedDms";
import { BlogTallyIntoDms } from "./Components/Blog/BlogPages/blogTallyIntDms";
import { BlogDmsImp } from "./Components/Blog/BlogPages/blogDmsImp";
import { Dms } from "./Components/Sections/Dms";
import { EazySfaPage } from "./Components/Sections/eazySfa";
import { PrivacyPolicy } from "./Components/Sections/privacyPolicy";
import LandingBPage from "./Components/LandingPage/landingb";
import CampaignPage from "./Components/CampaignPage";
import EmailDmsPage from "./Components/EmailDMS";
import LinkedIn1LandingPage from "./Components/LinkedIn1";
import RemarketingDmsLandingPage from "./Components/RemarktingDms";
import ErrorPage from "./Components/404Error";
import ScrollToTopOnMount from "./Components/ScrollToTopOnMount";
const App = () => {
  return (
    <>
      <BrowserRouter>
      <ScrollToTopOnMount />
        <Routes>
          <Route path="/"  element={<Sections />} />
          {/* <Route path="/" element={<Sections />} /> */}
          <Route path="/About" element={<AboutPage />} />
          <Route path="/Customer" element={<CustomePage />} />
          <Route path="/Media" element={<Media />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/CaseStudy" element={<CaseStudy />} />
          <Route path="/Blogs" element={<BlogPage />} />
          <Route path="/EazySfa" element={<EazySfaPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/CaseStudyGoodYear" element={<CaseStudyGoodYear />} />
          <Route path="/CaseStudyCrompton" element={<CaseStudyCrompton />} />
          <Route
            path="/blog-details-DMS-is-not-meant-for-policing"
            element={<BlogPageDmsNotPolicing />}
          />
          <Route
            path="/blog-right-approach-to-dms"
            element={<RightApproachToDms />}
          />
          <Route path="/blog-dms-crm" element={<BlogDmsCrm />} />
          <Route
            path="/blog-details-steeringfmcgsector"
            element={<BlogSteeringFmcgSector />}
          />
          <Route
            path="/blog-details-saasvsonpremisces"
            element={<BlogSaasVsPremisces />}
          />
          <Route
            path="/blog-details-rolloutbenefits"
            element={<BlogOnSiteDistributor />}
          />
          <Route
            path="/blog-details-dmsforautomotives"
            element={<BlogAutoMobile />}
          />
          <Route
            path="/blog-details-dmsvswebportal"
            element={<BlogTallyVsDms />}
          />
          <Route path="/blog-details-eazysfa" element={<BlogUspOfSfa />} />
          <Route path="/blog-details-fmcg" element={<BlogFmcgNeedDms />} />
          <Route
            path="/blog-details-tally-int"
            element={<BlogTallyIntoDms />}
          />
          <Route path="/whatisaDMS" element={<BlogDmsImp />} />
          <Route path="/distributor-management-software" element={<Dms />} />

          <Route path="/landingb" element={<LandingBPage />} />
          <Route path="/campaign1" element={<CampaignPage />} />
          <Route path="/emaildms" element={<EmailDmsPage />} />
          <Route path="/LinkedIn1" element={<LinkedIn1LandingPage />} />
          <Route
            path="/remarketingdms"
            element={<RemarketingDmsLandingPage />}
          />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
