import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { RecentPost } from "../recentPost";
import { Topbar } from "../../Topbar";
import AfterTopBar from "../../AfterTopbar/AfterTopbar";
import Footer from "../../Sections/footer";

export const BlogAutoMobile = () => {
  return (
    <>
      <div className="main_header">
        <Topbar />
        <AfterTopBar />
      </div>
      <div class="page-area4">
        <div class="breadcumb-overlay"></div>
        <div class="container">
          <div class="row text-center">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="breadcrumb">
                <div class="section-headline white-headline text-center">
                  <h4
                    style={{
                      fontSize: "48px",
                      letterSpacing: "2px",
                      lineHeight: "48px",
                      color: "#fff",
                      display: "inline-block",
                      maxWidth: "100%",
                    }}
                  >
                    WHY IS DMS IMPORTANT FOR AUTOMOBILE INDUSTRY?
                  </h4>
                </div>
                <ul>
                  <li className="home-bread">
                    Home
                    <span style={{ marginLeft: "7px" }}></span>
                    <FontAwesomeIcon icon={faAngleRight} />
                    <span style={{ marginLeft: "7px" }}></span> Blog
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="blog-page-area area-padding">
        <div class="container" style={{ color: "#6c757d" }}>
          <div class="blog-details">
            <div class="row">
              <div class="col-md-8 col-sm-8 col-xs-12">
                <article class="blog-post-wrapper">
                  <div class="blog-banner">
                    <a href="#" class="blog-images">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/blog/automotives.jpg"
                        alt="Distributor Management System for Automobile Industry Image"
                      />
                    </a>
                    <div class="blog-content">
                      <br />
                      <p>
                        <span style={{ color: "black", fontSize: "20pt" }}>
                          T
                        </span>
                        he automobile industry of India has witnessed a
                        remarkable growth ever since the economic liberalization
                        & delicensing in 1991. Indian automobile market is
                        growing at a rapid rate of 7.08 CAGR from 2013-2018 with
                        an inflow of about 20 billion FDI over the past 2
                        decades owing to the reputation of being the sixth
                        largest commercial vehicles producer across the world.
                      </p>
                      <p>
                        Before amendments were made to Competition Act,
                        Automobile companies were found to be using their
                        dominant position in the market for controlling the sale
                        of original spare parts and after sales service to their
                        benefit, thereby distorting fair competition. They only
                        dealt through parties they had agreements with and
                        completely restricted independent sellers from entering
                        the market which affected around 2 crore car consumers
                        in India.
                      </p>
                      <p>Automobile manufacturers were found to be:</p>

                      <ol style={{ listStyleType: "disc" }}>
                        <li>
                          Controlling and regulating the operations of various
                          authorized workshops and service stations which were
                          in the business of selling automobile spare parts.
                        </li>
                        <li>
                          Not making information regarding technology,
                          diagnostic tools and software programs required to
                          maintain service and repair of advanced automobiles
                          manufactured available to the independent repair
                          workshops. The repair and maintenance of such
                          automobiles could only be carried out at the workshops
                          or service stations of their authorized dealers.
                        </li>
                        <li>
                          Placing restriction on the sale and supply of genuine
                          spare parts, diagnostic tools/equipment and technical
                          information required to maintain, service and repair
                          the automobiles manufactured by them. The said car
                          companies and their respective dealers refused to
                          supply genuine spare parts and technological equipment
                          in the open market for providing maintenance and
                          repair services.
                        </li>
                      </ol>

                      <p>
                        However, amendments were made to Companies Act in 2009
                        by the Competition Commission of India in order to
                        protect the interest of consumers and prevent activities
                        having adverse effect on competition in India. With
                        regards to the automobile industry, it deals with issues
                        concerning the distribution of motor vehicles and spare
                        parts such as restricting the supply of the original
                        spare parts to manufacturer owned/authorized dealers
                        only.
                      </p>

                      <p>
                        It became mandatory for Automobile companies to grant
                        access to independent entities to sell their original
                        equipment spare parts and facilitate their after
                        services. These amendments to the Competition Act became
                        a game changer for the automobile industry of India,
                        thus, creating several challenges in terms of
                        distribution and dealer management due to the entry of a
                        vast network of independent & small sellers. Allocating
                        dealers exclusive territories from automobile companies
                        provided them with a wider, but not absolute control
                        over sales between territories as dealers can sell to
                        independent entities without the manufacturer’s
                        knowledge. This gave rise to the need of an effective
                        system for tracking sales from distributors to estimate
                        the actual market demand of their original spare parts.
                      </p>

                      <p
                        style={{
                          fontSize: "15pt",
                          fontWeight: "600",
                          color: "black",
                        }}
                      >
                        Some of the most common Challenges in Distributor
                        Management in Automobile industry are:
                      </p>

                      <p style={{ paddingLeft: "50px" }}>
                        <b style={{ color: "black" }}>
                          Inefficient Channel Management:{" "}
                        </b>
                        The distribution/dealer channel comprises of a complex
                        process involving multiple stages and channels which
                        often leads to inefficiency if things are being managed
                        manually and there is no automation in place for
                        coordination.
                      </p>

                      <p style={{ paddingLeft: "50px" }}>
                        <b style={{ color: "black" }}>
                          Mismanagement of Stock:{" "}
                        </b>
                        Due to a vast network of distributor/dealer spread out
                        and a massive demand, overstock and understock
                        situations are inevitable since there is lack of data
                        availability. Moreover, tracking sales from spare parts
                        and service stations make it even harder for automobile
                        companies in terms of SKU mapping and management.
                      </p>
                      <p style={{ paddingLeft: "50px" }}>
                        <b style={{ color: "black" }}>
                          Lack of Tech Infrastructure:{" "}
                        </b>
                        Majority of distributors/dealers are small and
                        unorganized entities that have inadequate capital and
                        tech infrastructure as well as limited penetration of
                        the internet, and substandard logistics infrastructure.
                      </p>
                      <p style={{ paddingLeft: "50px" }}>
                        <b style={{ color: "black" }}>
                          Multi Brand Management:{" "}
                        </b>
                        Multi brand distributors find it difficult to manage
                        data for the various brands they handle as it is
                        difficult for them to keep a track on sales of different
                        brands.
                      </p>
                      <p style={{ paddingLeft: "50px" }}>
                        <b style={{ color: "black" }}>
                          Lack of actionable data and information:{" "}
                        </b>
                        It becomes a cumbersome task to garner information
                        related to sale from individual distributor/dealer in
                        different regions of the country. This lack of
                        information hinders decision making for the management.
                      </p>

                      <p>
                        Therefore, implementing a Distributor Management
                        Software can add substantial value to automobile
                        manufacturers. It can help them acquire accurate
                        secondary sales data from various distributors/dealers
                        across the country and give them complete control over
                        their price. It will also enable to get distributor wise
                        sales data in order to measure performance and to roll
                        out discounts and other schemes. These insights to
                        real-time and accurate market data will enable them to
                        make precise business decisions.
                      </p>
                      <p></p>
                    </div>
                  </div>
                </article>
                <div class="clear"></div>
                <div class="single-post-comments"></div>
              </div>
              <div class="col-md-4">
                {/* <?php include ('recentpost.php')?> */}
                <RecentPost />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
