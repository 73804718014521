import React, { useState, useEffect } from 'react';

const TruncateText = ({ text }) => {
  const [maxLength, setMaxLength] = useState(100); // Default maxLength
  const [truncatedText, setTruncatedText] = useState('');
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 1200) {
        setMaxLength(450); // Set maxLength for lg screen
      } else if (window.innerWidth >= 992) {
        setMaxLength(350); // Set maxLength for md screen
      } else if (window.innerWidth >= 768) {
        setMaxLength(180); // Set maxLength for sm screen
      } else {
        setMaxLength(150); // Set maxLength for xs screen
      }
    }

    handleResize(); // Call the function once to set the initial maxLength

    window.addEventListener('resize', handleResize); // Add event listener for window resize

    return () => window.removeEventListener('resize', handleResize); // Clean up the event listener

  }, []);

  useEffect(() => {
    if (text.length > maxLength) {
      setTruncatedText(text.slice(0, maxLength));
      setIsTruncated(true);
    } else {
      setTruncatedText(text);
      setIsTruncated(false);
    }
  }, [text, maxLength]);

  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };

  return (
    <div className="truncate-text">
      <p>{isTruncated ? truncatedText + '...' : text}</p>
      {text.length > maxLength && (
        <button className='btn btn-info btn-sm' onClick={toggleTruncate}>
          {isTruncated ? 'Read more' : 'Read less'}
        </button>
      )}
    </div>
  );
};

export default TruncateText;
