import React from "react";
import { faAngleRight, faBackward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "../Sections/footer";
import { Topbar } from "../Topbar";
import AfterTopBar from "../AfterTopbar/AfterTopbar";

export const Media = () => {
  return (
    <>
      <div className="main_header">
        <Topbar />
        <AfterTopBar />
      </div>
      <div className="page-area3">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="breadcrumb">
                <div className="section-headline white-headline text-center">
                  <h3>Media</h3>
                </div>
                <ul>
                  <li className="home-bread">
                    Home
                    <span style={{ marginLeft: "7px" }}></span>
                    <FontAwesomeIcon icon={faAngleRight} />
                    <span style={{ marginLeft: "7px" }}></span> Media
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="blog-area area-padding">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="section-headline text-center">
                <h3>Media</h3>
              </div>
            </div>
          </div>
          <div class="blog-grid home-blog">
            <div class="row">
              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/globalnews.jpg"
                        alt="GLOBAL NEWS press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">30 Sep</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>Fusion DMS 4.0- Fusing every element into 1! </h4>
                      <p>
                        Fusion DMS 4.0- Fusing every element into 1! :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          GLOBALNEWS Tonight
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="https://globalnewstonight.com/index.php/2021/09/30/fusion-dms-4-0-fusing-every-element-into-1/"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/filipboardcov.jpg"
                        alt="FLIPBOARD press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">11 Nov</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>Fusion DMS 4.0- Fusing every element into 1! </h4>
                      <p>
                        Fusion DMS 4.0- Fusing every element into 1! :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          FLIPBOARD
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="https://flipboard.com/@theprintindia/top-stories-9n1cos2oz/fusion-dms-4-0--fusing-every-element-into-1/a-cYcqkeccTxqNu6zR_UFKaA%3Aa%3A2795223453-32d72823be%2Ftheprint.in"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/anicov.jpg"
                        alt="ANI press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">11 Nov</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>Fusion DMS 4.0- Fusing every element into 1! </h4>
                      <p>
                        Fusion DMS 4.0- Fusing every element into 1! :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          ANI
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="https://aninews.in/news/business/business/fusion-dms-40-fusing-every-element-into-120210930140948/"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/gadgetinvcov.jpg"
                        alt="Gadget Innovations press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">11 Nov</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>Fusion DMS 4.0- Fusing every element into 1! </h4>
                      <p>
                        Fusion DMS 4.0- Fusing every element into 1! :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          GADGET INNOVATIONS
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="https://www.gadget-innovations.com/2021/09/fusion-dms-40-fusing-every-element-into.html"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/onlinemediacov.jpg"
                        alt="Online Media Cafe press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">11 Nov</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>Fusion DMS 4.0- Fusing every element into 1! </h4>
                      <p>
                        Fusion DMS 4.0- Fusing every element into 1! :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          ONLINE MEDIA CAFE
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="https://onlinemediacafe.com/business/fusion-dms-4-0-fusing-every-element-into-1/"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/britishnewcovdms.jpg"
                        alt="British News Network press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">11 Nov</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>Fusion DMS 4.0- Fusing every element into 1! </h4>
                      <p>
                        Fusion DMS 4.0- Fusing every element into 1! :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          BRITISH NEWS NETWORK
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="https://www.britishnewsnetwork.com/news/fusion-dms-40-fusing-every-element-into-120210930140944/"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/cxootlook.jpg"
                        alt="CXO OUTLOOK press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">1 Oct</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>Fusion DMS 4.0- Fusing every element into 1! </h4>
                      <p>
                        Fusion DMS 4.0- Fusing every element into 1! :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          CXO OUTLOOK
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="http://www.cxooutlook.com/fusion-dms-4-0-fusing-every-element-into-1/"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/forexnewstimes.jpg"
                        alt="FOREX NEWSTIMES press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">30 Sep</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>Fusion DMS 4.0- Fusing every element into 1! </h4>
                      <p>
                        Fusion DMS 4.0- Fusing every element into 1! :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          FOREX NEWSTIMES
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="https://forexnewstimes.com/fusion-dms-4-0-fusing-every-element-into-1/"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/latestgoldnews.jpg"
                        alt="LATESTGOLD NEWS press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">30 Sep</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>Fusion DMS 4.0- Fusing every element into 1! </h4>
                      <p>
                        Fusion DMS 4.0- Fusing every element into 1! :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          LATESTGOLD NEWS
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="https://latestgoldnews.com/index.php/2021/09/30/fusion-dms-4-0-fusing-every-element-into-1/"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/justnewsnow.jpg"
                        alt="JUSTNEWS NOW press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">30 Sep</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>Fusion DMS 4.0- Fusing every element into 1! </h4>
                      <p>
                        Fusion DMS 4.0- Fusing every element into 1! :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          JUSTNEWS NOW
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="https://justnewsnow.com/fusion-dms-4-0-fusing-every-element-into-1/"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/inbusinesstimes.jpg"
                        alt="IN BUSINESS TIMES press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">30 Sep</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>Fusion DMS 4.0- Fusing every element into 1! </h4>
                      <p>
                        Fusion DMS 4.0- Fusing every element into 1! :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          INBUSINESS TIMES
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="https://inbusinesstimes.com/fusion-dms-4-0-fusing-every-element-into-1/"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/newsecontent.jpg"
                        alt="NEWS CONTENT press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">30 Sep</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>Fusion DMS 4.0- Fusing every element into 1! </h4>
                      <p>
                        Fusion DMS 4.0- Fusing every element into 1! :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          NEWS CONTENT
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="https://newsecontent.com/fusion-dms-4-0-fusing-every-element-into-1/"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/globalnews.jpg"
                        alt="GLOBAL NEWS press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">30 Sep</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>Fusion DMS 4.0- Fusing every element into 1! </h4>
                      <p>
                        Fusion DMS 4.0- Fusing every element into 1! :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          GLOBALNEWS Tonight
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="https://globalnewstonight.com/index.php/2021/09/30/fusion-dms-4-0-fusing-every-element-into-1/"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/finnancialcov.jpg"
                        alt="FINANCIAL NEWS DAY press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">30 Sep</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>Fusion DMS 4.0- Fusing every element into 1! </h4>
                      <p>
                        Fusion DMS 4.0- Fusing every element into 1! :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          FINANCIAL NEWS DAY
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="https://financialnewsday.com/fusion-dms-4-0-fusing-every-element-into-1/"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/bussinessstancov.jpg"
                        alt="BUSINESS STANDARD press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">30 Sep</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>Fusion DMS 4.0- Fusing every element into 1! </h4>
                      <p>
                        Fusion DMS 4.0- Fusing every element into 1! :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          BUSINESS STANDARD
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="https://www.business-standard.com/content/press-releases-ani/fusion-dms-4-0-fusing-every-element-into-1-121093000775_1.html"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/theprintcov.jpg"
                        alt="THE PRINT press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">30 Sep</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>Fusion DMS 4.0- Fusing every element into 1! </h4>
                      <p>
                        Fusion DMS 4.0- Fusing every element into 1! :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          THE PRINT
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="https://theprint.in/ani-press-releases/fusion-dms-4-0-fusing-every-element-into-1/742994/"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/news21covdms.jpg"
                        alt="News21 press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">30 Sep</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>Fusion DMS 4.0– Fusing every element into 1! </h4>
                      <p>
                        Fusion DMS 4.0– Fusing every element into 1! :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          NEWS21 COVERAGE
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="https://news21.co.in/index.php/2021/09/30/fusion-dms-4-0-fusing-every-element-into-1/"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/asian_news.png"
                        alt="ASIAN NEWS press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">23 Sep</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>Fusion DMS 4.0– Fusing every element into 1! </h4>
                      <p>
                        Fusion DMS 4.0– Fusing every element into 1! :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          {" "}
                          ASIAN NEWS
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="https://asiannews.in/fusion-dms-4-0-fusing-every-element-into-1/?amp=1"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/c11.png"
                        alt="BW BUSINESSWORLD press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">4 Jan</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>DMS - BW business world- How Technology Can Help </h4>
                      <p>
                        DMS - BW business world- How Technology Can Help :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          BW BUSINESSWORLD
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="http://www.businessworld.in/article/How-technology-can-help-traditional-retail/29-10-2020-337170/"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/c10.png"
                        alt="Gadget Innovations press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">4 Jan</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>
                        How brands can smoothly manage the entire distributor{" "}
                      </h4>
                      <p>
                        How brands can smoothly manage the entire distributor :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          Gadget Innovations
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="https://www.gadget-innovations.com/2020/11/how-brands-can-smoothly-manage-entire.html"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/c15.jpg"
                        alt="CXO Outlook press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">14 Dec</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>
                        Distribution Management Software’s Contribution to
                        Retail
                      </h4>
                      <p>
                        Distribution Management Software’s Contribution to
                        Retail :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          CXO Outlook
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="https://www.cxooutlook.com/distribution-management-softwares-contribution-to-retail-industry-growth-and-the-way-forward/"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/c13.png"
                        alt="Business News of the Week press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">19 Nov</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>
                        How brands can smoothly manage the entire distributor
                      </h4>
                      <p>
                        How brands can smoothly manage the entire distributor :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          Business News of the Week
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="http://businessnewsthisweek.com/business/how-brands-can-smoothly-manage-the-entire-distributor-network-with-eazy-dms/"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/c12.png"
                        alt="PNI press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">18 Nov</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>
                        How brands can smoothly manage the entire distributor
                      </h4>
                      <p>
                        How brands can smoothly manage the entire distributor :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          PNI
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="https://www.pninews.com/how-brands-can-smoothly-manage-the-entire-distributor-network-with-eazy-dms/"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/c16.jpg"
                        alt="Media Bulletins press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">4 Nov</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>
                        How brands can smoothly manage the entire distributor
                      </h4>
                      <p>
                        How brands can smoothly manage the entire distributor :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          Media Bulletins
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="http://mediabulletins.com/business-world/how-brands-can-smoothly-manage-the-entire-distributor-network-with-eazy-dms/"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/c9.png"
                        alt="SME Times press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">31 Oct</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>
                        DMS- An interview with Kunal Singhal, the Managing
                        Director
                      </h4>
                      <p>
                        DMS- An interview with Kunal Singhal, the Managing
                        Director :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          SME Times
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="http://thesmetimes.com/an-interview-with-kunal-singhal-the-managing-director-at-eazy-dms/"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/b11.png"
                        alt="SME Channel press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">4 Sep</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>
                        Hybrid Eazy DMS 3.0 – the new upgrade for the new normal
                      </h4>
                      <p>
                        Hybrid Eazy DMS 3.0 – the new upgrade for the new normal
                        :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          SME Channel
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="http://www.smechannels.com/hybrid-eazy-dms-3-0-the-new-upgrade-for-the-new-normal/"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/c6.png"
                        alt="Media Bulletins press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">4 Sep</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>
                        Hybrid Eazy DMS 3.0 – the new upgrade for the new normal
                      </h4>
                      <p>
                        Hybrid Eazy DMS 3.0 – the new upgrade for the new normal
                        :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          Media Bulletins
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="http://mediabulletins.com/business-world/hybrid-eazy-dms-3-0-the-new-upgrade-for-the-new-normal/"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/c4.png"
                        alt="SME Street press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">4 Sep</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>
                        Eazy ERP Introduced Hybrid Eazy DMS 3.0 An Upgrade for
                        the New
                      </h4>
                      <p>
                        Eazy ERP Introduced Hybrid Eazy DMS 3.0 An Upgrade for
                        the New Normal :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          SME Street
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="https://smestreet.in/msme-opportunities/fintech/eazy-erp-introduced-hybrid-eazy-dms-3-0-an-upgrade-for-the-new-normal/"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/b8.png"
                        alt="Gadget Innovation press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">4 Sep</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>
                        Hybrid Eazy DMS 3.0 – the new upgrade for the new normal
                      </h4>
                      <p>
                        Hybrid Eazy DMS 3.0 – the new upgrade for the new normal
                        :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          Gadget Innovation
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="https://www.gadget-innovations.com/2020/09/hybrid-eazy-dms-30-new-upgrade-for-new.html"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/c5.png"
                        alt="DQ Channels press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">4 Sep</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>
                        Hybrid Eazy DMS 3.0 – the new upgrade for the new normal
                      </h4>
                      <p>
                        Hybrid Eazy DMS 3.0 – the new upgrade for the new normal
                        :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          DQ Channels
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="https://www.dqchannels.com/hybrid-eazy-dms-3-0-new-upgrade-new-normal/"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/b6.png"
                        alt="Business News This Week press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">3 Sep</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>
                        Hybrid Eazy DMS 3.0 – the new upgrade for the new normal
                      </h4>
                      <p>
                        Hybrid Eazy DMS 3.0 – the new upgrade for the new normal
                        :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          Business News This Week
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="http://businessnewsthisweek.com/business/hybrid-eazy-dms-3-0-the-new-upgrade-for-the-new-normal/"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/c2.png"
                        alt="VarIndia press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">24 Aug</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>
                        Eazy ERP Technologies rolls out Hybrid Eazy DMS 3.0
                      </h4>
                      <p>
                        Eazy ERP Technologies rolls out Hybrid Eazy DMS 3.0 :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          VARINDIA
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="https://www.varindia.com/news/eazy-erp-technologies-rolls-out-hybrid-eazy-dms-30"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/c3.png"
                        alt="Business World press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">18 Aug</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>
                        Hybrid Eazy DMS 3.0 - The New Upgrade For The New Normal
                      </h4>
                      <p>
                        Hybrid Eazy DMS 3.0 - The New Upgrade For The New Normal
                        :{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          Business World
                        </span>
                        ...
                      </p>
                      <a
                        class="media-btn"
                        href="http://www.businessworld.in/article/Hybrid-Eazy-DMS-3-0-The-New-Upgrade-For-The-New-Normal/18-08-2020-309903/"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/b2.png"
                        alt="SME world press release image"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">5 Jun</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>Spectacular Growth of EAZY Business Solutions</h4>
                      <p>
                        EAZY Business Solution, known for its flagship
                        product...
                      </p>
                      <a
                        class="media-btn"
                        href="http://smeworld.asia/News.aspx?INNews=News-284/spectacular-growth-of-eazy-business-solutions#.W5D_xegzZdh"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/b3.png"
                        alt="Dataquest Award for Eazy DMS icon"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">23 Mar</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>
                        EAZYERP gets DQ awards for its innovative DMS solution
                      </h4>
                      <p>
                        EAZY-DMS is distributor management software for
                        tracking...
                      </p>
                      <a
                        class="media-btn"
                        href="http://www.uniindia.com/~/easyerp-gets-dq-award-for-its-innovative-dms-solution/Business%20Economy/news/1177292.html"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-6">
                <div class="single-media">
                  <div class="media-image">
                    <a class="image-scale" href="#">
                      <img
                        src="https://eazysites.s3.amazonaws.com/eazydms/img/media/b1.jpg"
                        alt="SME Street press release"
                      />
                    </a>
                  </div>
                  <div class="media-content">
                    <div class="media-title">
                      <div class="media-meta">
                        <span class="date-type">12 Mar</span>
                      </div>
                    </div>
                    <div class="media-text">
                      <h4>
                        Eazy ERP Introduces Eazy DMS Distributor Management
                        Solution
                      </h4>
                      <p>
                        EAZY-DMS is distributor management software for
                        tracking...{" "}
                      </p>
                      <a
                        class="media-btn"
                        href="https://smestreet.in/clusters/eazy-erp-registers-65-growth-set-to-introduce-eazy-dms-distributor-management-solution/"
                        target="_blank"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
