import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { RecentPost } from "./recentPost";
import { Link } from "react-router-dom";
import { Topbar } from "../Topbar";
import AfterTopBar from "../AfterTopbar/AfterTopbar";
import Footer from "../Sections/footer";

const BlogPage = () => {
  return (
    <>
      <div className="main_header">
        <Topbar />
        <AfterTopBar />
      </div>
      <div class="page-area4">
        <div class="container">
          <div class="row  text-center">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="breadcrumb">
                <div class="section-headline white-headline text-center">
                  <h3>Blog</h3>
                </div>
                <ul>
                  <li className="home-bread">
                    Home
                    <span style={{ marginLeft: "7px" }}></span>
                    <FontAwesomeIcon icon={faAngleRight} />
                    <span style={{ marginLeft: "7px" }}></span> Blog
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="blog-area area-padding">
        <div class="container">
          <div class="row">
            <div class="col-md-8 col-sm-8 col-xs-12">
              <div class="blog-grid home-blog">
                <div class="row">
                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="single-blog">
                      <div class="blog-image">
                        <Link
                          class=""
                          to="/blog-details-DMS-is-not-meant-for-policing"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <img
                            src="https://eazysites.s3.amazonaws.com/eazydms/img/blog/DMS_is_not_meant_for_policing.jpg"
                            alt="DMS is not meant for policing"
                          />
                        </Link>
                      </div>
                      <div class="blog-content">
                        <div class="blog-title">
                          <Link
                            to="/blog-details-DMS-is-not-meant-for-policing"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            <h4>DMS Is Not Meant For Policing</h4>
                          </Link>
                        </div>
                        <div class="blog-text">
                          <p>
                            The growth of the manufacturing industry has moved
                            the focus onto the distribution system.
                            Manufacturers have a vast distribution system that
                            handles several thousand transactions every month.
                            Several underlying issues are too challenging to
                            manage through manual workflows...{" "}
                          </p>
                          <Link
                            class="blog-btn"
                            to="/blog-details-DMS-is-not-meant-for-policing"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="single-blog">
                      <div class="blog-image">
                        <Link
                          class=""
                          to="/blog-right-approach-to-dms"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <img
                            src="https://eazysites.s3.amazonaws.com/eazydms/img/blog/BLOG_DMS-approach.jpg"
                            alt="Right Approach to DMS"
                          />
                        </Link>
                      </div>
                      <div class="blog-content">
                        <div class="blog-title">
                          <Link
                            to="/blog-right-approach-to-dms"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            <h4>Right Approach to DMS</h4>
                          </Link>
                        </div>
                        <div class="blog-text">
                          <p>
                            With the ever changing market dynamics, businesses
                            are evolving every minute to stay relevant. In an
                            increasingly competitive environment, having a
                            solution for tracking channel sales is very
                            important in order to achieve increased...{" "}
                          </p>
                          <Link
                            class="blog-btn"
                            to="/blog-right-approach-to-dms"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="single-blog">
                      <div class="blog-image">
                        <Link
                          class=""
                          to="/blog-dms-crm"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <img
                            src="https://eazysites.s3.amazonaws.com/eazydms/img/blog/dms-crm.jpg"
                            alt="blog-dms-crm"
                          />
                        </Link>
                      </div>
                      <div class="blog-content">
                        <div class="blog-title">
                          <Link
                            to="/blog-dms-crm"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            <h4>
                              Enhance the Power of Distributor Management
                              Software with CRM Integration
                            </h4>
                          </Link>
                        </div>
                        <div class="blog-text">
                          <p>
                            Distributor Management System (DMS) has become an
                            integral part of manufacturing organisations –
                            having a large distributor & retailer network.
                            Visibility of Primary & Secondary Sales has enabled
                            companies to devise strategies for timely delivery
                            of goods to Distributors...{" "}
                          </p>
                          <Link
                            class="blog-btn"
                            to="/blog-dms-crm"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="single-blog">
                      <div class="blog-image">
                        <Link
                          class=""
                          to="/blog-details-steeringfmcgsector"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <img
                            src="https://eazysites.s3.amazonaws.com/eazydms/img/blog/fmcg-m.png"
                            alt="Steering the FMCG Sector"
                          />
                        </Link>
                      </div>
                      <div class="blog-content">
                        <div class="blog-title">
                          <Link
                            to="/blog-details-steeringfmcgsector"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            <h4>How Technology is Steering the FMCG Sector</h4>
                          </Link>
                        </div>
                        <div class="blog-text">
                          <p>
                            Over the recent years, we have witnessed a marked
                            increase in the entry of new players in{" "}
                            <Link
                              to="/blog-details-fmcg"
                              target="_blank"
                              style={{ textDecoration: "none" }}
                            >
                              F&B and consumer durable industry
                            </Link>{" "}
                            offering ground breaking consumer products due to
                            evolution of consumers in terms of tastes,
                            preferences, lifestyle, need for variety etc...{" "}
                          </p>
                          <Link
                            class="blog-btn"
                            to="/blog-details-steeringfmcgsector"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="single-blog">
                      <div class="blog-image">
                        <Link
                          class=""
                          to="/blog-details-saasvsonpremisces"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <img
                            src="https://eazysites.s3.amazonaws.com/eazydms/img/blog/saasvsonprem.jpg"
                            alt=""
                          />
                        </Link>
                      </div>
                      <div class="blog-content">
                        <div class="blog-title">
                          <Link
                            to="/blog-details-saasvsonpremisces"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            <h4>
                              SaaS or On-Premise Distributor Management
                              Software? Which one is better for you?
                            </h4>
                          </Link>
                        </div>
                        <div class="blog-text">
                          <p>
                            In the era of industry 4.0, businesses are making a
                            continuous effort to be in a digitally connected
                            environment. Adopting enterprise level solutions
                            like ERP, Distributor Management system (DMS), CRM
                            etc help businesses to centralize their data across
                            the organization...{" "}
                          </p>
                          <Link
                            class="blog-btn"
                            to="/blog-details-saasvsonpremisces"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="single-blog">
                      <div class="blog-image">
                        <Link
                          class=""
                          to="/blog-details-rolloutbenefits"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <img
                            src="https://eazysites.s3.amazonaws.com/eazydms/img/blog/benefits-onsite.jpg"
                            alt=""
                          />
                        </Link>
                      </div>
                      <div class="blog-content">
                        <div class="blog-title">
                          <Link
                            to="/blog-details-rolloutbenefits"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            <h4>
                              BENEFITS OF AN ONSITE DISTRIBUTOR ROLLOUT IN DMS
                            </h4>
                          </Link>
                        </div>
                        <div class="blog-text">
                          <p>
                            Implementing a Distributor Management Software is
                            not just limited to product installation or hosting
                            at the parent company. It involves rollouts and
                            training to the vastly spread distributor network as
                            well. Whether you have opted for a traditional
                            Distributor Management Software, portal-based DMS or
                            a Tally integrated DMS...{" "}
                          </p>
                          <Link
                            class="blog-btn"
                            to="/blog-details-rolloutbenefits"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="single-blog">
                      <div class="blog-image">
                        <Link
                          class=""
                          to="/blog-details-dmsforautomotives"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <img
                            src="https://eazysites.s3.amazonaws.com/eazydms/img/blog/automotives.jpg"
                            alt=""
                          />
                        </Link>
                      </div>
                      <div class="blog-content">
                        <div class="blog-title">
                          <Link
                            to="/blog-details-dmsforautomotives"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            <h4>
                              WHY IS DISTRIBUTOR MANAGEMENT SOFTWARE IMPORTANT
                              FOR AUTOMOBILE INDUSTRY?
                            </h4>
                          </Link>
                        </div>
                        <div class="blog-text">
                          <p>
                            The automobile industry of India has witnessed a
                            remarkable growth ever since the economic
                            liberalization & delicensing in 1991. Indian
                            automobile market is growing...{" "}
                          </p>
                          <Link
                            class="blog-btn"
                            to="/blog-details-dmsforautomotives"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="single-blog">
                      <div class="blog-image">
                        <Link
                          class=""
                          to="/blog-details-dmsvswebportal"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <img
                            src="https://eazysites.s3.amazonaws.com/eazydms/img/blog/DMSblogDMSVSPortal.jpg"
                            alt=""
                          />
                        </Link>
                      </div>
                      <div class="blog-content">
                        <div class="blog-title">
                          <Link
                            to="/blog-details-dmsvswebportal"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            <h4>
                              Eazy DMS vs Portal Based Distributor Management
                              Software
                            </h4>
                          </Link>
                        </div>
                        <div class="blog-text">
                          <p>
                            Emerging markets for FMCG/FMCD companies are massive
                            and aggregated with manifold distributors, dealers,
                            retails and potential customers...{" "}
                          </p>
                          <Link
                            class="blog-btn"
                            to="/blog-details-dmsvswebportal"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="single-blog">
                      <div class="blog-image">
                        <Link
                          class=""
                          to="/blog-details-eazysfa"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <img
                            src="https://eazysites.s3.amazonaws.com/eazydms/img/blog/EAZY-SFA-DMS-blog-cover.png"
                            alt=""
                          />
                        </Link>
                      </div>
                      <div class="blog-content">
                        <div class="blog-title">
                          <Link
                            to="/blog-details-eazysfa"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            <h4>USP’S OF EAZY SFA</h4>
                          </Link>
                        </div>
                        <div class="blog-text">
                          <p>
                            Traditional sales force automation platforms help
                            enterprises keep a watch on their sales forces.
                            However, it must achieve beyond just tracking the
                            field sales and should also...{" "}
                          </p>
                          <Link
                            class="blog-btn"
                            to="/blog-details-eazysfa"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="single-blog">
                      <div class="blog-image">
                        <Link
                          class=""
                          to="/blog-details-fmcg"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <img
                            src="https://eazysites.s3.amazonaws.com/eazydms/img/blog/DMS-Post-28-nov.jpg"
                            alt=""
                          />
                        </Link>
                      </div>
                      <div class="blog-content">
                        <div class="blog-title">
                          <Link
                            to="/blog-details-fmcg"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            <h4>
                              Why do FMCG/FMCD companies need a Distributor
                              Management Software?
                            </h4>
                          </Link>
                        </div>
                        <div class="blog-text">
                          <p>
                            FMCG companies across the globe enjoy the benefits
                            of having a huge market potential because of the
                            size of the population of a country and is
                            characterized by a well-established distribution
                            network{" "}
                          </p>
                          <Link
                            class="blog-btn"
                            to="/blog-details-fmcg"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="single-blog">
                      <div class="blog-image">
                        <Link
                          class=""
                          to="/blog-details-tally-int"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <img
                            src="https://eazysites.s3.amazonaws.com/eazydms/img/blog/blog-tallyint.jpg"
                            alt=""
                          />
                        </Link>
                      </div>
                      <div class="blog-content">
                        <div class="blog-title">
                          <Link
                            to="/blog-details-tally-int"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            <h4>
                              Why Tally integrated Distributor Management
                              Software?
                            </h4>
                          </Link>
                        </div>
                        <div class="blog-text">
                          <p>
                            Present day markets are huge and multifaceted with
                            thousands of distributors, retailers and billions of
                            potential transactions. How can your company take
                            control? How can you manage your secondary sales and
                            promotions while still cutting costs?
                          </p>
                          <Link
                            class="blog-btn"
                            to="/blog-details-tally-int"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="single-blog">
                      <div class="blog-image">
                        <Link
                          class=""
                          to="/whatisaDMS"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <img
                            src="https://eazysites.s3.amazonaws.com/eazydms/img/blog/1.png"
                            alt="What is a Distributor Management System Blog Cover"
                          />
                        </Link>
                      </div>
                      <div class="blog-content">
                        <div class="blog-title">
                          <Link
                            to="/whatisaDMS"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            <h4>
                              What is Distributor Management Software & Why is
                              it Important?
                            </h4>
                          </Link>
                        </div>
                        <div class="blog-text">
                          <p>
                            Emerging markets are large and complex with
                            thousands of distributors, millions of outlets, and
                            billions of potential transactions. Manufacturers
                            have to manage this vast network of big and small
                            distributors while also juggling the conflicting
                            needs of different channel partners. But how can
                            manufacturers manage all of this with limited data
                            and insight on the market? How can they take
                            control?{" "}
                          </p>
                          <Link
                            class="blog-btn"
                            to="/whatisaDMS"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <RecentPost />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogPage;
