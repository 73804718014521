import React from "react";
import { faAngleRight, faBackward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContactForm } from "./ConatctForm";
import { ContactFooter } from "./ContactFooter";
import Footer from "../Sections/footer";
import { Topbar } from "../Topbar";
import AfterTopBar from "../AfterTopbar/AfterTopbar";

export const Contact = () => {
  return (
    <>
      <div className="main_header">
        <Topbar />
        <AfterTopBar />
      </div>
      <div className="page-area">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="breadcrumb">
                <div className="section-headline white-headline text-center">
                  <h3>Contact us</h3>
                </div>
                <ul>
                  <li className="home-bread">
                    Home
                    <span style={{ marginLeft: "7px" }}></span>
                    <FontAwesomeIcon icon={faAngleRight} />
                    <span style={{ marginLeft: "7px" }}></span> Contact
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactForm />
      <Footer />
    </>
  );
};
