import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import "./Alert.css"
export const ResponsePopUp = ({
    maxWidth,
    type,
    open,
    onClose,
    content,
    alert_title
}) => {
    const topButtonClass = (type == 'success') ? 'login_right_button' : 'login_close_button';
    const alert_title_display = alert_title ? 'show' : 'none';
    return (
        <>
            <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={maxWidth}>
                <DialogTitle id="alert-dialog-title" className='login_dialogue'>
                    <div className={topButtonClass}></div>
                    <div className="login_response_contnt" style={{paddingBottom: '0px', fontWeight: '600', display: alert_title_display}}>
                        {alert_title}
                    </div>
                    <div className="login_response_contnt">
                        {content}
                    </div>
                    <hr />
                    <div className="loginFormResponse">
                        <button onClick={onClose}>{'Ok'}</button>
                    </div>
                </DialogTitle>
            </Dialog>
        </>
    );
};
