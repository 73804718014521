import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { CaseStudyBody } from "./CaseBody";
import Footer from "../Sections/footer";
import { Topbar } from "../Topbar";
import AfterTopBar from "../AfterTopbar/AfterTopbar";
const CaseStudy = () => {
  return (
    <>
      <div className="main_header">
        <Topbar />
        <AfterTopBar />
      </div>
      <div className="page-area2" style={{ minHeight: "500px !important" }}>
        <div className="container">
          <div className="row text-center">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="breadcrumb">
                
                  <h4 className="text-white">Customer Case Studies of Eazy DMS</h4>
           
                <ul>
                  <li className="home-bread">
                    Home
                    <span style={{ marginLeft: "7px" }}></span>
                    <FontAwesomeIcon icon={faAngleRight} />
                    <span style={{ marginLeft: "7px" }}></span> Case Studies
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CaseStudyBody />
      <Footer />
    </>
  );
};

export default CaseStudy;
