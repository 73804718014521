import { Link } from "react-router-dom";

export const RecentPost = () => {
  return (
    <>
      <div class="left-head-blog">
        <div class="left-blog-page"></div>

        <div class="left-blog-page">
          <div class="left-blog">
            <h4>Recent post</h4>
            <div class="recent-post">
              <div class="recent-single-post">
                <div class="post-img">
                  <Link
                    to="/blog-details-DMS-is-not-meant-for-policing"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/blog/dms_is_not_meant_for_policing_th.jpg"
                      alt="Right Approach to DMS"
                    />
                  </Link>
                </div>
                <div class="pst-content">
                  <p>
                    <Link
                      to="/blog-details-DMS-is-not-meant-for-policing"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      DMS Is Not Meant For Policing
                    </Link>
                  </p>
                  <span class="date-type">Feb 2022</span>
                </div>
              </div>

              <div class="recent-single-post">
                <div class="post-img">
                  <Link
                    to="/blog-right-approach-to-dms"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/blog/blog_dms-approach-thumbnail.jpg"
                      alt="Right Approach to DMS"
                    />
                  </Link>
                </div>
                <div class="pst-content">
                  <p>
                    <Link
                      to="/blog-right-approach-to-dms"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      Right Approach to DMS
                    </Link>
                  </p>
                  <span class="date-type">Apr 2020</span>
                </div>
              </div>

              <div class="recent-single-post">
                <div class="post-img">
                  <Link
                    to="/blog-dms-crm"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/blog/dms-crm-thumbnail.jpg"
                      alt="blog-dms-crm"
                    />
                  </Link>
                </div>
                <div class="pst-content">
                  <p>
                    <Link
                      to="/blog-dms-crm"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      Enhance the Power of Distributor Management Software with
                      CRM Integration
                    </Link>
                  </p>
                  <span class="date-type">Nov 2019</span>
                </div>
              </div>

              <div class="recent-single-post">
                <div class="post-img">
                  <Link
                    to="/blog-details-steeringfmcgsector"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/blog/fmcg-m.png"
                      alt="Steering the FMCG Sector"
                    />
                  </Link>
                </div>
                <div class="pst-content">
                  <p>
                    <Link
                      to="/blog-details-steeringfmcgsector"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      How Technology is Steering the FMCG Sector
                    </Link>
                  </p>
                  <span class="date-type">Jun 2019</span>
                </div>
              </div>

              <div class="recent-single-post">
                <div class="post-img">
                  <Link
                    to="/blog-details-saasvsonpremisces"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/blog/saasvsonprem.jpg"
                      alt=""
                    />
                  </Link>
                </div>
                <div class="pst-content">
                  <p>
                    <Link
                      to="/blog-details-saasvsonpremisces"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      SaaS or On-Premise DMS? Which one is better for you?
                    </Link>
                  </p>
                  <span class="date-type">Mar 2019</span>
                </div>
              </div>

              <div class="recent-single-post">
                <div class="post-img">
                  <Link
                    to="/blog-details-rolloutbenefits"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/blog/benefits-onsite.jpg"
                      alt=""
                    />
                  </Link>
                </div>
                <div class="pst-content">
                  <p>
                    <Link
                      to="/blog-details-rolloutbenefits"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      BENEFITS OF AN ONSITE DISTRIBUTOR ROLLOUT IN DMS
                    </Link>
                  </p>
                  <span class="date-type">Feb 2019</span>
                </div>
              </div>

              <div class="recent-single-post">
                <div class="post-img">
                  <Link
                    to="/blog-details-dmsforautomotives"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/blog/automotives.jpg"
                      alt=""
                    />
                  </Link>
                </div>
                <div class="pst-content">
                  <p>
                    <Link
                      to="/blog-details-dmsforautomotives"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      WHY IS DMS IMPORTANT FOR AUTOMOBILE INDUSTRY?
                    </Link>
                  </p>
                  <span class="date-type">Feb 2019</span>
                </div>
              </div>

              <div class="recent-single-post">
                <div class="post-img">
                  <Link
                    to="/blog-details-dmsvswebportal"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/blog/DMSblogDMSVSPortal.jpg"
                      alt=""
                    />
                  </Link>
                </div>
                <div class="pst-content">
                  <p>
                    <Link
                      to="/blog-details-dmsvswebportal"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      Eazy DMS Vs Portal Based DMS
                    </Link>
                  </p>
                  <span class="date-type">Jan 2019</span>
                </div>
              </div>

              <div class="recent-single-post">
                <div class="post-img">
                  <Link
                    to="/blog-details-eazysfa"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/blog/EAZY-SFA-DMS-blog-cover.png"
                      alt=""
                    />
                  </Link>
                </div>
                <div class="pst-content">
                  <p>
                    <Link
                      to="/blog-details-eazysfa"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      USP’S OF EAZY SFA
                    </Link>
                  </p>
                  <span class="date-type">Dec 2018</span>
                </div>
              </div>

              <div class="recent-single-post">
                <div class="post-img">
                  <Link
                    to="/blog-details-fmcg"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/blog/DMS-Post-28-nov.jpg"
                      alt=""
                    />
                  </Link>
                </div>
                <div class="pst-content">
                  <p>
                    <Link
                      to="/blog-details-fmcg"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      Why do FMCG/FMCD companies need a DMS?
                    </Link>
                  </p>
                  <span class="date-type">Nov 2018</span>
                </div>
              </div>

              <div class="recent-single-post">
                <div class="post-img">
                  <Link
                    to="/blog-details-tally-int"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/blog/blog-tallyint.jpg"
                      alt=""
                    />
                  </Link>
                </div>
                <div class="pst-content">
                  <p>
                    <Link
                      to="/blog-details-tally-int"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      Why Tally integrated DMS?
                    </Link>
                  </p>
                  <span class="date-type">Nov 2018</span>
                </div>
              </div>

              <div class="recent-single-post">
                <div class="post-img">
                  <Link
                    to="/whatisaDMS"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      src="https://eazysites.s3.amazonaws.com/eazydms/img/blog/1.png"
                      alt=""
                    />
                  </Link>
                </div>
                <div class="pst-content">
                  <p>
                    <Link
                      to="/whatisaDMS"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      What is DMS & its Importance?
                    </Link>
                  </p>
                  <span class="date-type">Oct 2018</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
